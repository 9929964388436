<div class="cnt">



  <div class="card" [ngStyle]="{ 'height': reduced ? 'fit-content' :'auto' }">
    <div class="card-header  card-header-info">
      <h4 class="card-title" style="float:left">

        <span>{{!description || description == '' ? 'Inserisci una descrizione' : description}}</span>
        <span *ngIf="isModerator"> - MODERATORE</span>

        <button type="button" mat-icon-button class="btn" (click)="editDescription()" *ngIf="isModerator">
          <span class="material-icons">edit</span>
        </button>

      </h4>



      <button mat-mini-fab style="float:right; margin-left:10px; background-color: grey;" (click)="openSettings()"><span
          class="material-icons">
          settings
        </span></button>

      <button mat-raised-button *ngIf="notifications" style="float:right; padding: 0 10px!important; margin-left:10px"
        (click)="silence(false)">
        Silenzia Notifiche</button>
      <button mat-raised-button *ngIf="!notifications" style="float:right; padding: 0 10px!important; margin-left:10px"
        (click)="silence(true)">
        Riattiva Notifiche</button>


      <button mat-raised-button style="float:right; padding: 0 10px!important; margin-left:10px"
        (click)="shareContent()">
        Condividi Schermo</button>

      <button mat-raised-button style="float:right; padding: 0 10px!important; margin-left:10px"
        (click)="startCapture()" *ngIf="isModerator && !isRecording">Avvia registr.</button>

      <button mat-raised-button style="float:right; padding: 0 10px!important; margin-left:10px" (click)="stopCapture()"
        *ngIf="isModerator && isRecording">Stop registr.</button>



      <button mat-raised-button style="float:right; padding: 0 10px!important; margin-left:10px" (click)="openOther()"
        *ngIf="false && isModerator">Apri altro
        utente</button>
      <button mat-raised-button style="float:right; padding: 0 10px!important; margin-left:10px" (click)="copyUrl()"
        *ngIf="isModerator">Copia
        indirizzo</button>


      <button mat-raised-button style="float:right; padding: 0 10px!important; margin-left:10px"
        (click)="sendUrlByEmail()" *ngIf="isModerator">Invita per email</button>

<!--
      <button type="button" mat-icon-button class="btn" style="float:right; padding: 0 10px!important; margin-left:10px"
        (click)="logVideo()" style="color:green">
        <span class="material-icons">
          update
        </span>
      </button>
-->


      <button *ngIf="!isReserved && !isModerator" mat-raised-button
        style="float:right; padding: 0 10px!important; margin-left:10px; background-color:green ; color: white;"
        type="button" color="basic" (click)="reserve()">Alza Mano <span class="material-icons">
          pan_tool
        </span></button>

      <button *ngIf=" isReserved && !isModerator" mat-raised-button
        style="float:right; padding: 0 10px!important; margin-left:10px; background-color: red;" type="button"
        color="warn" (click)="reserve()">Abbassa Mano <span class="material-icons">
          pan_tool
        </span></button>

      <button *ngIf="isModerator" mat-raised-button style="float:right; padding: 0 10px!important; margin-left:10px"
        type="button" color="basic" (click)="exportToWord()">Esporta risultato Riunione</button>

      <span style="float:right; margin-right: 30px;">
        Dl: {{bw_down / 1024 | number:'.2-2'}}Mbps<br>
        Ul: {{bw_up / 1024 | number:'.2-2'}}Mbps
      </span>

    </div>

    <div *ngIf="!reduced">
      <div class="card-body" style="height: 100%;">

        <audio id="meeting-audio" #meetingAudio style="border:1px solid black"></audio>

        <div class="row" style="height: 100%;">
          <div [ngClass]="{ 'col': isFullscreen, 'col-6' : !isFullscreen }">

            <div class="row">
              <div class="col" style="padding:0; margin-left:15px; overflow: hidden;">
                <!--<button type="button" mat-icon-button class="btn" (click)="togglefullscreen()"
                  style="background-color:green;">
                  <span class="material-icons">
                    fullscreen
                  </span>
                </button>-->

                <video id="content-share-video" #meetingVideo crossOrigin="anonymous"
                  [ngClass]="{ 'fullscreen': isFullscreen && withoutCards, 'notfullscreen' : !isFullscreen, 'semifullscreen' : isFullscreen && !withoutCards }"></video>

              </div>
            </div>

            <div class="row" [ngClass]="{'hidden' : isFullscreen && withoutCards}">
              <div class="col">
                &nbsp;
              </div>
            </div>

            <div class="row" [ngClass]="{'hidden' : isFullscreen && withoutCards}">

              <div *ngFor="let x of videos"
                [ngClass]="{'col-2 module rounded': !isFullscreen , 'col-1 module rounded' : isFullscreen, 'hidden': !x.visible }"
                style="padding:0; margin-left:15px">
                <video [attr.id]="'video-'+x.id" style="width:100%; height:100%; max-height:100px; "></video>


                <div class="header">

                  <div style="float:left; margin-top: 8px;">
                    {{ x.id }} - {{ x.name }}
                  </div>

                  <span style="color:yellow; float:left; margin-top: 8px;" class="material-icons"
                    *ngIf=" (x?.warning && isModerator) || (x?.warning && x.attendeeId == getAttendeeId() )">
                    warning
                  </span>


                  <div style="float:right">

                    <button type="button" mat-icon-button class="btn" (click)="sendMessage(x,'setMute')"
                      *ngIf="getAttendeeId() != x.attendeeId && isModerator && !checkIfMuted(x) && !x.tileState?.isContent">
                      <span class="material-icons">
                        volume_up
                      </span>
                    </button>
                    <button type="button" mat-icon-button class="btn" (click)="sendMessage(x,'setUnmute')"
                      *ngIf="getAttendeeId() != x.attendeeId && isModerator && checkIfMuted(x) && !x.tileState?.isContent">
                      <span class="material-icons" style="color:red">
                        volume_off
                      </span>
                    </button>

                    <button type="button" mat-icon-button class="btn" (click)="toggleMute()"
                      *ngIf="getAttendeeId() == x.attendeeId  && isModerator && meetingSession?.audioVideo?.realtimeCanUnmuteLocalAudio()">
                      <span class="material-icons"
                        [ngStyle]="{ 'color': meetingSession.audioVideo.realtimeIsLocalAudioMuted() ? 'red' :'white' } ">
                        {{meetingSession.audioVideo.realtimeIsLocalAudioMuted() ? 'volume_off' : 'volume_up' }}
                      </span>
                    </button>

                    <button type="button" mat-icon-button class="btn" (click)="stopLocalVideo()" 
                      *ngIf="getAttendeeId() == x.attendeeId  && !novideo ">
                      <span class="material-icons">
                        videocam
                      </span>
                    </button>

                   <button type="button" mat-icon-button class="btn" (click)="startLocalVideo()" 
                      *ngIf="getAttendeeId() == x.attendeeId  && novideo ">
                      <span class="material-icons" style="color:red">
                        videocam_off
                      </span>
                    </button>

                    <br>

                    <button type="button" mat-icon-button class="btn" (click)="fixVideo(x)"
                      *ngIf="x.attendeeId != fixedSpeaker">
                      <span class="material-icons">
                        fullscreen
                      </span>
                    </button>
                    <button type="button" mat-icon-button class="btn" (click)="fixVideo()"
                      *ngIf="x.attendeeId == fixedSpeaker" style="color:green">
                      <span class="material-icons">
                        fullscreen
                      </span>
                    </button>

                  </div>
                </div>

                <span *ngIf="x.reservationNumber > 0" matBadgeColor="warn" [matBadge]="x.reservationNumber"
                  matBadgeOverlap="false" style="position:absolute; bottom:4px; left:4px; color:red">

                </span>


              </div>
            </div>
          </div>



          <div style="height:100%">
            <button mat-button (click)="togglefullscreen(false)" style="right: 3vw;
    
            background-color: #114C88;
            height: 84vh;
            min-width: 1%;
            padding: 0;
         
        "> <span style="
    left: 0.5vw; bottom:41.5vh" *ngIf="isFullscreen"
                [matBadge]="allegati?.length + chatroom?.length + questions?.length" matBadgeOverlap="false"></span>


              <span class="material-icons" style="color:white" *ngIf="!isFullscreen">
                keyboard_arrow_right
              </span>
              <!--<br *ngIf="!isFullscreen">
              <span class="material-icons" *ngIf="!isFullscreen" style="color:white">
                zoom_in
              </span>-->
              <span class="material-icons " style="color:white" *ngIf="isFullscreen">
                keyboard_arrow_left
              </span>
              <!--<br *ngIf="isFullscreen">
              <span class="material-icons" *ngIf="isFullscreen" style="color:white">
                zoom_out
              </span>-->

            </button>
            <button mat-button *ngIf="isFullscreen && !withoutCards" (click)="togglefullscreen(true)" style="right: 2vw;
    
            background-color: #114C88;
            height: 84vh;
            min-width: 1%;
            padding: 0;
         
        ">

              <span class="material-icons" style="color:white">
                keyboard_arrow_right
              </span>
              <!--<br *ngIf="!isFullscreen">
              <span class="material-icons" *ngIf="!isFullscreen" style="color:white">
                zoom_in
              </span>-->


            </button>
          </div>



          <div class="col-5" style="height: 100%;" [ngClass]="{'hidden' : isFullscreen}">

            <mat-tab-group style="height: 100%;">
              <mat-tab label="Chat" style="height: 100%;">
                <ng-template matTabLabel>
                  <span [matBadge]="chatroom?.length" matBadgeOverlap="false">Chat</span>
                </ng-template><br>
                <div class="chat-content">
                  <h3>Chat </h3>
                  <div *ngFor="let c of chatroom"
                    [ngClass]="{'left': getAttendeeId()!=c.senderAttendeeId , 'right': getAttendeeId()==c.senderAttendeeId }">

                    <div>
                      {{c.msg}}<br>
                      <span class="date">{{ getNameFromAttendeeId(c.senderAttendeeId) }} - </span>
                      <span class="date">{{c.date | date:'dd/MM/yyyy hh:mm'}}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div>

                    <div style="float:left; width:90%">
                      <input type="text" class="form-control" placeholder="Message" [(ngModel)]="msg"
                        (keydown.enter)="sendMessage(undefined,'chat')">


                    </div>
                    <div style="float:right;">
                      <button type="button" class="mat-mini-fab" (click)="sendMessage(undefined,'chat')"
                        style="background-color:rgb(121, 211, 247)!important;    box-shadow: none;">
                        <i class="material-icons" style="vertical-align:middle!important;color: white;">
                          send
                        </i>
                      </button>
                    </div>
                  </div>

                </div>

              </mat-tab>

              <mat-tab label="Editor" style="height: 100%;">
                <ckeditor *ngIf="showEditor" #myEditor style="height: 100%;" [config]="editor_config" [editor]="Editor"
                  [(data)]="doc_data" (change)="onChange_editor($event)" [disabled]="!isModerator"></ckeditor>
              </mat-tab>

              <mat-tab label="Allegati" style="height: 100%;">
                <ng-template matTabLabel>
                  <span [matBadge]="allegati?.length" matBadgeOverlap="false">Allegati</span>
                </ng-template><br>

                <div class="row">
                  <div class="col">
                    <app-gallery *ngIf="allegati" [(source)]="allegati" (onAdd)="attachmentSave($event)" id="allegati"
                      style="margin:10px" [showTag]="true" [showLabel]="false" [categorized]="false"
                      (onSelected)=openViewer($event,allegati) (onDelete)="attachmentSave($event)">
                    </app-gallery>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Domande" style="height: 100%;">
                <ng-template matTabLabel>
                  <span [matBadge]="questions?.length" matBadgeOverlap="false">Domande</span>
                </ng-template><br>


                <div class="row" *ngFor="let q of questions " style="padding-left: 10%!important;">
                  <div clss="col">
                    <p style="font-weight: bold;font-size: medium;">{{q?.id}}) {{q?.domanda}}: <span
                        style="font-weight: normal!important;"
                        *ngIf="!isModerator && giaRisposto(q.id) !== false">{{giaRisposto(q.id)}}</span></p>
                    <button mat-raised-button style="    box-shadow: none;
                    background-color: #124B87;
                    color: white;" *ngIf="!isModerator && giaRisposto(q.id) === false"
                      (click)="addAnswer(q.id, q.domanda)">Rispondi</button>


                    <div class="row" *ngIf="isModerator">
                      <div class="col">
                        <span>FAVOREVOLI - {{displayRisposte('YES',q.risposte)}}</span>
                      </div>
                      <div class="col">
                        <span>CONTRARI - {{displayRisposte('NO',q.risposte)}}</span>
                      </div>
                      <div class="col">
                        <span>ASTENUTI - {{displayRisposte('AST',q.risposte)}}</span>
                      </div>
                      <div class="col">
                        <span>TOTALE - {{displayRisposte('TOT',q.risposte)}}</span>
                      </div>

                    </div>


                    <button mat-button *ngIf="isModerator" (click)="toggleDetail(q)">Visualizza/nascondi
                      dettagli</button>
                    <div class="row" *ngIf="isModerator && showDetail(q)">
                      <div class="col">
                        <div class="row" *ngFor="let r of q.risposte">
                          <div class="col">{{r.username}}</div>
                          <div class="col">{{decodeResponseVal(r.val)}}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div *ngIf="isModerator" style="float:right;">
                  <button type="button" class="mat-mini-fab" (click)="addQuestion()"
                    style="background-color: #124B86!important;  margin-right:15px; margin-bottom:5px; box-shadow:none">
                    <i class="material-icons" style="vertical-align:middle!important;color: white;">
                      add
                    </i>
                  </button>
                </div>

              </mat-tab>
            </mat-tab-group>




          </div>

        </div>

      </div>


    </div>
    <!--VISUALIZZAZIONE PER TELEFONO-->
    <div *ngIf="reduced">

      <div class="card-body" style="height: 100%;">


        <audio id="meeting-audio" #meetingAudio style="border:1px solid black"></audio>


        <div class="row">
          <div class="col" style="padding:0; margin-left:15px; overflow: hidden;">

            <video id="content-share-video" #meetingVideo crossOrigin="anonymous" [ngClass]="'fullscreen'"></video>
          </div>
        </div>

        <div class="row">
          <div class="col" style="height: 100%;" [ngClass]="{'hidden' : isFullscreen}">

            <mat-tab-group style="height: 100%;">
              <mat-tab label="Chat" style="height: 100%;">
                <ng-template matTabLabel>
                  <span [matBadge]="chatroom?.length" matBadgeOverlap="false">Chat</span>
                </ng-template><br>
                <div class="chat-content">
                  <h3>Chat </h3>

                  <div *ngFor="let c of chatroom"
                    [ngClass]="{'left': getAttendeeId()!=c.senderAttendeeId , 'right': getAttendeeId()==c.senderAttendeeId }">

                    {{c.msg}}<br>
                    <span class="date">{{ getNameFromAttendeeId(c.senderAttendeeId) }} - </span>
                    <span class="date">{{c.date | date:'dd/MM/yyyy hh:mm'}}</span>
                  </div>
                </div>
                <div>
                  <div>

                    <div style="float:left; width:90%;">
                      <input type="text" class="form-control" placeholder="Message" [(ngModel)]="msg"
                        (keydown.enter)="sendMessage(undefined,'chat')">


                    </div>
                    <div style="float:right;">
                      <button type="button" class="mat-mini-fab" (click)="sendMessage(undefined,'chat')"
                        style="background-color:rgb(121, 211, 247)!important;">
                        <i class="material-icons" style="vertical-align:middle!important;color: white;">
                          send
                        </i>
                      </button>
                    </div>
                  </div>


                </div>
              </mat-tab>

              <mat-tab label="Editor" style="height: 100%;">
                <ckeditor *ngIf="showEditor" #myEditor style="height: 100%;" [config]="editor_config" [editor]="Editor"
                  [(data)]="doc_data" (change)="onChange_editor($event)" [disabled]="!isModerator"></ckeditor>
              </mat-tab>

              <mat-tab label="Allegati" style="height: 100%;">
                <ng-template matTabLabel>
                  <span [matBadge]="allegati?.length" matBadgeOverlap="false">Allegati</span>
                </ng-template><br>

                <div class="row">
                  <div class="col">
                    <app-gallery *ngIf="allegati" [(source)]="allegati" (onAdd)="attachmentSave($event)" id="allegati"
                      style="margin:10px" [showTag]="true" [showLabel]="false" [categorized]="false"
                      (onSelected)=openViewer($event,allegati) (onDelete)="attachmentSave($event)">
                    </app-gallery>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Domande" style="height: 100%;">
                <ng-template matTabLabel>
                  <span [matBadge]="questions?.length" matBadgeOverlap="false">Domande</span>
                </ng-template><br>


                <div class="row" *ngFor="let q of questions " style="padding-left: 10%!important;">
                  <div clss="col">
                    <p style="font-weight: bold;font-size: medium;">{{q?.id}}) {{q?.domanda}}: <span
                        style="font-weight: normal!important;"
                        *ngIf="!isModerator && giaRisposto(q.id) !== false">{{giaRisposto(q.id)}}</span></p>
                    <button mat-raised-button style="    box-shadow: none;
                    background-color: #124B87;
                    color: white;" *ngIf="!isModerator && giaRisposto(q.id) === false"
                      (click)="addAnswer(q.id, q.domanda)">Rispondi</button>

                    <div class="row" *ngIf="isModerator">
                      <div class="col">
                        <span>FAVOREVOLI - {{displayRisposte('YES',q.risposte)}}</span>
                      </div>
                      <div class="col">
                        <span>CONTRARI - {{displayRisposte('NO',q.risposte)}}</span>
                      </div>
                      <div class="col">
                        <span>ASTENUTI - {{displayRisposte('AST',q.risposte)}}</span>
                      </div>
                      <div class="col">
                        <span>TOTALE - {{displayRisposte('TOT',q.risposte)}}</span>
                      </div>

                    </div>


                    <button mat-button *ngIf="isModerator" (click)="toggleDetail(q)">Visualizza/nascondi
                      dettagli</button>
                    <div class="row" *ngIf="isModerator && showDetail(q)">
                      <div class="col">
                        <div class="row" *ngFor="let r of q.risposte">
                          <div class="col">{{r.username}}</div>
                          <div class="col">{{decodeResponseVal(r.val)}}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div *ngIf="isModerator" style="float:right;">
                  <button type="button" class="mat-mini-fab" (click)="addQuestion()"
                    style="background-color: #124B86!important;">
                    <i class="material-icons" style="vertical-align:middle!important;color: white;box-shadow:none">
                      add
                    </i>
                  </button>
                </div>

              </mat-tab>
            </mat-tab-group>





          </div>
        </div>
      </div>
    </div>


  </div>

  <video id="myVideo" playsinline class="video-js vjs-default-skin"></video>
</div>

<app-doc-viewer [index]="docviewer_i" [(files)]="docviewer_source" *ngIf="docviewer" (closed)="closeDocviewer()"
  [position]="docviewer_position" [filter]="docviewer_filter" [doubleView]="docviewer_double" [descr]="docviewer_descr">
</app-doc-viewer>