import { Component, Directive, Input, OnInit, ViewEncapsulation, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MysqlService } from 'app/services/mysql.service';
import { getEmptyIFirCert, IFir, IFirCert } from 'app/models/fir.interface';
import { UntypedFormGroup, Validators, UntypedFormBuilder, ValidatorFn, ValidationErrors, AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Location } from '@angular/common';
import { UserService } from 'app/services/user.service';
import { HttpEventType } from '@angular/common/http';



@Component({
  selector: 'app-dialog-fir-cert',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],

  encapsulation: ViewEncapsulation.None,


})

export class FirCertDialogComponent implements OnInit {

  id: number;
  item: IFirCert;

  editMode: boolean;

  loading = false;
  loading_percentage;

  sessionId: string;
  token: string;

  form: UntypedFormGroup;

  role: string = '';
  client;

  isWritable: boolean = false;
  isSubmitted = false;

  constructor(
    private route: ActivatedRoute,
    private db: MysqlService,
    public dialog: MatDialog,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router

  ) {


    this.role = userService.getCurrentUser().role;
    this.client = userService.getCurrentUser().client;




    this.form = this.formBuilder.group({
      nrCommessaInt: ['',],
      dataCommessa: ['',],
      cliente: ['', Validators.required],
      telaioDiProvenienza: ['',],
      respInt: ['', Validators.required],
      anomalieIN: ['', Validators.required],
      tipoMotore: ['', Validators.required],


      tipoRev: ['', Validators.required],



      smontaggio_eseguito: ['', [Validators.required, Validators.requiredTrue]],
      lavaggio_eseguito: ['', [Validators.required, Validators.requiredTrue]],
      controllo_eseguito: ['', [Validators.required, Validators.requiredTrue]],
      controllo_difformita: ['', [Validators.required]],


      lm_incamiciatura_eseguito: ['', [Validators.required, Validators.requiredTrue]],
      lm_incamiciatura_el_accessori: ['', [Validators.required]],
      lm_pianatura_eseguito: ['', [Validators.required, Validators.requiredTrue]],
      lm_alloggiamento_albero_eseguito: ['', []],
      lm_alloggiamento_albero_el_accessori: ['', [Validators.required]],
      lm_sost_bronzine_eseguito: ['', [Validators.required, Validators.requiredTrue]],
      lm_sost_bielle_eseguito: ['', []],
      lm_mont_cuscinetti_eseguito: ['', [Validators.required, Validators.requiredTrue]],
      lm_mont_pistoni_eseguito: ['', [Validators.required, Validators.requiredTrue]],
      lm_mont_bielle_eseguito: ['', [Validators.required, Validators.requiredTrue]],


      lm_sost_albero_eseguito: ['', Validators.required],
      lm_sost_albero_rettifica_eseguito: ['', ],
      lm_sost_albero_rettifica: [''],
      lm_sost_albero_stappatura_eseguito: ['', ],
      lm_sost_albero_prescrizione: ['',],

      lm_sost_testate_eseguito: ['', Validators.required,],
      lm_sost_testate_spianatura_eseguito: ['', Validators.required],
      lm_sost_testate_spianatura_rettifica: ['',],
      lm_sost_testate_misurazione_eseguito: ['', ],
      lm_sost_testate_misurazione_rettifica: ['',],
      lm_sost_testate_controllo_supportini_eseguito: ['', Validators.required],
      lm_sost_testate_sost_valvole_eseguito: ['', Validators.required],
      lm_sost_testate_controllo_distrib_eseguito: ['',],
      lm_sost_testate_controllo_camme_eseguito: ['', ],
      lm_sost_testate_pulitura_condotti_eseguito: ['', Validators.required],
      lm_sost_testate_sost_guarniz_eseguito: ['',],


      mat_canne: ['', [Validators.required, Validators.requiredTrue]],
      mat_pistoni: ['', [Validators.required, Validators.requiredTrue]],
      mat_boccole: ['', [Validators.required, Validators.requiredTrue]],
      mat_semicuscinetti: ['', [Validators.required, Validators.requiredTrue]],
      mat_cuscinetti: ['', [Validators.required, Validators.requiredTrue]],
      mat_semianelli: ['', []],
      mat_guarnizioni: ['', [Validators.required, Validators.requiredTrue]],
      mat_albero: ['', []],
      mat_bilanceri: ['', []],
      mat_cinghie: ['', []],
      mat_valvole: ['', [Validators.required, Validators.requiredTrue]],
      mat_guide_valvole: ['', [Validators.required, Validators.requiredTrue]],
      mat_punterie: ['', []],

      mat_corona: ['', []],
      mat_valvole_lub: ['', []],
      mat_filtri_olio: ['', []],
      mat_tubi_iniet: ['', []],
      mat_tubi_rit: ['', []],
      mat_polv: ['', []],
      mat_pompa: ['', []],
      mat_manicotti: ['', []],
      mat_valvole_termo: ['', []],
      mat_candelette: ['', []],
      mat_turbo: ['', []],



    });


  }


  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');
    this.loadData();
  }




  loadData() {


    this.item = getEmptyIFirCert();

    this.editMode = this.id > 0;

    if (this.id > 0)
      this.db.fir_cert_get(this.id).then(res => {

        console.clear()

        console.log('data', res[0])
        let data = res[0];

        data.smontaggio_eseguito = +  data.smontaggio_eseguito > 0
        data.lavaggio_eseguito = +  data.lavaggio_eseguito > 0
        data.controllo_eseguito = +  data.controllo_eseguito > 0
        data.lm_incamiciatura_eseguito = +  data.lm_incamiciatura_eseguito > 0
        data.lm_pianatura_eseguito = +  data.lm_pianatura_eseguito > 0
        data.lm_alloggiamento_albero_eseguito = +  data.lm_alloggiamento_albero_eseguito > 0
        data.lm_sost_bronzine_eseguito = +  data.lm_sost_bronzine_eseguito > 0
        data.lm_sost_bielle_eseguito = +  data.lm_sost_bielle_eseguito > 0
        data.lm_mont_cuscinetti_eseguito = +  data.lm_mont_cuscinetti_eseguito > 0
        data.lm_mont_pistoni_eseguito = +  data.lm_mont_pistoni_eseguito > 0
        data.lm_mont_bielle_eseguito = +  data.lm_mont_bielle_eseguito > 0

        data.lm_sost_albero_eseguito = +  data.lm_sost_albero_eseguito > 0
        data.lm_sost_albero_rettifica_eseguito = +  data.lm_sost_albero_rettifica_eseguito > 0
        data.lm_sost_albero_stappatura_eseguito = +  data.lm_sost_albero_stappatura_eseguito > 0
        data.lm_sost_albero_prescrizione = +  data.lm_sost_albero_prescrizione > 0

        data.lm_sost_testate_eseguito = +  data.lm_sost_testate_eseguito > 0
        data.lm_sost_testate_spianatura_eseguito = +  data.lm_sost_testate_spianatura_eseguito > 0
        data.lm_sost_testate_misurazione_eseguito = +  data.lm_sost_testate_misurazione_eseguito > 0
        data.lm_sost_testate_controllo_supportini_eseguito = +  data.lm_sost_testate_controllo_supportini_eseguito > 0
        data.lm_sost_testate_sost_valvole_eseguito = +  data.lm_sost_testate_sost_valvole_eseguito > 0
        data.lm_sost_testate_controllo_distrib_eseguito = +  data.lm_sost_testate_controllo_distrib_eseguito > 0
        data.lm_sost_testate_controllo_camme_eseguito = +  data.lm_sost_testate_controllo_camme_eseguito > 0
        data.lm_sost_testate_pulitura_condotti_eseguito = +  data.lm_sost_testate_pulitura_condotti_eseguito > 0
        data.lm_sost_testate_sost_guarniz_eseguito = +  data.lm_sost_testate_sost_guarniz_eseguito > 0



        data.mat_canne = +  data.mat_canne > 0
        data.mat_pistoni = +  data.mat_pistoni > 0
        data.mat_boccole = +  data.mat_boccole > 0
        data.mat_semicuscinetti = +  data.mat_semicuscinetti > 0
        data.mat_cuscinetti = +  data.mat_cuscinetti > 0
        data.mat_semianelli = +  data.mat_semianelli > 0
        data.mat_guarnizioni = +  data.mat_guarnizioni > 0
        data.mat_albero = +  data.mat_albero > 0
        data.mat_bilanceri = +  data.mat_bilanceri > 0
        data.mat_cinghie = +  data.mat_cinghie > 0
        data.mat_valvole = +  data.mat_valvole > 0
        data.mat_guide_valvole = +  data.mat_guide_valvole > 0
        data.mat_punterie = +  data.mat_punterie > 0
        data.mat_corona = +  data.mat_corona > 0
        data.mat_valvole_lub = +  data.mat_valvole_lub > 0
        data.mat_filtri_olio = +  data.mat_filtri_olio > 0
        data.mat_tubi_iniet = +  data.mat_tubi_iniet > 0
        data.mat_tubi_rit = +  data.mat_tubi_rit > 0
        data.mat_polv = +  data.mat_polv > 0
        data.mat_pompa = +  data.mat_pompa > 0
        data.mat_manicotti = +  data.mat_manicotti > 0
        data.mat_valvole_termo = +  data.mat_valvole_termo > 0
        data.mat_candelette = +  data.mat_candelette > 0
        data.mat_turbo = +  data.mat_turbo > 0






        this.item = data

        console.log('item', this.item)

        this.loadAll();

      }).catch(err => console.error(err));

    else
      this.loadAll();

  }

  loadAll() {


    this.loading = false;

  }

  error = false
  errorAlbero = false
  errorTesta = false

  save(closeWin: boolean = true) {

    let msg = ''
    this.error = false
    this.errorAlbero = false
    this.errorTesta = false

    console.log(this.item)


    if (this.loading) return;

    this.isSubmitted = true

    if (this.form.invalid) {
      this.error = true
      msg += "Form invalid\n"
    }




    if (!(
      this.item.lm_sost_albero_eseguito =='NUOVO' ||
      (
        this.item.lm_sost_albero_rettifica_eseguito &&
        this.item.lm_sost_albero_stappatura_eseguito &&
        this.item.lm_sost_albero_prescrizione &&
        this.item.lm_sost_albero_rettifica != ''
      ))
    ) {
      this.errorAlbero = true
      this.error = true
      msg += "Form albero\n"
    }


    if (!(
      this.item.lm_sost_testate_eseguito =='NUOVO' ||
      (
        this.item.lm_sost_testate_spianatura_eseguito &&
        this.item.lm_sost_testate_misurazione_eseguito &&
        this.item.lm_sost_testate_controllo_supportini_eseguito &&
        this.item.lm_sost_testate_sost_valvole_eseguito &&
        this.item.lm_sost_testate_controllo_distrib_eseguito &&
        this.item.lm_sost_testate_controllo_camme_eseguito &&
        this.item.lm_sost_testate_controllo_supportini_eseguito &&
        this.item.lm_sost_testate_controllo_supportini_eseguito &&
        this.item.lm_sost_testate_controllo_supportini_eseguito &&
        this.item.lm_sost_testate_pulitura_condotti_eseguito &&
        this.item.lm_sost_testate_sost_guarniz_eseguito &&
        this.item.lm_sost_testate_spianatura_rettifica != '' &&
        this.item.lm_sost_testate_misurazione_rettifica != ''
      ))
    ) {
      this.errorTesta = true
      this.error = true

      msg += "Form testate\n"
    }

    if (this.item.tipoRev == 'COMPLETO') {

      if (
        !this.item.mat_tubi_iniet ||
        !this.item.mat_tubi_rit ||
        !this.item.mat_polv ||
        !this.item.mat_pompa ||
        !this.item.mat_manicotti ||
        !this.item.mat_valvole_termo ||
        !this.item.mat_candelette ||
        !this.item.mat_turbo
      ) {

        this.errorTesta = true
        this.error = true

        msg += "Dati revisione completa\n"

      }




    }


    if (this.error) {

      for (const c in this.form.controls) {

        if (this.form.controls[c].status != 'VALID') {
          msg += ("\nVerificare " + c + "\n")
        }

      }
 
      alert('Attenzione, verificare attentamente la compilazione del form' )

      console.warn('form invalid')
      console.log(this.form)



      return;
    }

    this.loading = true;


    this.db.fir_cert_upsert(this.item).then(res => {

      if (res.status != 'OK') {
        alert('fir_cert_upsert: ' + JSON.stringify(res))
        return
      }

      this.id = res.item.id
      this.item = res.item

      this.loadData()


      //if (closeWin) this.close()


    }).catch(err => {
      alert(JSON.stringify(err))
      this.loading = false;
    })


  }

  downloadCert() {

    let url = this.db.fir_get_cert_url(this.item.id)
    window.open(url, '_blank')

  }

  close() {


    this.router.navigate(['/extra/fir/'])

  }



  isMSIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      let r = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      console.log(r);
      return r;
    }
  }


  isDisabled() {
    return this.item.id
  }

}

