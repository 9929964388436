import { Component, OnInit, ViewEncapsulation, Inject, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MysqlService } from 'app/services/mysql.service';
import { IDomande, IRisposta} from '../chimes.component'
import * as moment from 'moment';


@Component({
  selector: 'app-dialog-question',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],

  encapsulation: ViewEncapsulation.None,


})

export class QuestionDialogComponent implements OnInit {

  question


  constructor(
    private route: ActivatedRoute,
    private db: MysqlService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<QuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

   

  }


  ngOnInit() {
    console.log(this.data)
  }

  ok(){
    this.dialogRef.close(this.question);

  }
  approva(){
    this.dialogRef.close('YES');
  }

  rifiuta(){
    this.dialogRef.close('NO');
  }

  astenuto(){
    this.dialogRef.close('AST')
  }

  close(){
    this.dialogRef.close(false);
  }


}

