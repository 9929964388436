<div class="cnt">

    <div [ngClass]="{'logo':true}">
        <a href="./" class="simple-text">
            <div class="logo-img">
                <img [src]="logo" />
            </div>
        </a>
    </div>


    <div [ngClass]="{'sidebar-wrapper':false}">

        <app-navbar [hidden]="!user"></app-navbar><br><br>
        <hr>


        <app-search-perizie style="display:block; width:200px; margin-left:30px;">
        </app-search-perizie>


        <ul class="nav">

            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20"
                    (click)="open(node)">
                    <!-- use a disabled button to provide padding for tree leaf -->
                    <button mat-icon-button disabled></button>
                    <i class="material-icons">{{node.icon}}</i>
                    {{node.name}}
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                    matTreeNodePaddingIndent="20" (click)="open(node)">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <i class="material-icons">{{node.icon}}</i>
                    {{node.name}}
                </mat-tree-node>
            </mat-tree>

            <!--
            <mat-accordion>

                <div *ngFor="let c of categories">
                    <mat-expansion-panel *ngIf="countVisibleByCategory(c.text)>0" >
                        
                        <mat-expansion-panel-header [ngClass]="['cl_' + c.color]">
                            <mat-panel-title>
                                <i class="material-icons">{{c.icon}}</i>{{c.text}}
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <li routerLinkActive="active" *ngFor="let menuItem of filterMenuItems()"
                            class="{{menuItem.class}} nav-item">

                            <div *ngIf="menuItem.category == c.text">
                                <a class="nav-link" (click)=open(menuItem)
                                    *ngIf="!menuItem.separator && !menuItem.child && ( !menuItem.permissions || menuItem.permissions.includes(user?.Livello) ) && checkUserPermissionFlag(menuItem)">

                                    <span *ngIf="!menuItem.disabled">
                                        <i class="material-icons">{{menuItem.icon}}</i>
                                        <p>{{menuItem.title}}</p>
                                    </span>

                                    <span *ngIf="menuItem.disabled">
                                        <s style="cursor:not-allowed;">
                                            <i class="material-icons">{{menuItem.icon}}</i>
                                            <p>{{menuItem.title}}</p>
                                        </s>
                                    </span>
                                </a>
                       
                                <div class="logo" *ngIf="menuItem.separator">

                                </div>

                            </div>

                        </li>


                    </mat-expansion-panel>

                </div>
            </mat-accordion>

        -->




        </ul>
    </div>
</div>