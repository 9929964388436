<!--
    <div *ngIf="isRecording" class="reddot" ><i class="fa fa-circle text-danger blink"></i></div>
-->


<div #subscriberDiv></div>



<div *ngIf="captureEnabled" style="position:absolute; bottom:10px; right:10px; z-index:999">
    <button *ngIf="recordable" mat-button class="btn "
        [ngClass]="{ 'btn-success': !isRecording, 'btn-danger': isRecording, 'blink': isRecording  }"
        (click)="toggleRecord()">REC</button>
    <button mat-button class="btn btn-success" *ngIf="max(currentResolution.w,currentResolution.h) >= 1280"
        (click)="capture()">CAPTURE ({{currentResolution.w}} x
        {{currentResolution.h}})</button>
    <button mat-button class="btn btn-warning"
        *ngIf=" max(currentResolution.w,currentResolution.h) < 1280 && max(currentResolution.w,currentResolution.h) >= 640"
        (click)="capture()">CAPTURE ({{currentResolution.w}} x
        {{currentResolution.h}})</button>
    <button mat-button class="btn btn-danger" *ngIf="max(currentResolution.w,currentResolution.h) < 640"
        (click)="capture()">CAPTURE ({{currentResolution.w}} x
        {{currentResolution.h}})</button>



    <button mat-button class="btn btn-success" (click)="sendCommand('switch_camera')">SWITCH CAMERA</button>
</div>