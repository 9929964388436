<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <div class="card" style="margin-top: 1%;">
          <div class="card-header card-header-info">
            <h4 class="card-title"> Paga con Stripe

            </h4>
            <p class="card-category">
            </p>
          </div>
          <div class="card-body" style="width: 15%;">

            <mat-spinner *ngIf="wait"></mat-spinner>
            <div *ngIf="!wait && item?.payment?.status!='ok'">
              <h2>{{ammo}} EUR</h2>

              <form novalidate (ngSubmit)="createToken()" [formGroup]="stripeTest">
                <mat-form-field><input type="text" matInput formControlName="name" placeholder="Nome, Cognome">
                </mat-form-field>


                <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
                <br>
                <button mat-raised-button color="success" type="submit">
                  PAGA
                </button>

              </form>

            </div>
            <div *ngIf="!wait && item?.payment?.status=='ok'">
              <p>IL PAGAMENTO RISULTA GIA' EFFETTUATO</p>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>