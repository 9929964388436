import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TiresComponent } from './tires/tires.component';
import { TireComponent } from './tires/tire/tire.component';
import { VideochatComponent } from './videochat/videochat.component';
import { PublisherComponent } from './videochat/publisher/publisher.component';
import { SubscriberComponent } from './videochat/subscriber/subscriber.component';
import { SliedeshowComponent } from './sliedeshow/sliedeshow.component';
import { DashcardComponent } from './dashcard/dashcard.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FileHelpersModule } from 'ngx-file-helpers';

import { NgxFileDropModule } from 'ngx-file-drop';
import { RatingComponent } from './rating/rating.component';
//import { StarRatingModule } from 'angular-star-rating';
import { GalleryComponent } from './gallery/gallery.component';
import { DxSchedulerModule, DxCircularGaugeModule, DxLinearGaugeModule, DxSliderModule } from 'devextreme-angular';
import {
  EcoFabSpeedDialModule
} from '@ecodev/fab-speed-dial';
import { GaugeModule } from 'angular-gauge';
import { DocViewerComponent } from './doc-viewer/doc-viewer.component';
import { FilterByFileTypePipe } from 'app/pipes/filter-by-file-type.pipe';
import { WeatherComponent } from './weather/weather.component';
import { GalleryResultComponent } from './gallery-result/gallery-result.component';
import { PipesModule } from 'app/pipes/pipes.module';
import { BlueprintComponent } from './blueprint/blueprint.component';
import { C360Component } from './c360/c360.component';
import { FormComponent } from './form/form.component';
import { FirComponent } from 'app/menu/schedule/fir/fir.component';
import { FirDialogComponent } from 'app/menu/schedule/fir/dialog/dialog.component';

import { MatFormFieldModule } from '@angular/material/form-field';
//import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

//import { RatingModule } from 'ng-starrating';
import { SearchableSelectComponent } from './searchable-select/searchable-select.component';
import { LocationSearchComponent } from './location-search/location-search.component';
import { SidebarTreeComponent } from './sidebar-tree/sidebar.component';
import { XlsButtonComponent } from './xls-button/xls-button.component';
import { MultiConfirmComponent } from './multi-confirm/multi-confirm.component';
//import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DatetimeComponent } from './datetime/datetime.component';
import { QRCodeModule } from 'angular2-qrcode';
import { ColorPickerModule } from 'ngx-color-picker';

import 'ag-grid-enterprise';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
//import { NgScrollbarModule } from 'ngx-scrollbar';

import { AgGridModule } from 'ag-grid-angular';

import { GridActiveComponent, GridDeleteComponent, GridEditComponent, GridIconComponent, GridStatusComponent, GridUpDownComponent, GridDuplicateComponent, GridFlottaComponent, GridDownloadComponent, GridOKErrorComponent,IconRendererEuro } from './grid-edit/grid-edit.component';

// import { GridActiveComponent, GridDeleteComponent, GridEditComponent, GridIconComponent, GridStatusComponent, GridUpDownComponent, GridDuplicateComponent, GridFlottaComponent, GridDownloadComponent, IconRendererEuro } from './grid-edit/grid-edit.component';


import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FirCertDialogComponent } from 'app/menu/schedule/fir/dialog_cert/dialog.component';
import { AdzViewerComponent } from './adz-viewer/adz-viewer.component';

import { LicenseManager } from 'ag-grid-enterprise';
import { ReclamiComponent } from './reclami/reclami.component'
import { MyDialogComponent } from './my-dialog/my-dialog.component';
import { SearchPerizieComponent } from './search-perizie/search-perizie.component';
import { Blueprint3dComponent } from './blueprint-3d/blueprint-3d.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';


LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-050791}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{General_group_S.r.l.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_January_2025}____[v3]_[01]_MTczNjg5OTIwMDAwMA==863f699460290baf2f393831fedff4a6")


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    MatExpansionModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatTooltipModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatIconModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatExpansionModule,
    MatRadioModule,
    MatTreeModule,
    MatBadgeModule,
    MatChipsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatMenuModule,
    //NgxMatSelectSearchModule,
    FileHelpersModule,
    NgxFileDropModule,
    DxSchedulerModule,
    DxCircularGaugeModule,
    DxLinearGaugeModule,
    DxSliderModule,
    EcoFabSpeedDialModule,


    GaugeModule.forRoot(),

    //StarRatingModule.forRoot(),

    QRCodeModule,
    ColorPickerModule,
    CKEditorModule,
    NgApexchartsModule,

    AgGridModule,//.withComponents([GridEditComponent, GridIconComponent, GridDownloadComponent, GridActiveComponent, GridStatusComponent, GridDeleteComponent, GridUpDownComponent, GridFlottaComponent]),

    NgMultiSelectDropDownModule,


    //NgScrollbarModule,
  ],
  declarations: [
    IconRendererEuro,
    XlsButtonComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    SidebarTreeComponent,
    TireComponent,
    TiresComponent,
    VideochatComponent,
    VideochatComponent,
    PublisherComponent,
    SubscriberComponent,
    SliedeshowComponent,
    DashcardComponent,
    FirComponent,
    FirDialogComponent,
    FirCertDialogComponent,
    GalleryComponent,
    RatingComponent,
    DocViewerComponent,
    FilterByFileTypePipe,
    WeatherComponent,
    ReclamiComponent,
    GalleryResultComponent,
    AdzViewerComponent,
    BlueprintComponent,
    Blueprint3dComponent,
    C360Component,
    FormComponent,
    SearchableSelectComponent,
    LocationSearchComponent,
    MultiConfirmComponent,
    DatetimeComponent,

    GridActiveComponent,

    MyDialogComponent,
    SearchPerizieComponent,
    PasswordStrengthComponent,



  ],
  exports: [
    XlsButtonComponent,
    PipesModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatTooltipModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatIconModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatExpansionModule,
    MatRadioModule,
    MatTreeModule,
    MatBadgeModule,
    MatChipsModule,
    MatMenuModule,
    FileHelpersModule,
    NgxFileDropModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    SidebarTreeComponent,
    TireComponent,
    TiresComponent,
    VideochatComponent,
    PublisherComponent,
    SubscriberComponent,
    SliedeshowComponent,
    DashcardComponent,
    FirComponent,
    FirDialogComponent,
    FirCertDialogComponent,
    GalleryComponent,
    RatingComponent,
    DxSchedulerModule,
    DxCircularGaugeModule,
    DxLinearGaugeModule,
    DxSliderModule,
    GaugeModule,
    //StarRatingModule,
    DocViewerComponent,
    FilterByFileTypePipe,
    WeatherComponent,
    ReclamiComponent,
    GalleryResultComponent,
    AdzViewerComponent,
    BlueprintComponent,
    Blueprint3dComponent,
    AdzViewerComponent,
    C360Component,
    FormComponent,
    MatSelectModule,
    MatFormFieldModule,
    //NgxMatSelectSearchModule,
    SearchableSelectComponent,
    LocationSearchComponent,
    MultiConfirmComponent,
    DatetimeComponent,
    QRCodeModule,
    ColorPickerModule,
    NgApexchartsModule,
    CKEditorModule,
    MatTabsModule,

    AgGridModule,
    GridActiveComponent,
    //NgScrollbarModule,

    NgMultiSelectDropDownModule,

    MyDialogComponent,

    EcoFabSpeedDialModule,
    SearchPerizieComponent,
    PasswordStrengthComponent,
  ]
})
export class ComponentsModule { }
