<div class="card">

    <div class="card-header card-header-info">
        <h4 class="card-title">
            {{data?.myDialogData?.title}}
            <a style="float:right" (click)="close()"><i class="material-icons">close</i></a>
        </h4>
    </div>

    <div class="card-body">

        <mat-form-field *ngFor="let f of data?.myDialogData?.fields">
            <mat-label>{{f.title}}</mat-label>
            <input matInput [type]="f.type" [(ngModel)]="result[f.name]">
        </mat-form-field>


        <button type="button" mat-raised-button class="btn btn-success" (click)="ok()">Salva</button>

    </div>
</div>