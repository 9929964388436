import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { MysqlService } from 'app/services/mysql.service';

@Component({
  selector: 'app-multi-confirm',
  templateUrl: './multi-confirm.component.html',
  styleUrls: ['./multi-confirm.component.scss']
})
export class MultiConfirmComponent implements OnInit {

  @Input() validazione1_utente
  @Input() validazione1_data
  @Input() validazione2_utente
  @Input() validazione2_data

  @Output() onConfirm: EventEmitter<any> = new EventEmitter();

  role

  users = []

  constructor(private userService: UserService, private db: MysqlService) {

    this.role = this.userService.getRole()

    this.db.list_utenti().then(res => this.users = res)

  }

  ngOnInit() {
  }

  confirm(i) {

    console.log('i: ' + i)

    if (i == 1) {
      this.validazione1_utente = this.userService.getEmail()
      this.validazione1_data = new Date()

      console.log(this.validazione1_utente, this.validazione1_data)
    }
    if (i == 2) {
      this.validazione2_utente = this.userService.getEmail()
      this.validazione2_data = new Date()

      console.log(this.validazione2_utente, this.validazione2_data)
    }

    this.onConfirm.emit({
      validazione1_utente: this.validazione1_utente,
      validazione1_data: this.validazione1_data,
      validazione2_utente: this.validazione2_utente,
      validazione2_data: this.validazione2_data,
    })


  }


  getUserDescr(email) {

    let u = this.users.find(x => x.Email == email)

    if (u) return u.Nome + ' ' + u.Cognome
    else return email

  }

}
