<div *ngIf="!item?.okPrivacy && !item?.closed" class="policy">

    <h3 class="title">
        <span>Informativa sulla privacy videoperizia DirectXpert</span>
    </h3>


    <div #privacy id="privacy"></div>

    <!--
    <div>
        <p>&nbsp;</p>
        <p><strong><u>INFORMATIVA</u></strong></p>
        <p><strong><u>EX ART. 13 REG. UE N. 679/2016</u></strong></p>
        <p>&nbsp;</p>
        <p><strong>GENERAL GROUP S.R.L. </strong>nella sua qualit&agrave; di TITOLARE del trattamento dei suoi dati
            personali, ai sensi dell&rsquo;art. 13 Reg. UE n. 679/2016 (di seguito, &ldquo;GDPR&rdquo;), le fornisce le
            seguenti informazioni in relazione ai trattamenti dei dati personali ottenuti attraverso l&rsquo;utilizzo
            della web app di video perizia &ldquo;DirectXpert&rdquo;.</p>
        <p>&nbsp;</p>
        <p>***&nbsp;&nbsp; &sect;&sect;&sect;&nbsp;&nbsp; ***</p>
        <p>General Group s.r.l. tratta i suoi dati personali nel rispetto della normativa in materia prevista dal GDPR,
            dei provvedimenti dell&rsquo;Autorit&agrave; di controllo italiana (Garante per la protezione dei dati
            personali) e del Comitato Europeo per la protezione dei Dati (EDPB).</p>
        <p>Pu&ograve; consultare il testo del Regolamento ed i provvedimenti del Garante sul sito <a
                href="http://www.garanteprivacy.it">www.garanteprivacy.it</a></p>
        <p>&nbsp;</p>
        <p>***&nbsp;&nbsp; &sect;&sect;&sect;&nbsp;&nbsp; ***</p>
        <ol>
            <li><strong><u> identit&agrave;, dati di contatto del titolare del trattamento </u></strong>
                <ul>
                    <li>Il Titolare del trattamento (di seguito, &ldquo;Titolare&rdquo;) &egrave;:</li>
                </ul>
            </li>
        </ol>
        <p>&nbsp;</p>
        <table>
            <tbody>
                <tr>
                    <td width="175">
                        <p><strong>RAGIONE SOCIALE</strong></p>
                    </td>
                    <td width="467">
                        <p>GENERAL GROUP S.R.L.</p>
                    </td>
                </tr>
                <tr>
                    <td width="175">
                        <p>SEDE LEGALE</p>
                    </td>
                    <td width="467">
                        <p>Viale Bruno Buozzi 49, Roma (RM) (00197)</p>
                    </td>
                </tr>
                <tr>
                    <td width="175">
                        <p>DOMICILIO DIGITALE</p>
                    </td>
                    <td width="467">
                        <p><a href="mailto:ggroup@pec.general-group.it">ggroup@pec.general-group.it</a></p>
                    </td>
                </tr>
                <tr>
                    <td width="175">
                        <p>MAIL</p>
                    </td>
                    <td width="467">
                        <p><a href="mailto:gdpr@general-group.it">gdpr@general-group.it</a></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <ol start="2">
            <li><strong><u> finalit&agrave; e basi giuridiche del trattamento</u></strong></li>
        </ol>
        <p>2.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Le finalit&agrave; e le basi giuridiche del
            trattamento sono indicate dalla tabella che segue:</p>
        <p>&nbsp;</p>
        <table>
            <tbody>
                <tr>
                    <td  style="vertical-align: top;">
                        <p><strong>FINALITA&rsquo;</strong></p>
                    </td>
                    <td  style="vertical-align: top;">
                        <p><strong>BASE GIURIDICA</strong></p>
                    </td>
                </tr>
                <tr>
                    <td  style="vertical-align: top;">
                        <p>a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Effettuazione di video &ndash; perizia su richiesta di un Utente</p>
                    </td>
                    <td  style="vertical-align: top;">
                        <p>a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Il contratto o misure precontrattuali su richiesta dell&rsquo;interessato</p>
                    </td>
                </tr>
                <tr>
                    <td  style="vertical-align: top;">
                        <p>b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Effettuazione di video &ndash; perizia nell&rsquo;ambito di gestione di un sinistro
                            assicurativo per conto di compagnie di assicurazioni</p>
                    </td>
                    <td  style="vertical-align: top;">
                        <p>b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Il legittimo interesse del Titolare che consiste nell&rsquo;interesse di GENERAL GROUP
                            S.R.L. di dare esecuzione all&rsquo;incarico ricevuto dalla Compagnia di Assicurazione, con
                            cui l&rsquo;Interessato &egrave; assicurato.</p>
                    </td>
                </tr>
                <tr>
                    <td  style="vertical-align: top;">
                        <p>c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Dare esecuzione ad obblighi di legge</p>
                    </td>
                    <td  style="vertical-align: top;">
                        <p>c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Adempimento di un obbligo di legge cui &egrave; soggetto il Titolare</p>
                    </td>
                </tr>
                <tr>
                    <td  style="vertical-align: top;">
                        <p>d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Esercizio del diritto di agire in giudizio o resistere in giudizio (comprese le sedi
                            alternative di risoluzione delle controversie) per far valere o difendere un diritto del
                            Titolare</p>
                    </td>
                    <td  style="vertical-align: top;">
                        <p>d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Il legittimo interesse che consiste nell&rsquo;interesse di gestire un rapporto con
                            l&rsquo;interessato, basato su lealt&agrave;, correttezza e buona fede.</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p><strong><u>&nbsp;</u></strong></p>
        <p>2.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I dati personali sono raccolti al momento
            dell&rsquo;avvio della video-perizia e, successivamente, nel corso della stessa. I dati personali possono
            essere raccolti anche precedentemente, nel momento in cui viene conferito l&rsquo;incarico.</p>
        <p>2.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Con specifico riferimento alle finalit&agrave; di
            cui alla lettera b), General Group s.r.l. opera in qualit&agrave; di Responsabile del trattamento della
            Compagnia di assicurazione con cui l&rsquo;interessato ha stipulato un contratto assicurativo. In tale
            ipotesi alcuni dati potranno essere raccolti direttamente dalla Compagnia di assicurazione e da questa
            comunicati a General Group S.r.l.</p>
        <p>2.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I presupposti del suddetto trattamento sono:</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (i)
            l&rsquo;esistenza di un contratto tra General Group s.r.l. e la Compagnia di assicurazione;</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ii)
            l&rsquo;esistenza di un contratto di assicurazione tra l&rsquo;Interessato e la Compagnia di assicurazione.
        </p>
        <p>&nbsp;</p>
        <ol start="3">
            <li><strong><u> natura del conferimento dei dati personali e conseguenze dell&rsquo;eventuale
                        rifiuto</u></strong></li>
        </ol>
        <p>3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Il conferimento dei dati personali per il
            perseguimento della finalit&agrave; di cui al punto 2.1 lett. a), b, c) e d) &egrave; un requisito
            necessario ed obbligatorio. Il mancato corretto conferimento implicher&agrave; l&rsquo;impossibilit&agrave;
            di effettuare la video - perizia.</p>
        <p>&nbsp;</p>
        <ol start="4">
            <li><strong><u> natura e tipologia di dati oggetto di trattamento</u></strong></li>
        </ol>
        <p>4.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I dati personali oggetto di trattamento saranno di
            natura comune e non appartengono alle categorie particolari di cui all&rsquo;art. 9, par. 1 GDPR o 10, par.
            1 GDPR.</p>
        <p>4.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In particolare, General Group s.r.l.
            tratter&agrave; i seguenti dati, coerentemente con il rapporto che giustifica l&rsquo;erogazione del
            servizio:</p>
        <ol>
            <li><strong>dati di contatto:</strong> nome e cognome, codice fiscale, data e luogo di nascita, luogo ed
                indirizzo di residenza e/o di domicilio, indirizzo e-mail,</li>
            <li><strong>dati relativi al veicolo:</strong> numero di targa e di telaio del veicolo danneggiato,</li>
            <li><strong>dati di pagamenti:</strong> coordinate bancarie e/o dati della carta di credito,</li>
            <li><strong>immagini video-fotografiche</strong> raccolte nel corso della video-perizia. Prima che la
                video-perizia abbia inizio, verr&agrave; chiesto all&rsquo;interessato di attivare la fotocamera del
                dispositivo.</li>
            <li><strong>dati relativi alla posizione geografica</strong>: il dispositivo verr&agrave; localizzato a fini
                strettamente connessi con il rapporto assicurativo e la corretta gestione del sinistro. Prima che la
                video-perizia abbia inizio, verr&agrave; chiesto all&rsquo;interessato di attivare la localizzazione del
                dispositivo.</li>
        </ol>
        <p><strong>I trattamenti di cui alla lettera d) ed e) avverranno solo ed esclusivamente a fronte di un
                comportamento concludente dell&rsquo;interessato, in mancanza del quale sar&agrave; impossibile
                effettuare il trattamento.</strong></p>
        <p>4.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; General Group s.r.l. tratter&agrave; solo i dati
            strettamente necessari al fine del perseguimento della finalit&agrave; di cui al punto 2.1. L&rsquo;utente
            non dovr&agrave; fornire dati ulteriori rispetto a quanto sopra indicato (immagini non richieste, il volto
            di passanti, familiari o altre persone).</p>
        <p>&nbsp;</p>
        <ol start="5">
            <li><strong><u> modalit&agrave;, misure di sicurezza e strumenti di trattamento</u></strong></li>
        </ol>
        <p>5.1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Il trattamento dei dati personali sar&agrave; improntato
            al rispetto dei principi indicati dal GDPR e dei diritti dell'Interessato.</p>
        <p>5.2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; General Group s.r.l. ha adottato le misure di sicurezza
            (tecniche ed organizzative) adeguate per garantire ed essere in grado di dimostrare, che i trattamenti siano
            effettuati nel rispetto del GDPR. General Group s.r.l. riesamina ed aggiorna le predette misure, ove
            necessario.</p>
        <p>5.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I dati potranno essere trattati sia con strumenti
            elettronici che con strumenti non elettronici.</p>
        <p>&nbsp;</p>
        <ol start="6">
            <li><strong><u> Ambito di accesso ai dati personali da parte della struttura organizzativa del
                        titolare</u></strong></li>
        </ol>
        <p>6.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All&rsquo;interno della struttura organizzativa di
            General Group s.r.l., le operazioni di trattamento saranno effettuate esclusivamente da soggetti
            espressamente autorizzati, nei limiti e secondo le modalit&agrave; di cui ai rispetti atti autorizzativi /
            di designazione.</p>
        <p>&nbsp;</p>
        <ol start="7">
            <li><strong><u> Destinatari dei dati </u></strong></li>
        </ol>
        <p>7.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I dati personali potranno essere messi a
            disposizione di soggetti che non appartengono alla struttura organizzativa di General Group s.r.l.</p>
        <p>7.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In alcuni casi, i destinatari dei dati porranno in
            essere operazioni di trattamento in qualit&agrave; di Responsabili del trattamento, esclusivamente a fronte
            di specifico contratto ex art. 28 GDPR, nei limiti e secondo le modalit&agrave; ivi indicati.</p>
        <p>L&rsquo;elenco aggiornato dei Responsabili &egrave; disponibile presso la sede di General Group s.r.l.</p>
        <p>7.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In altre situazioni, i dati personali potranno
            essere messi a disposizione, mediante comunicazione, alle seguenti categorie di soggetti:</p>
        <ol>
            <li>a) PARTNER</li>
            <li>c) FORNITORI</li>
        </ol>
        <p>7.4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I destinatari non compresi nell&rsquo;Elenco dei
            responsabili, tratteranno i dati personali come autonomi Titolari o Contitolari, e saranno soggetti ai
            relativi obblighi.</p>
        <p>7.5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I dati personali non saranno oggetto di diffusione.
        </p>
        <p>7.6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I dati personali non saranno trasferiti al di fuori
            dell&rsquo;Unione Europea.</p>
        <p>&nbsp;</p>
        <ol start="8">
            <li><strong><u> periodo di conservazione dei dati personali / criteri utilizzati per determinare tale
                        periodo</u></strong></li>
        </ol>
        <p>8.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I dati saranno trattati per il tempo necessario al
            fine di effettuare la video &ndash; perizia e le attivit&agrave; connesse.</p>
        <p>8.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Nell&rsquo;ambito del rapporto assicurativo, i dati
            necessari saranno conservati per la durata del rapporto stesso.</p>
        <p>8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ad ogni modo, una volta perseguita la predetta
            finalit&agrave;, General Group S.R.L. potr&agrave; trattare i dati strettamente necessari per un ulteriore
            periodo di 10 (dieci) dalla data di cessazione dell&rsquo;incarico:</p>
        <p>- sulla base di disposizioni di legge, ai fini di adempiere agli obblighi di conservazione di natura civile,
            contabile e fiscale,</p>
        <p>- sulla base del legittimo interesse di General Group s.r.l.: per far valere o difendere i diritti in
            giudizio.</p>
        <p>&nbsp;</p>
        <ol start="9">
            <li><strong><u> diritti degli interessati</u></strong></li>
        </ol>
        <p>9.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Gli Interessati hanno il diritto, in qualunque
            momento di ottenere &ndash; ove ne ricorrano i presupposti:</p>
        <ol>
            <li>a) la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano e, in tale
                caso, l&rsquo;accesso ai dati personali ed alle informazioni, secondo quanto previsto dall&rsquo;art. 15
                GDPR,</li>
            <li>b) la rettifica dei dati personali inesatti che lo riguardano, compresa l'integrazione dei dati
                personali incompleti, anche fornendo una dichiarazione integrativa, secondo quanto previsto
                dall&rsquo;art. 16 GDPR,</li>
            <li>c) la cancellazione dei dati personali che lo riguardano, secondo quanto previsto dall&rsquo;art. 17
                GDPR,</li>
            <li>d) la limitazione del trattamento, secondo quanto previsto dall&rsquo;art. 18 GDPR,</li>
            <li>e) la portabilit&agrave; dei dati personali, secondo quanto previsto dall&rsquo;art. 20 GDPR,</li>
            <li>f) l&rsquo;opposizione al trattamento, secondo quanto previsto dall&rsquo;art. 21 GDPR.</li>
        </ol>
        <p>9.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Il diritto a revocare il consenso, in qualsiasi
            momento. La revoca del consenso non far&agrave; venire meno la liceit&agrave; dei trattamenti effettuati in
            base a tale consenso prestato o effettuato su altre basi giuridiche.</p>
        <p>Per una migliore gestione, le richieste vanno rivolte:</p>
        <table>
            <tbody>
                <tr>
                    <td width="175">
                        <p>VIA E-MAIL</p>
                    </td>
                    <td width="467">
                        <p>gdpr@general-group.it</p>
                    </td>
                </tr>
                <tr>
                    <td width="175">
                        <p>VIA PEC</p>
                    </td>
                    <td width="467">
                        <p>ggroup@pec.general-group.it</p>
                    </td>
                </tr>
                <tr>
                    <td width="175">
                        <p>VIA POSTA ORDINARIA</p>
                    </td>
                    <td width="467">
                        <p>Viale Bruno Buozzi n. 49, Roma (RM) (00197)</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>Si prega inoltre di indicare nell&rsquo;oggetto: &ldquo;Richiesta ai sensi del Reg. UE n. 679/2016&rdquo;
            specificando il diritto che si intende far valere, come sopra specificato.</p>
        <p>&nbsp;</p>
        <ol start="10">
            <li><strong><u> diritto di proporre reclamo a un'autorit&agrave; di controllo o ricorso
                        giurisdizionale</u></strong></li>
        </ol>
        <p>10.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Nel caso in cui ritenga che il trattamento che lo riguarda
            violi il GDPR, l&rsquo;interessato ha diritto di proporre reclamo avanti ad un&rsquo;Autorit&agrave; di
            controllo. La predetta Autorit&agrave; di Controllo pu&ograve; essere quella dello Stato membro in cui
            l&rsquo;Interessato risiede abitualmente, ovvero quella del luogo in cui si &egrave; verificata la presunta
            violazione.</p>
        <p>&nbsp;</p>
        <table>
            <tbody>
                <tr>
                    <td width="177">
                        <p>Autorit&agrave; di controllo italiana</p>
                    </td>
                    <td width="475">
                        <p>Garante per la protezione dei dati personali</p>
                        <p>Sede legale: Piazza Venezia n. 11, scala B - 00187 ROMA</p>
                        <p>PEC: urp [@] gpdp.it</p>
                        <p>Sito: http://www.garanteprivacy.it/</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p>&nbsp;</p>
        <p>10.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; L&rsquo;interessato ha il diritto di proporre un ricorso
            giurisdizionale nel caso in cui ritenga che i diritti di cui gode a norma del GDPR siano stati violati a
            seguito di un trattamento. Le azioni nei confronti del Titolare del trattamento o del Responsabile del
            trattamento sono promosse dinanzi alle autorit&agrave; giurisdizionali dello Stato membro in cui il Titolare
            del trattamento o il Responsabile del trattamento ha uno stabilimento. In alternativa, tali azioni possono
            essere promosse dinanzi alle autorit&agrave; giurisdizionali dello Stato membro in cui l'Interessato risiede
            abitualmente.</p>
        <p>&nbsp;</p>
        <p>Il presente documento, pubblicato all'indirizzo</p>
        <p><a href="https://www.general-group.it/">https://www.general-group.it/</a></p>
        <p>costituisce la "L&rsquo;informativa specifica VIDEO-PERIZIA" di questo sito che potr&agrave; essere soggetta
            ad aggiornamenti.</p>
        <p>Presso la sede del Titolare sono conservate le precedenti versioni.</p>
        <p>Versione pubblicata in data 21 aprile 2020</p>
    </div>
-->
    <div>

        <button type="button" mat-raised-button class="btn btn-success" style="float:right; width:100%"
            (click)="setOkPrivacy(true)">CONSENTO
        </button>
        <button type="button" mat-raised-button class="btn btn-info" style="float:right; width:100%"
            (click)="setOkPrivacy(false)">NON CONSENTO
        </button>

        <br>
        <br>
        <br>
        <br>
        <br>
    </div>

</div>

<app-videochat inverted="true" [captureEnabled]="false" *ngIf="sessionId && token && !item?.closed && item?.okPrivacy"
    [hideThumb]="hideThumb" [sessionId]="sessionId" [token]="token" [(receiveCommand)]="cmd"
    (onOkPrivacy)="okPrivacyCam($event)" (onSetStream)="setStream($event)"></app-videochat>