
  <table style="margin:10px" style="width:90%">
    <tr>
      <td colspan=3 style="font-size: 180% ; padding:0">

        <img style=" width:64px" [src]="'http://openweathermap.org/img/w/' + data.weather[0].icon + '.png'">
        {{data.weather[0].description |uppercase}}
        <hr>

      </td>
    </tr>

    <tr>
      <td>&nbsp;</td>
      <td>
        Temperature:
      </td>
      <td>
        {{data.main.temp| number: '.0-1'}} °C
      </td>
    </tr>

    <tr>
      <td>&nbsp;</td>
      <td>
        Umidità:
      </td>
      <td>
        {{data.main.humidity}} %
      </td>
    </tr>

    <tr>
      <td>&nbsp;&nbsp;&nbsp;</td>
      <td>
        Vento:
      </td>
      <td>
        {{data.wind.speed}} m/s
      </td>
    </tr>

    <tr *ngIf="data?.clouds?.all">
        <td>&nbsp;</td>
        <td>
          Nuvole:
        </td>
        <td>
          {{data.clouds.all}} %
        </td>
      </tr>

    <tr *ngIf="data.rain">
      <td>&nbsp;</td>
      <td>
        Pioggia:
      </td>
      <td>
        {{data.rain | number: '.2-2'}} mm/h
      </td>
    </tr>

    <tr *ngIf="data.snow">
      <td>&nbsp;</td>
      <td>
        Neve:
      </td>
      <td>
        {{data.snow | number: '.2-2'}} mm/h
      </td>
    </tr>

  </table>

  