<div class="main-content" style="height: calc(100vh - 50px); padding-top:10px; margin-bottom:0">


  <form [formGroup]="form" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" style="height: 100%; ">

    <div class="card">

      <div class="card-header card-header-info">
        <h4 class="card-title">

          Certificato: {{item.id ? item?.nrCommessaInt + '/' + item?.y : 'NUOVO'}} <span *ngIf="item.id">del
            {{item?.dataCommessa | date: 'dd/MM/yyyy'}} (SOLA LETTURA)</span>
          <div style="float:right">
            <button style="margin-right: 10px;" mat-raised-button type="submit" class="btn btn-success"
              *ngIf="!isDisabled()">Salva
            </button>
            <button style="margin-right: 10px;" type="button" mat-raised-button class="btn btn-info "
              (click)="downloadCert()" *ngIf="isDisabled()">Scarica certificato</button>
            <a (click)="close()"><i class="material-icons">close</i></a>
          </div>
        </h4>
      </div>

      <div class="card-body" style="width: 100%!important; height: 100%" *ngIf="loading">
        <div class="row">
          <div class="col-sm-2" style="margin:auto">

            <mat-spinner *ngIf="isMSIE()"></mat-spinner>
            <mat-progress-spinner mode="indeterminate" [value]="loading_percentage" *ngIf="!isMSIE()">
            </mat-progress-spinner>

          </div>
        </div>

      </div>

      <div class="card-content" style="height: calc(100vh - 100px); overflow-x:hidden; overflow-y:auto; padding:20px;"
        [hidden]="loading">

        <div class="row">


          <div class="col">
            <mat-form-field>
              <mat-label>Tipo cliente</mat-label>
              <mat-select placeholder="Tipo cliente" [(ngModel)]="item.cliente" formControlName="cliente"
                [disabled]="isDisabled()">
                <mat-option value="consumatore">Consumatore</mat-option>
                <mat-option value="societa">Società</mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div class="col">
            <mat-form-field>
              <mat-label>Telaio di provenienza (se conosciuto)</mat-label>
              <input matInput type="text" placeholder="Telaio di provenienza" [(ngModel)]="item.telaioDiProvenienza"
                formControlName="telaioDiProvenienza" [readonly]="isDisabled()">
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field>
              <mat-label>Tipo motore</mat-label>
              <input matInput type="text" placeholder="Tipo motore" [(ngModel)]="item.tipoMotore"
                formControlName="tipoMotore" [readonly]="isDisabled()">
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field>
              <mat-label>Tipo rev.</mat-label>
              <mat-select placeholder="Tipo rev." [(ngModel)]="item.tipoRev" formControlName="tipoRev"
                [disabled]="isDisabled()">
                <mat-option value="COMPLETO">Completo</mat-option>
                <mat-option value="SEMI-COMPLETO">Semi-completo</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field>
              <mat-label>Responsabile interno</mat-label>
              <input matInput type="text" placeholder="Responsabile interno" [(ngModel)]="item.respInt"
                formControlName="respInt" [readonly]="isDisabled()">
            </mat-form-field>
          </div>

        </div>


        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>Anomalie riscontrate in ingresso</mat-label>
              <textarea matInput type="text" placeholder="Descrizione>" [(ngModel)]="item.anomalieIN"
                formControlName="anomalieIN" [readonly]="isDisabled()" matTextareaAutosize matAutosizeMinRows=2
                matAutosizeMaxRows=25></textarea>
            </mat-form-field>
          </div>
        </div>


        <!------------------------------------------------- SMONTAGGIO ------------------------------------------->
        <div class="row">

          <div class="col-8 title">
            SMONTAGGIO
          </div>

          <div class="col-1 title">
            Eseguita
          </div>

          <div class="col-3 title">
            &nbsp;
          </div>

        </div>

        <div class="row hoverable">

          <div class="col-8">

            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['smontaggio_eseguito'].invalid">error</i>

            Smontaggio di tutti gli organi di base del motore e frazionamento degli stessi in gruppi e sottogruppi in
            base alle procedure di lavoro prestabilite per ognuno di essi.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.smontaggio_eseguito" formControlName="smontaggio_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>


        <!------------------------------------------------- LAVAGGIO ------------------------------------------->
        <div class="row">

          <div class="col-8 title">
            LAVAGGIO
          </div>

          <div class="col-1 title">
            Eseguita
          </div>

          <div class="col-3 title">
            &nbsp;
          </div>

        </div>

        <div class="row hoverable">

          <div class="col-8">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['lavaggio_eseguito'].invalid">error</i>
            Procedura effettuata con l’ausilio di solventi chimici ad alta azione degrassante e disincrostazione in
            apposite vasche collegate ad un circuito di acido e recupero dei liquidi utilizzati e/o lavaggio ad
            ultrasuoni.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lavaggio_eseguito" formControlName="lavaggio_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>
        <!-------------------------------------------------------------------------------------------------------->


        <!------------------------------------------------- CONTROLLO ------------------------------------------->
        <div class="row">

          <div class="col-8 title">
            CONTROLLO
          </div>

          <div class="col-1 title">
            Eseguita
          </div>

          <div class="col-3 title">
            Difformità
          </div>

        </div>

        <div class="row hoverable">

          <div class="col-8">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['controllo_eseguito'].invalid">error</i>
            Rilevazione delle misure dimensionali, misurazione delle profondità e delle corrosioni, prova idraulica
            testate e controlli non distruttivi su albero motore, selezione dei pezzi con separazione dei sostituiti ed
            accantonamento al fine di essere messi a disposizione del cliente per un eventuale accertamento.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.controllo_eseguito" formControlName="controllo_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            <mat-form-field>
              <mat-select [(ngModel)]="item.controllo_difformita" formControlName="controllo_difformita"
                [disabled]="isDisabled()">
                <mat-option value="Valori accettabili">Valori accettabili</mat-option>
                <mat-option value="Fuori tolleranza">Fuori tolleranza</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>



        <!------------------------------------------------- LAVORAZIONI MECCANICHE ------------------------------------------->
        <div class="row">

          <div class="col-8 title">
            LAVORAZIONI MECCANICHE
          </div>

          <div class="col-1 title">
            Eseguita
          </div>

          <div class="col-3 title">
            Elementi accessori
          </div>

        </div>

        <div class="row hoverable">

          <div class="col-8">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['lm_incamiciatura_eseguito'].invalid">error</i>
            Incamiciatura e/o rettifica dell’alloggiamento canne cilindri o montaggio nuove camicie e tiraggio delle
            stesse sul monoblocco ai valori prescritti.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_incamiciatura_eseguito" formControlName="lm_incamiciatura_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            <mat-form-field>
              <mat-select [(ngModel)]="item.lm_incamiciatura_el_accessori"
                formControlName="lm_incamiciatura_el_accessori" [disabled]="isDisabled()">
                <mat-option value="Rettifica">Rettifica</mat-option>
                <mat-option value="Incamiciatura">Incamiciatura</mat-option>
                <mat-option value="Camicie nuove">Camicie nuove</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row hoverable">

          <div class="col-8">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['lm_pianatura_eseguito'].invalid">error</i>
            Spianatura dei piani di contatto superiore.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_pianatura_eseguito" formControlName="lm_pianatura_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>

        <div class="row hoverable">

          <div class="col-8">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['lm_alloggiamento_albero_eseguito'].invalid">error</i>
            Alloggiamento albero motore: barenatura (quando necessario, si faccia riferimento alle tabelle delle
            tolleranze previste dal costruttore) alloggi cuscinetti di banco e lavorazione degli stessi per riportare i
            diametri ai valori originali, montaggio cuscinetti e controllo tiratura.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_alloggiamento_albero_eseguito"
              formControlName="lm_alloggiamento_albero_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            <mat-form-field>
              <mat-select [(ngModel)]="item.lm_alloggiamento_albero_el_accessori"
                formControlName="lm_alloggiamento_albero_el_accessori" [disabled]="isDisabled()">
                <mat-option value="Barenatura necessaria">Barenatura necessaria</mat-option>
                <mat-option value="Barenatura non necessaria">Barenatura non necessaria</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row hoverable">

          <div class="col-8">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['lm_sost_bronzine_eseguito'].invalid">error</i>
            Sostituzione bronzine e spallamenti
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_bronzine_eseguito" formControlName="lm_sost_bronzine_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>

        <div class="row hoverable">

          <div class="col-8">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['lm_sost_bielle_eseguito'].invalid">error</i>
            Sostituzione delle bielle (quando necessario).
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_bielle_eseguito" formControlName="lm_sost_bielle_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>

        <div class="row hoverable">

          <div class="col-8">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['lm_mont_cuscinetti_eseguito'].invalid">error</i>
            Montaggio cuscinetti testa di biella, imboccolatura del piede, alesatura e successivo controllo della
            squadratura.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_mont_cuscinetti_eseguito"
              formControlName="lm_mont_cuscinetti_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>

        <div class="row hoverable">

          <div class="col-8">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['lm_mont_pistoni_eseguito'].invalid">error</i>
            Montaggio dei pistoni sulla biella.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_mont_pistoni_eseguito" formControlName="lm_mont_pistoni_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-8">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['lm_mont_bielle_eseguito'].invalid">error</i>
            Montaggio delle bielle sull’albero e controllo giochi.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_mont_bielle_eseguito" formControlName="lm_mont_bielle_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>










        <!-----------------------------------ALBERO MOTORE SOST -------------------------------------------------->


        <div class="row hoverable subtitle">

          <div class="col-8 ">
            <i class="material-icons" style="float:left; color:red; margin-right:10px" *ngIf="errorAlbero">error</i>

            Albero Motore - sostituzione
          </div>

          <div class="col-1">

            <mat-form-field>
              <mat-select [(ngModel)]="item.lm_sost_albero_eseguito" formControlName="lm_sost_albero_eseguito" [disabled]="isDisabled()">
                <mat-option value="NO">No</mat-option>
                <mat-option value="USATO">Usato</mat-option>
                <mat-option value="NUOVO">Nuovo</mat-option>
              </mat-select>
            </mat-form-field>

            <!--
            <mat-slide-toggle [(ngModel)]="item.lm_sost_albero_eseguito" formControlName="lm_sost_albero_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
            -->
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_albero_eseguito =='NUOVO'  && !isDisabled() }">
          <div class="col-8">
            Lucidatura o rettifica (quando necessario, si faccia riferimento alle tabelle delle tolleranze previste dal
            costruttore) dei colli e portate del banco in esito allo stato di conservazione.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_albero_rettifica_eseguito"
              formControlName="lm_sost_albero_rettifica_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">

            <mat-form-field>
              <mat-select [(ngModel)]="item.lm_sost_albero_rettifica" formControlName="lm_sost_albero_rettifica"
                [disabled]="isDisabled()">
                <mat-option value="LUCIDATURA">Lucidatura</mat-option>
                <mat-option value="R025">Rettifica 0,25</mat-option>
                <mat-option value="R050">Rettifica 0,50</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_albero_eseguito  =='NUOVO'  && !isDisabled() }">
          <div class="col-8">
            Stappatura dei condotti olio (se previsto), pulizia residui carboniosi o di lavorazione e ritappatura
          </div>

          <div class="col-1">
              <mat-slide-toggle [(ngModel)]="item.lm_sost_albero_stappatura_eseguito" formControlName="lm_sost_albero_stappatura_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>


          <div class="col-3">
            &nbsp;
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_albero_eseguito  =='NUOVO'  && !isDisabled() }">

          <div class="col-8">
            Si prescrive la sostituzione in caso di tolleranze oltre misura prevista da costruttore.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_albero_prescrizione"
              formControlName="lm_sost_albero_prescrizione" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>











        <!----------------------------------- TESTATE SOST -------------------------------------------------->


        <div class="row hoverable subtitle">

          <div class="col-8"><i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="errorTesta">error</i>
            Testate - sostituzione
          </div>

          <div class="col-1">

            <!--
            <mat-slide-toggle [(ngModel)]="item.lm_sost_testate_eseguito" formControlName="lm_sost_testate_eseguito"
              [disabled]="isDisabled()"></mat-slide-toggle>
            -->
              <mat-form-field>
                <mat-select [(ngModel)]="item.lm_sost_testate_eseguito" formControlName="lm_sost_testate_eseguito" [disabled]="isDisabled()">
                  <mat-option value="NO">No</mat-option>
                  <mat-option value="USATO">Usato</mat-option>
                  <mat-option value="NUOVO">Nuovo</mat-option>
                </mat-select>
              </mat-form-field>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>




        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_testate_eseguito == 'NUOVO' && !isDisabled()}">
          <div class="col-8">
            Spianatura del piano testa, sostituzione guida valvole e alesatura delle stesse.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_testate_spianatura_eseguito"
              formControlName="lm_sost_testate_spianatura_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">

            <mat-form-field>
              <mat-select [(ngModel)]="item.lm_sost_testate_spianatura_rettifica"
                formControlName="lm_sost_testate_spianatura_rettifica" [disabled]="isDisabled()">
                <mat-option value="R025">Rettifica 0,05</mat-option>
                <mat-option value="R025">Rettifica 0,10</mat-option>
                <mat-option value="R025">Rettifica 0,15</mat-option>
                <mat-option value="R025">Rettifica 0,20</mat-option>
                <mat-option value="R025">Rettifica 0,25</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>


        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_testate_eseguito == 'NUOVO' && !isDisabled()}">
          <div class="col-8">
            Misurazione e rettifica e/o sostituzione (quando necessario, si faccia riferimento alle tabelle delle
            tolleranze previste dal costruttore) successiva fresatura delle sedi valvole.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_testate_misurazione_eseguito"
              formControlName="lm_sost_testate_misurazione_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">

            <mat-form-field>
              <mat-select [(ngModel)]="item.lm_sost_testate_misurazione_rettifica"
                formControlName="lm_sost_testate_misurazione_rettifica" [disabled]="isDisabled()">
                <mat-option value="sost">Sostituzione</mat-option>
                <mat-option value="R025">Rettifica 0,05</mat-option>
                <mat-option value="R025">Rettifica 0,10</mat-option>
                <mat-option value="R025">Rettifica 0,15</mat-option>
                <mat-option value="R025">Rettifica 0,20</mat-option>
                <mat-option value="R025">Rettifica 0,25</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_testate_eseguito == 'NUOVO' && !isDisabled()}">
          <div class="col-8">
            Controllo della linearità dei supportini porta albero e camme.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_testate_controllo_supportini_eseguito"
              formControlName="lm_sost_testate_controllo_supportini_eseguito" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>


        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_testate_eseguito == 'NUOVO' && !isDisabled()}">
          <div class="col-8">
            Sostituzione sistematica delle valvole. (per applicazioni fino a 3.5t)
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_testate_sost_valvole_eseguito"
              formControlName="lm_sost_testate_sost_valvole_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>


        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_testate_eseguito == 'NUOVO' && !isDisabled()}">
          <div class="col-8">
            Controllo ingranaggi della distribuzione ed eventuale sostituzione (quando necessario)
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_testate_controllo_distrib_eseguito"
              formControlName="lm_sost_testate_controllo_distrib_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>


        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_testate_eseguito == 'NUOVO' && !isDisabled()}">
          <div class="col-8">
            Controllo del profilo delle camme e lucidatura delle zone di rotolamento ed eventuale rettifica (quando
            necessario)
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_testate_controllo_camme_eseguito"
              formControlName="lm_sost_testate_controllo_camme_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>


        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_testate_eseguito == 'NUOVO' && !isDisabled()}">
          <div class="col-8">
            Pulitura dei condotti di lubrificazione.
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_testate_pulitura_condotti_eseguito"
              formControlName="lm_sost_testate_pulitura_condotti_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>


        <div class="row hoverable" [ngClass]="{ 'hidden' : item.lm_sost_testate_eseguito == 'NUOVO' && !isDisabled()}">
          <div class="col-8">
            Sostituzione di tutte le guarnizioni di tenuta. Perni vari e prigionieri (quando necessario).
          </div>

          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.lm_sost_testate_sost_guarniz_eseguito"
              formControlName="lm_sost_testate_sost_guarniz_eseguito" [disabled]="isDisabled()"></mat-slide-toggle>
          </div>

          <div class="col-3">
            &nbsp;
          </div>
        </div>









        <!------------------------------------------------- MATERIALI ------------------------------------------->
        <div class="row">

          <div class="col-11 title">
            MATERIALI (i particolari di seguito indicati, ove presenti, dovranno essere sempre sostituiti in occasione
            della revisione utilizzando ricambi nuovi ed equivalente all’originale. )
          </div>

          <div class="col-1 title">
            Sost
          </div>

        </div>



        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_canne'].invalid">error</i>
            Serie canne cilindro. (rettifica se necessario incamiciatura)
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_canne" formControlName="mat_canne" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_pistoni'].invalid">error</i>
            Serie pistoni completi di anelli elastici e perno-spinotto.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_pistoni" formControlName="mat_pistoni" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_boccole'].invalid">error</i>
            Serie boccole piede di biella.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_boccole" formControlName="mat_boccole" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_semicuscinetti'].invalid">error</i>
            Serie di semicuscinetti di biella.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_semicuscinetti" formControlName="mat_semicuscinetti"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_cuscinetti'].invalid">error</i>
            Serie di cuscinetti di banco.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_cuscinetti" formControlName="mat_cuscinetti"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_semianelli'].invalid">error</i>
            Serie di semi - anelli di spallamento (quando necessario).
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_semianelli" formControlName="mat_semianelli"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_guarnizioni'].invalid">error</i>
            Tutte le guarnizioni di tenuta di qualunque tipo e impiego.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_guarnizioni" formControlName="mat_guarnizioni"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_albero'].invalid">error</i>
            Albero dei bilancieri per distribuzione. (quando necessario)
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_albero" formControlName="mat_albero" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_bilanceri'].invalid">error</i>
            Serie completa bilancieri distribuzione. (quando necessario)
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_bilanceri" formControlName="mat_bilanceri"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_cinghie'].invalid">error</i>
            Cinghie di comando distribuzione. (quando previsto da allestimento)
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_cinghie" formControlName="mat_cinghie" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_valvole'].invalid">error</i>
            Serie valvole aspirazione e scarico.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_valvole" formControlName="mat_valvole" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_guide_valvole'].invalid">error</i>
            Serie guide valvole aspirazione e valvole.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_guide_valvole" formControlName="mat_guide_valvole"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_punterie'].invalid">error</i>
            Serie punterie distribuzione. (quando necessario per punterie meccaniche sempre per idrauliche)
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_punterie" formControlName="mat_punterie" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_corona'].invalid">error</i>
            Corona dentata di avviamento (o volano-corona d’avviamento completo se del caso).
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_corona" formControlName="mat_corona" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_valvole_lub'].invalid">error</i>
            Valvole del circuito di lubrificazione. (quando necessario)
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_valvole_lub" formControlName="mat_valvole_lub"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_filtri_olio'].invalid">error</i>
            Filtri olio lubrificante.(quando necessario)
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_filtri_olio" formControlName="mat_filtri_olio"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_tubi_iniet'].invalid">error</i>
            Serie di tubi iniettori.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_tubi_iniet" formControlName="mat_tubi_iniet"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_tubi_rit'].invalid">error</i>
            Tubazione ritorno iniettori
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_tubi_rit" formControlName="mat_tubi_rit" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_polv'].invalid">error</i>
            Serie di polverizzatori
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_polv" formControlName="mat_polv" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_pompa'].invalid">error</i>
            Revisione apparato pompa iniezione(è ammissibile far eseguire la lavorazione a terzi)
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_pompa" formControlName="mat_pompa" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_manicotti'].invalid">error</i>
            Tutti i manicotti in gomma (silicone) per acqua.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_manicotti" formControlName="mat_manicotti"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_valvole_termo'].invalid">error</i>
            Serie valvole termostatiche.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_valvole_termo" formControlName="mat_valvole_termo"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_candelette'].invalid">error</i>
            Candelette preriscaldo.
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_candelette" formControlName="mat_candelette"
              [disabled]="isDisabled()"></mat-slide-toggle>
          </div>
        </div>

        <div class="row hoverable" [ngClass]="{'hidden': item.tipoRev != 'COMPLETO' }">
          <div class="col-11">
            <i class="material-icons" style="float:left; color:red; margin-right:10px"
              *ngIf="isSubmitted && form.controls['mat_turbo'].invalid">error</i>
            Revisione e/o sostituzione turbocompressore
          </div>
          <div class="col-1">
            <mat-slide-toggle [(ngModel)]="item.mat_turbo" formControlName="mat_turbo" [disabled]="isDisabled()">
            </mat-slide-toggle>
          </div>
        </div>


        <div style="float:right; margin-top: 10px">
          <button style="margin-right: 10px;" mat-raised-button type="submit" class="btn btn-success"
            *ngIf="!isDisabled()">Salva
          </button>
          <button style="margin-right: 10px;" type="button" mat-raised-button class="btn btn-info "
            (click)="downloadCert()" *ngIf="isDisabled()">Scarica certificato</button>
        </div>



        <!-------------------------------------------------------------------------------------------------------->

      </div>



    </div>

  </form>

</div>