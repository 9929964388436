<div style="height:90%" class="card">
    <div class="card-header card-header-info">
      <h4 class="card-title">Dettaglio Costi/RIcavi
        <a style="float:right" (click)="close()"><i class="material-icons">close</i></a></h4>
      <p class="card-category"></p>
    </div>
    <div class="card-body">
  
      <form [formGroup]="form" (ngSubmit)="save()">
  
        <mat-form-field class="full">
          <mat-label>Tipo Veicolo</mat-label>
          <mat-select placeholder="Attività" [(ngModel)]="tipoVeicolo" formControlName="tipoVeicolo">
            <mat-option value="tutti">Tutti</mat-option>
            <mat-option value="auto">Automobile</mat-option>
            <mat-option value="moto">Moto</mat-option>
            <mat-option value="vcom">Veicoli Commerciali</mat-option>
            <mat-option value="vind">Veicoli Industriali</mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field class="full">
          <mat-label>Documentale</mat-label>
          <mat-select placeholder="SI/NO" [(ngModel)]="documentale" formControlName="documentale">
            <mat-option value="true">SI</mat-option>
            <mat-option value="false">NO</mat-option>

          </mat-select>
        </mat-form-field>
  
        <mat-form-field class="full">
          <mat-label>Ricavo</mat-label>
          <input type="number" matInput placeholder="Ricavo" formControlName="ricavo" [(ngModel)]="ricavo">
        </mat-form-field>

        <mat-form-field class="full">
            <mat-label>Costo</mat-label>
            <input type="number" matInput placeholder="Costo" formControlName="costo" [(ngModel)]="costo">
          </mat-form-field>
  
  
        <button style="float:right" mat-raised-button type="submit" class="btn btn-danger">Salva</button>
  
      </form>
  
    </div>
  </div>