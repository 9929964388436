<form [formGroup]="form_location_search">
  <mat-form-field class="full">
    <div [id]="'searchBoxContainer_' + id">
      <input matInput type='text' [id]="'searchBox_' + id" formControlName="locationSearch"
        placeholder="Cerca indirizzo..." [readonly]="disabled" autocomplete="off" [(ngModel)]="locationSearch" />
    </div>
  </mat-form-field>

  <div [id]="'map_' + id" style='width: 100%; margin-left:15px; margin-right:45px; margin-bottom:15px'
    [ngStyle]="{'height' : height, 'display': showMap ? 'block' : 'none' }">
  </div>

</form>