<div style="height:90%" class="card">
  <div class="card-header card-header-info" *ngIf="formData">
    <h4 class="card-title">{{formData.description}}
      <a style="float:right" (click)="close()"><i class="material-icons">close</i></a></h4>
    <p class="card-category"></p>
    <div style="float:right">
      <div  style="float:right">
          <button value="EXP" class="btn btn-info" (click)="leedExport()">Esporta Risultati</button>
      </div>
      
  </div>

  </div>
  <div class="card-body">


      <mat-tab-group style="min-height: 500px; overflow-y: hidden" *ngIf="formData">

    

        <mat-tab *ngFor="let u of formQuestions" [label]="u.cols[0].id" > <br>
          <h2>Domanda: {{u.cols[0].descr}}</h2>
          <p>Tipo: <span style="font-weight: bold;">{{u.cols[0].type}}</span></p>
          <div *ngIf="u.cols[0].type == 'input-toggle'">
            <p>Si:{{getYesNo(rowData[u.cols[0].id], true)}} , No:{{getYesNo(rowData[u.cols[0].id], false)}}</p>
          </div>
          <div *ngIf="u.cols[0].type == 'input-checkbox' ">
            <p>Si:{{getYesNo(rowData[u.cols[0].id], true)}} , No:{{getYesNo(rowData[u.cols[0].id], false)}}</p>
          </div>
          <div *ngIf="u.cols[0].type == 'input-select' ">
            <p *ngFor="let sel of spliceSelect(u.cols[0].values) index as ind"><span *ngIf="getCombo(rowData[u.cols[0].id], sel)!=0"><span style="font-weight: bold;">{{sel}}</span>:&nbsp;&nbsp;{{getCombo(rowData[u.cols[0].id], sel)}}</span> </p>
          </div>
          <div *ngIf="u.cols[0].type == 'input-slider' ">
            <p *ngFor="let sel of setArrayOfNum(u.cols[0].nstep) index as ind"><span *ngIf="getTotalSlider(rowData[u.cols[0].id],sel)!=0"><span style="font-weight: bold;">{{sel}}</span>:&nbsp;&nbsp;{{getTotalSlider(rowData[u.cols[0].id],sel)}}</span> </p>
          </div>
          <ag-grid-angular style="width: 100%; height: calc(100vh - 300px);"
          class="ag-theme-alpine" [rowData]="rowData[u.cols[0].id]" [rowStyle]="rowStyle"
          [getRowStyle]="getRowStyle" *ngIf="rowData && tableReady" [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"  (gridReady)="onGridReady($event)"
          rowSelection="multiple">
      </ag-grid-angular>
          
        </mat-tab>

      

      </mat-tab-group>

      <button style="float:right" mat-raised-button type="submit" class="btn btn-danger">Salva</button>



  </div>
</div>