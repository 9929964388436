<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">

                <div class="card">
                    <div class="card-header card-header-info" style="float:right">
                        <h4 class="card-title">Sondaggi</h4>
                        <div style="float:right">
                            <div *ngIf="this.tab==0" style="float:right">
                                <button value="EXP" class="btn btn-info" (click)="showDialogSend()">Invia Sondaggio</button>
                            </div>
                            
                        </div>



                    </div>
                    <div class="card-body" style=" margin-bottom:0; padding-bottom:0;">

                        <mat-tab-group (selectedTabChange)="tabChange($event)" style="height: 100vh!important;">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon style="margin: 0!important;" class="example-tab-icon">list</mat-icon>
                                    Utenti UXpert
                                </ng-template>
                                <br>

                                <div class="row">
                                    <div class="col">

                                        <div style="width:100%; min-width: 1000px; min-height: 300px; overflow-x: auto">
                                            <mat-spinner *ngIf="!rowData" style="margin:100px auto"></mat-spinner>


                                            <ag-grid-angular style="width: 100%; height: calc(100vh - 300px);"
                                                class="ag-theme-alpine" [rowData]="rowData" [rowStyle]="rowStyle"
                                                [getRowStyle]="getRowStyle" *ngIf="rowData" [columnDefs]="columnDefs"
                                                [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)"
                                                rowSelection="multiple">
                                            </ag-grid-angular>




                                        </div>

                                    </div>



                                </div>


                            </mat-tab>

                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <mat-icon  style="margin: 0!important;" class="example-tab-icon">list</mat-icon>
                                    Sondaggi
                                </ng-template>
                                <br>

                                <div class="row">
                                    <div class="col">

                                        <div style="width:100%; min-width: 1000px; min-height: 300px; overflow-x: auto">
                                            <mat-spinner *ngIf="!rowData2" style="margin:100px auto"></mat-spinner>


                                            <ag-grid-angular style="width: 100%; height: calc(100vh - 300px);"
                                                class="ag-theme-alpine" [rowData]="rowData2" [rowStyle]="rowStyle2"
                                                [getRowStyle]="getRowStyle2" *ngIf="rowData2 && tableReady" [columnDefs]="columnDefs2"
                                                [defaultColDef]="defaultColDef2"
                                                rowSelection="multiple">
                                            </ag-grid-angular>




                                        </div>

                                    </div>



                                </div>

                                <button mat-fab class="fab-toggler mat-primary" color="primary"
                                    style="float:right; margin-bottom:60px" (click)="onCreateDialog()">
                                    <i style="margin: 0!important;" class="material-icons">add</i>
                                </button>

                            </mat-tab>




                        </mat-tab-group>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="dialog" *ngIf="showDialog">

    <div class="card shadowed">
  
      <div class="card-header card-header-info">
        <h4 class="card-title" style="float:left;">Seleziona un sondaggio</h4>
  
        <button style="float:right;  padding:0" mat-icon-button color="info" aria-label="Info"
          class="btn-secondary" (click)="close()">
          <mat-icon>close</mat-icon> 
        </button>
  
  
      </div>
  
      <div class="card-body">
  
        <mat-select placeholder="Sondaggio" [(ngModel)]="selectedLeed" style="padding-bottom:2% !important">
            <mat-option *ngFor="let u of rowData2" [value]="u.code">{{u.description}}</mat-option>
          </mat-select>
  
      </div>
  
      <button class="btn btn-success" mat-raised-button type="button" style="float:right;color:white;    margin: 18px;" (click)="sendLeed()">Invia</button>
  
    </div>
  
  </div>