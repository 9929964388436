import { IFoto } from "./perizia.interface";
import { IFile } from "./incarico.interface";

export interface IFir {
    id?: number;
    user?: string;
    dta: Date;
    type: string;
    productCategory: string;
    productState: string;
    powerType: string;
    application: string;
    refPerson: string;
    refPhone: string;
    attachments: IFile[];

    clientType: string;
    clientExt: string;

    lead1: number;
    lead2: number;
    lead3: number;
    status: string;
    note: string;
    response: string;
}

export interface IFirCert {
    id?: number
    user?: string
    nrCommessaInt?: string
    dataCommessa?: Date
    cliente: string
    telaioDiProvenienza: string
    respInt: string
    anomalieIN: string
    tipoMotore: string
    tipoRev: string
    smontaggio_eseguito: boolean
    lavaggio_eseguito: boolean
    controllo_eseguito: boolean
    controllo_difformita: string
    lm_incamiciatura_eseguito: boolean
    lm_incamiciatura_el_accessori: string
    lm_pianatura_eseguito: boolean
    lm_alloggiamento_albero_eseguito: boolean
    lm_alloggiamento_albero_el_accessori: string
    lm_sost_bronzine_eseguito: boolean
    lm_sost_bielle_eseguito: boolean
    lm_mont_cuscinetti_eseguito: boolean
    lm_mont_pistoni_eseguito: boolean
    lm_mont_bielle_eseguito: boolean
    lm_sost_albero_eseguito: string
    lm_sost_albero_rettifica_eseguito: boolean
    lm_sost_albero_rettifica: string
    lm_sost_albero_stappatura_eseguito: boolean
    lm_sost_albero_prescrizione: boolean
    lm_sost_testate_eseguito: string
    lm_sost_testate_spianatura_eseguito: boolean
    lm_sost_testate_spianatura_rettifica: string
    lm_sost_testate_misurazione_eseguito: boolean
    lm_sost_testate_misurazione_rettifica: string
    lm_sost_testate_controllo_supportini_eseguito: boolean
    lm_sost_testate_sost_valvole_eseguito: boolean
    lm_sost_testate_controllo_distrib_eseguito: boolean
    lm_sost_testate_controllo_camme_eseguito: boolean
    lm_sost_testate_pulitura_condotti_eseguito: boolean
    lm_sost_testate_sost_guarniz_eseguito: boolean


    mat_canne: boolean
    mat_pistoni: boolean
    mat_boccole: boolean
    mat_semicuscinetti: boolean
    mat_cuscinetti: boolean
    mat_semianelli: boolean
    mat_guarnizioni: boolean
    mat_albero: boolean
    mat_bilanceri: boolean
    mat_cinghie: boolean
    mat_valvole: boolean
    mat_guide_valvole: boolean
    mat_punterie: boolean
    mat_corona: boolean
    mat_valvole_lub: boolean
    mat_filtri_olio: boolean
    mat_tubi_iniet: boolean
    mat_tubi_rit: boolean
    mat_polv: boolean
    mat_pompa: boolean
    mat_manicotti: boolean
    mat_valvole_termo: boolean
    mat_candelette: boolean
    mat_turbo: boolean


    y?: any

}

export const getEmptyIFirCert = function (): IFirCert {

    let r: IFirCert = {

        cliente: undefined,
        telaioDiProvenienza: '',
        respInt: undefined,
        anomalieIN: undefined,
        tipoMotore: undefined,
        tipoRev: undefined,

        smontaggio_eseguito: false,
        lavaggio_eseguito: false,
        controllo_eseguito: false,
        controllo_difformita: undefined,
        lm_incamiciatura_eseguito: false,
        lm_incamiciatura_el_accessori: undefined,
        lm_pianatura_eseguito: false,
        lm_alloggiamento_albero_eseguito: false,
        lm_alloggiamento_albero_el_accessori: undefined,
        lm_sost_bronzine_eseguito: false,
        lm_sost_bielle_eseguito: false,
        lm_mont_cuscinetti_eseguito: false,
        lm_mont_pistoni_eseguito: false,
        lm_mont_bielle_eseguito: false,

        lm_sost_albero_eseguito: 'NO',
        lm_sost_albero_rettifica_eseguito: false,
        lm_sost_albero_rettifica: '',
        lm_sost_albero_stappatura_eseguito: false,
        lm_sost_albero_prescrizione: false,

        lm_sost_testate_eseguito: 'NO',
        lm_sost_testate_spianatura_eseguito: false,
        lm_sost_testate_spianatura_rettifica: '',
        lm_sost_testate_misurazione_eseguito: false,
        lm_sost_testate_misurazione_rettifica: '',
        lm_sost_testate_controllo_supportini_eseguito: false,
        lm_sost_testate_sost_valvole_eseguito: false,
        lm_sost_testate_controllo_distrib_eseguito: false,
        lm_sost_testate_controllo_camme_eseguito: false,
        lm_sost_testate_pulitura_condotti_eseguito: false,
        lm_sost_testate_sost_guarniz_eseguito: false,

        mat_canne: false,
        mat_pistoni: false,
        mat_boccole: false,
        mat_semicuscinetti: false,
        mat_cuscinetti: false,
        mat_semianelli: false,
        mat_guarnizioni: false,
        mat_albero: false,
        mat_bilanceri: false,
        mat_cinghie: false,
        mat_valvole: false,
        mat_guide_valvole: false,
        mat_punterie: false,
        mat_corona: false,
        mat_valvole_lub: false,
        mat_filtri_olio: false,
        mat_tubi_iniet: false,
        mat_tubi_rit: false,
        mat_polv: false,
        mat_pompa: false,
        mat_manicotti: false,
        mat_valvole_termo: false,
        mat_candelette: false,
        mat_turbo: false,
    }

    return r
}