import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

import * as moment from 'moment-timezone';

@Component({
  selector: 'app-grid-edit',
  templateUrl: './grid-edit.component.html',
  styleUrls: ['./grid-edit.component.scss']
})
export class GridEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params.data);
  }

}

@Component({
  selector: 'app-grid-duplicate',
  template: `<i class="material-icons" (click)="btnClickedHandler()" >file_copy</i>`

})
export class GridDuplicateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler() {
    this.params.clicked(this.params.value);
  }

}



@Component({
  selector: 'app-grid-updown',
  template: `
    <i class="material-icons" (click)="btnClickedHandler('up')" >arrow_upward</i>
    <i class="material-icons" (click)="btnClickedHandler('down')" >arrow_downward</i>
  `,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridUpDownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(d) {
    this.params.clicked({ direction: d, order: this.params.value });
  }

}
@Component({
  selector: 'app-grid-checkmark',
  template: `
   <div style="display:flex; align-items: center; height: 100%"> <i class="material-icons" (click)="btnClickedHandler('up')" >launch</i></div>

  `,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridCheckmarkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(d) {
    this.params.clicked({ direction: d, order: this.params.value });
  }

}

@Component({
  selector: 'app-grid-delete',
  template: '<div style="display:flex; align-items: center; height: 100%"><i class="material-icons" (click)="btnClickedHandler($event)" >delete</i></div>',
  styleUrls: ['./grid-edit.component.scss']
})
export class GridDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params.value);
  }

}



@Component({
  selector: 'app-grid-icon',
  template: `<div style="display:flex; align-items: center; height: 100%">
  <i class="material-icons" (click)="btnClickedHandler($event)" >{{icon}}</i>
  </div>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridIconComponent implements OnInit {
  icon = '';
  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
    if(this.params.data.completed > 0){
      this.icon = 'collections'
    }
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params);
  }

}

@Component({
  selector: 'app-grid-icon',
  template: `<i class="material-icons" (click)="btnClickedHandler($event)" >
    {{ this.params.data.adz_data ? 'cloud_download' : '' }}
  </i>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridDownloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  params: any;

  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params);
  }

}



@Component({
  selector: 'app-flotta-icon',
  template: `
  <i *ngIf="icon == 'check'" class="material-icons" style="margin-top:5px; color:green">checkbox-marked-circle</i>  
  <i *ngIf="icon == 'warn' " class="material-icons" style="margin-top:5px; color:orange">report-problem</i>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridFlottaComponent implements ICellRendererAngularComp {

  icon

  constructor() { }


  params: ICellRendererParams;
  agInit(params) {

    // console.log(params)

    if (+params.data.idPerizia > 0) return


    let dScadenza = moment(params.data.ScadenzaContrattoPrevista, 'DDMMYYYY').format('YYYYMMDD')
    let dNow = moment().format('YYYYMMDD')

    if (dScadenza > dNow) {
      this.icon = 'warn'
    } else {
      this.icon = 'check'
    }


  }

  refresh(params): boolean {
    return true;
  }
}




@Component({
  selector: 'app-grid-active',
  template: `
  
  <i class="material-icons" color="success" style="color:green" *ngIf="value">
    fiber_manual_record
  </i>

  <i class="material-icons" color="success" style="color:red" *ngIf="!value">
      fiber_manual_record
  </i>

  `,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridActiveComponent implements OnInit {

  value = false

  constructor() { }

  ngOnInit(): void {



  }
  params: any;
  agInit(params: any) {
    this.params = params;
    this.value = this.params.value && +this.params.value > 0
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params.value);
  }

}

@Component({
  selector: 'app-grid-active',
  template: `
  
  <i class="material-icons"  (click)="btnClickedHandler($event)" >
    analytics
  </i>



  `,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridStatusComponent implements OnInit {

  value = false

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params.value);
  }

}

@Component({
  selector: 'icon-renderer',
  template: `<div style="display:flex; align-items: center; heigth: 100%"><i class="material-icons" (click)="btnClickedHandler($event)">euro_symbol</i></div>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class IconRendererEuro implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params.data);
  }

}


@Component({
  selector: 'app-grid-okerr',
  template: `<i *ngIf="params.value" [ngStyle]="{'color': params.value ? 'green' : 'error'}" class="material-icons" (click)="btnClickedHandler($event)" >done-outline</i>`,
  styleUrls: ['./grid-edit.component.scss']
})
export class GridOKErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any) {
    this.params = params;
  }
  btnClickedHandler(e) {
    this.params.clicked(this.params);
  }

}
