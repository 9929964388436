import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, EventEmitter, Output, Input, OnDestroy } from '@angular/core';

import * as OT from '@opentok/client';
import { OpentokService } from 'app/services/opentok.service';

@Component({
  selector: 'app-videochat',
  templateUrl: './videochat.component.html',
  styleUrls: ['./videochat.component.scss']
})
export class VideochatComponent implements OnInit, OnDestroy {

  cmd = '';

  @Input() inverted: boolean = false;
  @Input() captureEnabled: boolean = true;

  @Input() sessionId: string;
  @Input() token: string;

  @Input() hideThumb: boolean = false;

  @Input() isRecording: boolean = false;



  @Input() //value: IResult
  set receiveCommand(cmd: string) {
    if (cmd == '') return;
    //this.getContext();
    console.log('cmd received - component: ', cmd);
    this.cmd = cmd;
    cmd = '';
    setTimeout(() => { this.cmd = ''; }, 100)
  }

  @Input() recordable = false;

  @Output() onCapture: EventEmitter<string> = new EventEmitter();
  @Output() onSendCommand: EventEmitter<string> = new EventEmitter();


  @Output() onToggleRecord: EventEmitter<string> = new EventEmitter();

  @Output() onOkPrivacy: EventEmitter<boolean> = new EventEmitter();
  @Output() onSetStream: EventEmitter<boolean> = new EventEmitter();


  title = 'Angular Basic Video Chat';
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;

  constructor(private ref: ChangeDetectorRef, private opentokService: OpentokService) {
    this.changeDetectorRef = ref;
  }

  ngOnInit() {
    setTimeout(() => {

      this.init()

    }, 500);
  }

  init() {

    console.log('*** INIT')

    this.opentokService.initSession(this.sessionId, this.token).then((session: OT.Session) => {
      this.session = session;
      this.session.on('streamCreated', (event) => {
        console.log('streamCreated')
        this.streams.push(event.stream);
        this.changeDetectorRef.detectChanges();
      });
      this.session.on('streamDestroyed', (event) => {
        console.log('streamDestroyed')
        const idx = this.streams.indexOf(event.stream);
        if (idx > -1) {
          this.streams.splice(idx, 1);
          this.changeDetectorRef.detectChanges();
        }
      });
    })
      .then(() => this.opentokService.connect())
      .catch((err) => {
        console.error(err);
        alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
      });

  }

  ngOnDestroy() {

    console.log('videochat destroy')

    this.opentokService.close()
  }

  capture(e) {
    console.log('capture - app-videochat')
    this.onCapture.emit(e)
  }

  sendCommand(cmd: string) {
    console.log('sendCommand - videochat component')
    this.onSendCommand.emit(cmd)
  }

  toggleRecord(e) {
    this.onToggleRecord.emit(e)
  }

  okPrivacy(e) {
    this.onOkPrivacy.emit(e)

  }

  setStream(e) {

    console.log('videochat setstream', e)

    this.onSetStream.emit(e)

  }

}
