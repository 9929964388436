<div class="card" style="width:50%; margin:auto;">
  <div class="card-header card-header-info">
    <h4 class="card-title">Domanda</h4>
  </div>
  <div  *ngIf="data.type == 'question'" class="card-body">

    <mat-form-field class="full">
      <input type="text" matInput [(ngModel)]="question" >
    </mat-form-field>


    <input type="button" value="Ok" (click)="ok()" class="btn btn-success" />
    <input type="button" value="Annulla" (click)="close()" class="btn btn-danger" />
  </div>


<div  *ngIf="data.type == 'answer'" class="card-body">

 
  <p>{{data.question}}</p>


  <input type="button" value="Approva" (click)="approva()" class="btn btn-success" />
  <input type="button" value="Rifiuta" (click)="rifiuta()" class="btn btn-danger" />
  <input type="button" value="Astieniti" (click)="astenuto()" class="btn btn-default" />
</div>
</div>