import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'myduration',
})
export class DurationPipe implements PipeTransform {


  transform(n): any {

    if(!n) return '';

    let r = '';

    if (n > 0) {
      r = 'Scaduta';
    } else {

      n = Math.abs(Math.floor(n / 1000));

      let ggFact = 24 * 60 * 60;
      let gg = Math.floor(n / ggFact);
      n = n % ggFact;

      let hhFact = 60 * 60;
      let hh = Math.floor(n / hhFact);
      n = n % hhFact;

      let mmFact = 60;
      let mm = Math.floor(n / mmFact);
      n = n % mmFact;

      r = `${gg}gg ${hh}h ${mm}m ${n}s`

    }



    return r;

  }

}
