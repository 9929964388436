<div *ngIf="!form">
  Loading...
</div>

<div *ngIf="form && result">

  <form [formGroup]="xform" novalidate>

    <div class="row" *ngFor="let row of form.rows; index as r">
      <div class="col" *ngFor="let col of row.cols; index as c">
        <div *ngIf="col" [ngSwitch]="col.type">

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'h1'">
            <div class="row">
              <div class="col">
                <h3>{{ col.descr }}</h3>
              </div>
              <div class="col-2">
                <button mat-mini-fab *ngIf="col.tooltip" color="primary" (click)="showTooltip(col.tooltip)"><span
                    class="material-icons">
                    help
                  </span></button>
              </div>
            </div>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'h2'">
            <div class="row">
              <div class="col">
                <h4>{{ col.descr }}</h4>
              </div>
              <div class="col-2">
                <button mat-mini-fab *ngIf="col.tooltip" color="primary" (click)="showTooltip(col.tooltip)"><span
                    class="material-icons">
                    help
                  </span></button>
              </div>
            </div>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'p'">
            <div class="row">
              <div class="col">
                <p>
                  <span *ngFor="let s of (col.descr |multiline)">{{s}} <br> </span>

                </p>
              </div>
              <div class="col-2">
                <button mat-mini-fab *ngIf="col.tooltip" color="primary" (click)="showTooltip(col.tooltip)"><span
                    class="material-icons">
                    help
                  </span></button>
              </div>
            </div>

          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'hr'">
            <hr>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-select'">
            <mat-form-field>
              <mat-label>{{col.descr}}<span *ngIf="col.mandatory"> *</span></mat-label>
              <mat-select [formControlName]="col.id" [(ngModel)]="result[col.id]"
                (ngModelChange)="result[col.id] = $event" [disabled]="disabled">
                <mat-option *ngFor="let i of col.values.split('|')" [value]="i">
                  {{i}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-text'">
            <mat-form-field>
              <mat-label>{{col.descr}}<span *ngIf="col.mandatory"> *</span></mat-label>
              <input [formControlName]="col.id" matInput type="text" [placeholder]="col.placeholder"
                [readonly]="disabled" [(ngModel)]="result[col.id]">
            </mat-form-field>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-email'">
            <mat-form-field>
              <mat-label>{{col.descr}}<span *ngIf="col.mandatory"> *</span></mat-label>
              <input [formControlName]="col.id" matInput type="email" [placeholder]="col.placeholder"
                [readonly]="disabled" [(ngModel)]="result[col.id]">
            </mat-form-field>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-number'">
            <mat-form-field>
              <mat-label>{{col.descr}}<span *ngIf="col.mandatory"> *</span></mat-label>
              <input [formControlName]="col.id" matInput type="number" min="0" max="100" step="1"
                [(ngModel)]="result[col.id]" [readonly]="disabled">
            </mat-form-field>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-date'">
            <mat-form-field>
              <mat-label>{{col.descr}}<span *ngIf="col.mandatory"> *</span></mat-label>
              <input [formControlName]="col.id" matInput type="date" [(ngModel)]="result[col.id]" [readonly]="disabled">
            </mat-form-field>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-time'">
            <mat-form-field>
              <mat-label>{{col.descr}}<span *ngIf="col.mandatory"> *</span></mat-label>
              <input [formControlName]="col.id" matInput type="time" [(ngModel)]="result[col.id]" [readonly]="disabled">
            </mat-form-field>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-checkbox'">
            <mat-checkbox [formControlName]="col.id" [(ngModel)]="result[col.id]"
              [disabled]="disabled">{{col.descr}}</mat-checkbox>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-textarea'">
            <mat-label>{{col.descr}}<span *ngIf="col.mandatory"> *</span></mat-label>
            <textarea [formControlName]="col.id" matInput matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=25
              [(ngModel)]="result[col.id]" style="border:1px solid #ccc!important"></textarea>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-toggle'">
            <mat-slide-toggle [formControlName]="col.id" [disabled]="disabled"
              [(ngModel)]="result[col.id]">{{col.descr}}</mat-slide-toggle>
          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-slider'">
            <div style="float:left">{{col.descr}}</div>
            <mat-slider style="float:right;width:80%" [formControlName]="col.id" [(ngModel)]="result[col.id]"
              [min]="(col.min ? col.min : '0')" [disabled]="disabled" max="100"
              [step]="100/ ( col.nstep && col.nstep>0 ? col.nstep: 1  ) ">
            </mat-slider>
          </div>



          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-signature'">
            <div style="float:left">{{col.descr}}</div>

            <img *ngIf="result[col.id]?.signature" [src]="result[col.id].signature">

          </div>

          <div [ngStyle]="{'background-color': col.color, color: getMediumColor(col.color) > 128 ? 'black' : 'white' }"
            *ngSwitchCase="'input-address'">

            <div class="row">
              <div class="col">
                <app-location-search [location_lat]="result[col.id].location_lat" [showMap]="false"
                  [disabled]="disabled" [location_lon]="result[col.id].location_lon"
                  (onSelected)="onLocationSelected(col.id,$event)">
                  <!--*ngIf="result[col.id].location_lat && result[col.id].location_lon && result[col.id].location_lat!=0 && result[col.id].location_lat!=0 || id == 0 "-->
                </app-location-search>
              </div>
              <div class="col"
                *ngIf="this.result[col.id].indirizzo || this.result[col.id].provincia || this.result[col.id].cap || this.result[col.id].citta">
                <span *ngIf="this.result[col.id].indirizzo">{{ this.result[col.id].indirizzo}}, </span>
                <span *ngIf="this.result[col.id].provincia">{{ this.result[col.id].provincia}}, </span>
                <span *ngIf="this.result[col.id].cap">{{ this.result[col.id].cap}}, </span>
                <span *ngIf="this.result[col.id].citta">{{ this.result[col.id].citta}}</span>
              </div>
            </div>

          </div>

          <div *ngSwitchCase="'input-img'">


            <div class="col-2 btnzone"><button type="button" id="btn_upload"
                (click)="loadImageClick(col.id)" class="btn btn-info">CARICA IMMAGINE...</button>
            </div>

            <input type="file" [id]="'file_' + col.id" #file (change)="uploadFile($event)" style="display: none;" accept="image/jpeg">

            <div class="dropzone" [ngxFileDropzone]="readMode" (fileDrop)="dropFile($event,col.id)" *ngIf="!result[col.id]?.img">

              <div style="text-align: center; margin:auto;" *ngIf="!result[col.id] && !disabled">Inserisci {{col.descr}}
              </div>

          
            </div>

            <div >
              <img [src]="result[col.id]?.img?.fileContent ? result[col.id]?.img?.fileContent : result[col.id]?.img?.url" style="max-height:25vh">
            </div>






          </div>

        </div>
      </div>
    </div>
  </form>
</div>