<div class="card" style="width:50%; margin:auto;">
  <div class="card-header card-header-info">
    <h4 class="card-title">Modifica domanda</h4>
  </div>
  <div class="card-body">



    <div class="row">
      <div class="col-2">
        <mat-form-field class="full">
          <mat-label>SectionCode</mat-label>
          <!--<input matInput [(ngModel)]="q.sectionCode">-->
          <mat-select [(ngModel)]="q.sectionCode" (selectionChange)="onSelectChange($event)">

            
            <mat-option  value="(Nuovo)"  >(Nuovo)</mat-option>


            <mat-option *ngFor="let e of sections" [value]="e.sectionCode"  >{{e.sectionCode}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full">
          <mat-label>SectionDescription</mat-label>
          <input matInput [(ngModel)]="q.sectionDescription">
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field class="full">
          <mat-label>Order</mat-label>
          <input type="number" matInput [(ngModel)]="q.Order">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <mat-form-field class="full">
          <mat-label>QuestionId</mat-label>
          <input matInput [(ngModel)]="q.questionId">
        </mat-form-field>
      </div>
      <div class="col-2">
        <mat-form-field class="full">
          <mat-label>QuestionName</mat-label>
          <input matInput [(ngModel)]="q.questionName">
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full">
          <mat-label>QuestionDescr</mat-label>
          <input matInput [(ngModel)]="q.questionDescr">
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field class="full">
          <mat-label>Text1</mat-label>
          <input type="text" matInput [(ngModel)]="q.text1">
        </mat-form-field>
      </div>
      <div class="col">
        <mat-label>Val1: {{q.val1}}</mat-label>
        <mat-slider max="100" min="0" step="25" thumbLabel="true" tickInterval="5" [(ngModel)]="q.val1" style="width: 100%;">
        </mat-slider>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <mat-form-field class="full">
          <mat-label>Text2</mat-label>
          <input type="text" matInput [(ngModel)]="q.text2">
        </mat-form-field>
      </div>
      <div class="col">
        <mat-label>Val2: {{q.val2}}</mat-label>
        <mat-slider max="100" min="0" step="25" thumbLabel="true" tickInterval="5" [(ngModel)]="q.val2" style="width: 100%;">
        </mat-slider>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <mat-form-field class="full">
          <mat-label>Text3</mat-label>
          <input type="text" matInput [(ngModel)]="q.text3">
        </mat-form-field>
      </div>
      <div class="col">
        <mat-label>Val3: {{q.val3}}</mat-label>
        <mat-slider max="100" min="0" step="25" thumbLabel="true" tickInterval="5" [(ngModel)]="q.val3" style="width: 100%;">
        </mat-slider>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <mat-form-field class="full">
          <mat-label>Text4</mat-label>
          <input type="text" matInput [(ngModel)]="q.text4">
        </mat-form-field>
      </div>
      <div class="col">
        <mat-label>Val4: {{q.val4}}</mat-label>
        <mat-slider max="100" min="0" step="25" thumbLabel="true" tickInterval="5" [(ngModel)]="q.val4" style="width: 100%;">
        </mat-slider>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <mat-form-field class="full">
          <mat-label>Text5</mat-label>
          <input type="text" matInput [(ngModel)]="q.text5">
        </mat-form-field>
      </div>
      <div class="col">
        <mat-label>Val5: {{q.val5}}</mat-label>
        <mat-slider max="100" min="0" step="25" thumbLabel="true" tickInterval="5" [(ngModel)]="q.val5" style="width: 100%;">
        </mat-slider>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <mat-label>NonApplicabile: </mat-label>
        <mat-checkbox [(ngModel)]="q.nonApplicabile"></mat-checkbox>
      </div>
      <div class="col">
        <mat-label>PhotoRequired: </mat-label>
        <mat-checkbox [(ngModel)]="q.photoRequired"></mat-checkbox>
      </div>
      <div class="col">
        <mat-label>Foto nascosta nel report: </mat-label>
        <mat-checkbox [(ngModel)]="q.photoNotReport"></mat-checkbox>
      </div>
    </div>
    <!--

 <div class="row">
      <div class="col">
        <mat-label>Blueprint: </mat-label>
        <mat-checkbox [(ngModel)]="q.blueprint"></mat-checkbox>
      </div>
      <div class="col">
        <mat-label>Tires: </mat-label>
        <mat-checkbox [(ngModel)]="q.tires"></mat-checkbox>
      </div>
    </div>

    -->
   

    <hr>

    <div class="row">
      <div class="col">
        <mat-form-field class="full">
          <mat-label>Hint</mat-label>
          <textarea matInput [(ngModel)]="q.hint"></textarea>
        </mat-form-field>
      </div>
    </div>

    <input type="button" value="Salva" (click)="save()" class="btn btn-success" />
    <input type="button" value="Annulla" (click)="close()" class="btn btn-danger" />

  </div>
</div>