<div class="row" *ngIf="!disabled" style="margin-left: 0!important">

  <div class="col dropzone" [ngxFileDropzone]="readMode" (fileDrop)="dropFile($event)" *ngIf="dropzone">Trascina un file qua.</div>
  <div class="col btnzone">
    <button type="button" id="btn_upload" (click)="startUpload(''); file.click()" class="btn btn-info">CARICA ALLEGATO...</button>


    <button *ngFor="let t of customDocTypes" type="button" [id]="'btn_upload'" (click)="startUpload(t); file.click()"
      class="btn btn-info">CARICA {{t}}</button>




  </div>




</div>

<div class="row" *ngIf="!uploader && categorized" style="margin-left: 0!important">
  <div class="col-12">
    <input type="button" value="Immagini" (click)="setFilter(['img'])"
      [ngClass]="{'btn': true, 'btn-success': filter.length==1, 'btn-default': filter.length>1 }" />
    <input type="button" value="Documenti" (click)="setFilter(['oth','pdf'])"
      [ngClass]="{'btn': true, 'btn-default': filter.length==1, 'btn-success': filter.length>1 }" />

    <input [disabled]="downloading" type="button" value="Scarica tutto" (click)="downloadAll()" class="btn btn-success"
      style="margin-left:20px" />
  </div>
</div>

<div class="card"  >

  <div *ngIf="viewGallery">

    <div class="row" *ngIf="!uploader" style="margin-left: 0!important">

      <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2 bshadow growx" 
        *ngFor="let a of filtered_source; index as i " [ngClass]="{ 'concordato' : isConcordato(a) }">

        

        <button *ngIf="!disabled" type="button" color="warn" mat-icon-button (click)="delete(a)"
          style="position:absolute; top:0; right:0;"><i class="material-icons">delete_forever</i></button>

        <a class="halfwidth" (click)="openFile(i)" >
          <img [src]="isImage(a)" class="halfwidth" [ngClass]="{ 'concordato' : isConcordato(a) }">
        </a>

        <div class="img-footer" (click)="openFile(i)" *ngIf="showLabel">
          {{ a.fileName }} - {{humanize(a.size)}}
        </div>

        <div class="img-footer" (click)="openFile(i)" *ngIf="showTag">
          {{ a.tag }} 
        </div>

      </div>

    </div>

  </div>

  <div *ngIf="!viewGallery">

    <div class="row" *ngIf="!uploader" style="margin-left: 0!important">
      <div class="col-12" >

        <table style="width:100%">

          <tr>
            <th>Anteprima</th>
            <th>Nome file</th>
            <th>Data</th>
            <th>Dimensione</th>
            <th></th>
          </tr>

          <tr *ngFor="let a of filtered_source; index as i " (click)="openFile(i)">
            <td style="width:32px"> <img [src]="isImage(a)" width=32> </td>
            <td>{{a.fileName}}</td>
            <td>{{a.creationDate|date}}</td>
            <td>{{a.size/1024/1024|number:'0.2-2'}} MB</td>
            <td style="width:32px"><button *ngIf="!disabled" type="button" color="warn" mat-icon-button
                (click)="delete(a)"><i class="material-icons">delete_forever</i></button></td>
          </tr>

        </table>
      </div>
    </div>
  </div>

</div>

<input type="file" #file (change)="uploadFile($event);" style="display: none;" [accept]="accepted" [multiple]="isMultiple" [(ngModel)]="ffile">
{{ffile}}