<div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
  
          <div class="card">
            <div class="card-header card-header-info">
              <h4 class="card-title">UXpert Utenti</h4>
  
  
            </div>
            <div class="card-body">
  
  
              <mat-tab-group (selectedTabChange)="onTabChanged($event);">
  
  
  
                <mat-tab label='Elenco utenti' style="height: 100%;">
  
  
                    <ag-grid-angular style="width: 100%; height: calc(100vh - 300px);" class="ag-theme-alpine"
                    [rowData]="rowData" *ngIf="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef_def" 
                    (gridReady)="onGridReady($event)">
                  </ag-grid-angular>
  
                </mat-tab>
  
              </mat-tab-group>
  
              <!--
              <input type="file" #file_visura id="file_visura" style="display: none;" (change)="addAttachment($event, 'visura')"
              accept="application/pdf">
          
            <input type="file" #file_etax id="file_etax" style="display: none;" (change)="addAttachment($event, 'etax')"
              accept="application/pdf">
  -->
            </div>
          </div>
  
  
        </div>
      </div>
    </div>
  </div>