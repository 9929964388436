import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as THREE from "three"
import * as _ from 'lodash'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import {
  CSS2DRenderer,
  CSS2DObject
} from 'three/examples/jsm/renderers/CSS2DRenderer'
import { IFoto } from 'app/models/perizia.interface';


interface Annotation {
  title: string
  description: string
  descriptionDomElement?: HTMLElement
}



@Component({
  selector: 'app-blueprint-3d',
  templateUrl: './blueprint-3d.component.html',
  styleUrls: ['./blueprint-3d.component.scss']
})
export class Blueprint3dComponent implements OnInit, AfterViewInit {

  pointerDown = false

  modalOpen = false
  modalData

  isAnnotationHidden = true


  @ViewChild('coldanni', { static: false }) private colDanni!: ElementRef<HTMLDivElement>;
  @ViewChild('canvasCnt', { static: false }) private canvasCnt!: ElementRef<HTMLDivElement>;



  @Input() public objUrl: string = ''
  @Input() public showCustomGrid = false
  @Input() public showGrid = false
  @Input() public showAxes = false
  @Input() public controlsEnabled = true
  @Input() public showLoading = false

  @Input() public hideList = true
  @Input() public readonly = false


  @Input() public damages: IDamage3d[]

  currentObjRotation = { x: -100, y: 0, z: 0 }
  groupName: any;
  annotation1: any;
  tempObject: any = {
    obj: null,
    color: null
  };
  exColor: any;
  infoModal: { intersections: any; param: any; name: any; };

  @Input('rotateObj') set rotateObj(rotation: { x: number; y: number; z: number, positionZ: number }) {

    this.resetObjRotation()

    console.log('rotation', rotation)
    if (rotation?.x) {
      this.currentObjRotation.x += rotation.x * _1DEG
      this.object.rotateX(rotation.x * _1DEG)
    }
    if (rotation?.y) {
      this.currentObjRotation.y += rotation.y * _1DEG
      this.object.rotateY(rotation.y * _1DEG)
    }
    if (rotation?.z) {
      this.currentObjRotation.z += rotation.z * _1DEG
      this.object.rotateZ(rotation.z * _1DEG)
    }

    if (rotation.positionZ) {
      this.camera.position.z = rotation.positionZ
    } else {
      let def = getDefault(this.objUrl).camera
      this.camera.position.z = def.positionZ

    }


  }

  //@Output() damagesChange: EventEmitter<any> = new EventEmitter();

  loading = 0
  rendered = false

  annotationCounter = 0
  annotations: { [key: string]: Annotation } = {}
  annotationMarkers: THREE.Sprite[] = []

  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();

  item: IDamage3d = {
    severity: 'G',
    type: '',
    x: 0, y: 0,
    intersect: { face: '', point: '' },
    objectName: ''
  }


  @Input() public cameraZ: number = 0
  @Input() public fieldOfView: number = 10

  @Input('nearClipping') public nearClippingPlane: number = 1
  @Input('farClipping') public farClippingPlane: number = 65000



  @Output() onNew: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<void> = new EventEmitter();

  labelRenderer = new CSS2DRenderer()


  circleTexture_g = new THREE.TextureLoader().load('./assets/img/circle_g.png')
  circleTexture_y = new THREE.TextureLoader().load('./assets/img/circle_y.png')
  circleTexture_r = new THREE.TextureLoader().load('./assets/img/circle_r.png')
  circleTexture_s = new THREE.TextureLoader().load('./assets/img/circle_s.png')

  camera!: THREE.PerspectiveCamera

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement
  }



  public object!: THREE.Group | THREE.Object3D
  private renderer!: THREE.WebGLRenderer

  public scene!: THREE.Scene

  rootObjectNames = [

    'FANALI_ANT',
    'FANALI_POST',
    'CERCHI',
    'CARROZZERIA',
    'CRISTALLI',
    'SPECCHI_ESTERNI',
    'ELEMENTI_PLASTICI',
    'COVER_MONTANTI',
    'ALTRO',
    'SOTTOSCOCCA',
    'ELEMENTI_CROMATI',
    'CORNICI_CRISTALLI',
    //'_5_shield_rearbump',
    'GRIGLIA_ANTERIORE',
    'CARENE',
    'TARGA',
    'DISCHI_FRENO',
    'PNEUMATICI',

  ]

  @ViewChild('canvas', { static: false }) private canvasRef!: ElementRef;

  constructor() {



  }

  ngOnInit(): void {
  }

  addGrid() {
    if (!this.showGrid) return
    //return
    const gridHelper = new THREE.GridHelper(1000, 100, 0xff0000, 0x0000ff);
    this.scene.add(gridHelper);
  }

  addLights() {

    let ambient = new THREE.AmbientLight(0x999999, 1);
    this.scene.add(ambient);

    var directionalLight = new THREE.DirectionalLight(0xffeedd, 1);
    directionalLight.position.set(1, 1, 1).normalize();
    this.scene.add(directionalLight);

  }

  private createScene() {
    this.scene = new THREE.Scene()
    this.scene.background = null

    this.addLights()
    this.addGrid()

    this.load()

    //this.loadMTL()

    let aspectRatio = this.getAspectRatio()

    this.camera = new THREE.PerspectiveCamera(
      this.fieldOfView,
      aspectRatio,
      this.nearClippingPlane,
      this.farClippingPlane
    )

  }

  load() {
    this.loadGLTF()
  }


  getObj(obj) {
    //console.clear()

    obj.children = obj.children.filter(e => e.type == 'Object3D' || e.type == 'Mesh')

    console.log('getObj', obj.name)

    let b: boolean = obj.children.length > 1 || obj.children.length == 0

    if (b) {
      return obj
    }
    else
      return this.getObj(obj.children[0])

  }

  loadGLTF() {

    const loader = new GLTFLoader()

    loader.load(this.objUrl, (gltf) => {

      //console.clear()
      let tmp = gltf.scene.children[0]
      this.object = this.getObj(tmp)


      //this.object.scale.set(300, 300, 300)

      //this.object.rotateX(180)
      this.scene.add(this.object)

      this.setObjectDefaults()
      this.setCameraDefaults()

      this.setCustomGrid()
      this.setAxis()

      this.loadData()

      console.log('added')
      this.rendered = true

    },
      (xhr) => {
        this.loading = (xhr.loaded / xhr.total) * 100
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
      },
      (error) => {
        console.log(error)
      });
  }

  setOrbitalControls() {

    if (!this.object) {
      setTimeout(() => {
        this.setOrbitalControls()
      }, 300);
      return
    }


    const controls = new OrbitControls(this.camera, this.renderer.domElement)
    controls.addEventListener('change', (res) => {
      // console.log('object', this.object)
      // console.log('camera', this.camera)
    })

    controls.enabled = this.controlsEnabled

  }

  setLabelRenderer() {
    //this.labelRenderer.setSize(+this.canvas.width,+this.canvas.height)
    this.labelRenderer.domElement.style.position = 'absolute'
    this.labelRenderer.domElement.style.top = this.canvas.offsetTop + 'px'
    this.labelRenderer.domElement.style.left = this.canvas.offsetLeft + 'px'
    this.labelRenderer.domElement.style.width = '100%'
    this.labelRenderer.domElement.style.height = '100%'
    this.labelRenderer.domElement.style.pointerEvents = 'none'
    //this.labelRenderer.domElement.style.backgroundColor = '#ff000077'
    //this.labelRenderer.domElement.style.border = "3px solid red"
    this.canvasCnt.nativeElement.appendChild(this.labelRenderer.domElement)


    //this.labelRenderer.setPixelRatio(devicePixelRatio)
    this.labelRenderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight)

  }

  setObjectDefaults(rotateX = 0, rotateY = 0, rotateZ = 0) {

    let def = getDefault(this.objUrl).object
    console.log(def)

    this.object.rotateX(def.rotateX + rotateX)
    this.object.rotateY(def.rotateY + rotateY)
    this.object.rotateZ(def.rotateZ + rotateZ)

    this.object.position.x -= def.positionX
    this.object.position.y -= def.positionY
    this.object.position.y -= def.positionZ


    this.setReflection()

  }


  setCameraDefaults(x = 0, y = 0, z = 0) {

    let def = getDefault(this.objUrl).camera
    console.log(def)

    this.camera.position.x = def.positionX + x
    this.camera.position.y = def.positionY + y
    this.camera.position.z = def.positionZ + z

    let pos = this.object.position.clone()


    this.camera.lookAt(pos)

  }


  setCustomGrid() {

    if (!this.showCustomGrid) return

    //console.clear()
    let bbox = new THREE.Box3().setFromObject(this.object);


    console.log(bbox)

    let stepx = (bbox.max.x - bbox.min.x) / 3
    let stepy = (bbox.max.y - bbox.min.y) / 2
    let stepz = (bbox.max.z - bbox.min.z) / 4

    for (let x = bbox.min.x; x <= bbox.max.x; x += stepx) {

      for (let y = bbox.min.y; y <= bbox.max.y; y += stepy) {

        for (let z = bbox.min.z; z <= bbox.max.z; z += stepz) {

          const geometry = new THREE.BoxGeometry(stepx, stepy, stepz,);
          const edges = new THREE.EdgesGeometry(geometry)
          const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0xffffff }));


          line.position.x = x
          line.position.y = y
          line.position.z = z

          this.scene.add(line);



        }
      }
    }



  }



  setAxis() {

    if (!this.showAxes) return

    const axesHelper = new THREE.AxesHelper(150);
    this.scene.add(axesHelper);

  }

  getAspectRatio() {
    return this.canvas.clientWidth / this.canvas.clientHeight
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    //console.clear()
    //console.log('resize')
    this.camera.aspect = this.getAspectRatio()
    //this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight)
    this.renderer.render(this.scene, this.camera)
    this.setLabelRenderer()
  }


  private startRenderingLoop() {
    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true, alpha: true })
    this.renderer.setPixelRatio(devicePixelRatio)
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight)
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.setLabelRenderer()
    this.setOrbitalControls()

    const render = () => {
      requestAnimationFrame(render);
      //component.animateCube()
      this.renderer.render(this.scene, this.camera)
      this.labelRenderer.render(this.scene, this.camera)

    }

    console.log(this.scene)


    render()


  }

  ngAfterViewInit(): void {


    //console.clear()
    console.log('ngAfterViewInit')

    setTimeout(() => {

      this.createScene()
      this.startRenderingLoop()

    }, 1000);

  }

  loadData() {

    this.loadMarkers()
  }


  loadMarkers() {
    console.log('N Damages: ' + this.damages.length)
    this.damages.forEach(d => {
      this.addMarker(d)
    });
  }

  damageID
  async openDialog(intersect, damageID?: number, name?: string) {

    let d

    if (damageID != undefined)
      d = this.damages[damageID]

    console.log(damageID, d)
    this.modalData = {
      //object: damageID != undefined ? d.object : object,
      intersect: damageID != undefined ? d.intersect : intersect,
      damage: damageID != undefined ? d : undefined
    }
    this.damageID = damageID
    this.modalOpen = true
    if (this.modalData.damage)
      this.item = this.modalData.damage
    else {
      this.item = {
        severity: 'G',
        type: '',
        x: 0, y: 0,
        intersect: { face: this.modalData.intersect.face, point: this.modalData.intersect.point },
        //object: this.modalData.object,
        objectName: name ? name : ''
      }
    }


    console.log(this.item)
    this.ok(this.item)

    //this.presentModal()

  }

  async presentModal() {


    //console.clear()

    /*
      const modal = await this.modalController.create({
        component: Blueprint3dModalPage,
        cssClass: 'my-custom-class',
        componentProps: { item: this.item }
  
      });
  
      modal.onDidDismiss().then(d => { if (d) this.ok(d) })
  
      return await modal.present();
    */
  }

  ok(d) {
    if (!this.item) return

    console.log(`Dialog result`, d);

    this.lastObjHoverId = -1
    this.lastObjName = ''
    if (this.damageID) {
      //this.damages[this.damageID] = d
    } else {
      this.addMarker(this.item)

      setTimeout(() => {
        this.colDanni.nativeElement.scrollTop = this.colDanni.nativeElement.scrollHeight
      }, 150);
      //this.damages.push(this.item)
    }


    this.onNew.emit(this.item)
    this.modalOpen = false



  }

  close() {
    this.modalOpen = false
  }

  onAnnotationClick() {
    console.log("annotationClicked")


    this.isAnnotationHidden = true

    const div = document.getElementById('annotation-1')
    div.style.display = 'none';

    this.restoreMaterial()

    this.openDialog(this.infoModal.intersections, this.infoModal.param, this.infoModal.name)
  }

  restoreMaterial() {
    if (this.tempObject.obj != null) {
      let objectPrev = this.scene.getObjectById(this.tempObject.obj);
      objectPrev.traverse((e) => {
        e.material = this.tempObject.color;
      })
    }
  }

  onDoubleClick(evt: MouseEvent) {
    console.log('onDoubleClick')

    const rect = this.renderer.domElement.getBoundingClientRect();
    this.mouse.x = ((evt.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
    this.mouse.y = - ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObject(this.object)


    if (intersects.length > 0) {

      //intersects[0].object.materials[0].color.setHex(Math.random() * 0xffffff);

      //let uuid = intersects[0].object.uuid
      //console.log('uuid', uuid)

      let id = this.getGroup(intersects[0].object.id)


      //let name = this.scene.getObjectById(id)!.name;
      //let name = intersects[0].object.name

      let object = this.scene.getObjectById(id);

      // console.clear()
      console.log(object)

      object = this.scene.getObjectByName(object!.name);

      let name = object!.name
      console.log('name', name)
      console.log('uuid', object)


      this.openDialog(intersects[0], undefined, name)


    } else {
      console.log('no intersects')
    }


  }

  onTest(evt: MouseEvent) {
    console.log('testMIO')

    this.isAnnotationHidden = true
    this.restoreMaterial()

    const rect = this.renderer.domElement.getBoundingClientRect();
    this.mouse.x = ((evt.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
    this.mouse.y = - ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObject(this.object)


    if (intersects.length > 0) {

      //intersects[0].object.materials[0].color.setHex(Math.random() * 0xffffff);

      //let uuid = intersects[0].object.uuid
      //console.log('uuid', uuid)

      let id = this.getGroup(intersects[0].object.id)


      //let name = this.scene.getObjectById(id)!.name;
      //let name = intersects[0].object.name

      let object = this.scene.getObjectById(id);

      //INIZIO PRENDO COLORE E LO RISETTO 
      if (this.tempObject.obj != null) {
        let objectPrev = this.scene.getObjectById(this.tempObject.obj);

        objectPrev.traverse((e) => {
          e.material = this.tempObject.color;
        })
      }
      let exColor
      object.traverse((e) => {
        exColor = e.material
      })
      this.tempObject['obj'] = _.cloneDeep(id)
      this.tempObject['color'] = exColor
      //FINE DEL PRENDO COLORE E LO RISETTO 



      const material = new THREE.MeshPhongMaterial({
        color: 0xcc0000,
        roughness: 0.1,
        metalness: 0.9,

        emissive: 0x000000,
        specular: 0x111111,
        shininess: 100,

        reflectivity: 1,
        refractionRatio: 0.98,

        side: THREE.FrontSide,
      })
      object.traverse((e) => {
        e.material = material;
      })
      console.log("tempObject")
      console.log(this.tempObject)
      console.log("tempObject")
      // object.children = this.tempObject;
      // console.clear()
      console.log(object)

      object = this.scene.getObjectByName(object!.name);

      let name = object!.name
      console.log('name', name)
      console.log('uuid', object)
      this.groupName = name;
      this.addTooltip(intersects[0])

      this.infoModal = {
        intersections: intersects[0],
        param: undefined,
        name: name
      }



    } else {
      console.log('no intersects')
    }


  }

  getRandomHexColor(): string {
    // Generate a random number between 0 and 16777215 (0xFFFFFF in hexadecimal)
    const randomColor = Math.floor(Math.random() * 0xFFFFFF);

    // Convert the number to a hexadecimal string and pad with zeros if needed
    const hexColor = randomColor.toString(16).padStart(6, '0');

    // Prepend a "#" to the hex color to make it a valid CSS color
    return '0x' + hexColor;
  }

  clearMarkers() {

    this.scene.traverse((object) => {

      if (object instanceof THREE.Sprite) {
        console.log(object)
        setTimeout(() => {
          object.removeFromParent()
        }, 1);
      }

    });

    setTimeout(() => {
      this.loadMarkers()
    }, 100);



  }

  addMarker(d: IDamage3d) {

    if (!d?.intersect?.face) return;

    const n = new THREE.Vector3()
    n.copy((d.intersect.face as THREE.Face).normal)
    n.transformDirection(this.object.matrixWorld)

    const aId = this.annotationMarkers.length + 1

    let circleTexture
    switch (d.severity) {
      case 'L': circleTexture = this.circleTexture_g; break
      case 'M': circleTexture = this.circleTexture_y; break
      case 'G': circleTexture = this.circleTexture_r; break
      case 'S': circleTexture = this.circleTexture_s; break
    }

    const annotationSpriteMaterial = new THREE.SpriteMaterial({
      map: circleTexture,
      depthTest: false,
      depthWrite: false,
      sizeAttenuation: false
    })
    const annotationSprite = new THREE.Sprite(
      annotationSpriteMaterial
    )

    annotationSprite.userData.id = aId
    this.annotationMarkers.push(annotationSprite)

    annotationSprite.scale.set(0.01, 0.01, 0.01)
    annotationSprite.lookAt(n)
    annotationSprite.position.copy(d.intersect.point)

    this.scene.add(annotationSprite)


    const annotationDiv = document.createElement('div')
    annotationDiv.className = 'annotationLabel'
    annotationDiv.innerHTML = '' + aId

    const annotationLabel = new CSS2DObject(annotationDiv)
    annotationLabel.scale.set(0.03, 0.03, 0.03)
    annotationLabel.lookAt(n)
    annotationLabel.position.copy(d.intersect.point)
    // this.scene.add(annotationLabel)

    let annotation: Annotation = { title: 'Danno ' + aId, description: 'Descrizione' }
    const annotationTextDiv = document.createElement('div')
    annotationTextDiv.className = 'annotationDescription'
    annotationTextDiv.innerHTML = annotation.title
    annotationTextDiv.innerHTML += `
    
    <p>Entità: <span class="${d.severity}">${d.severity}</span></p>
    <p>Tipo: ${d.type}</p>    
    `
    annotationDiv.appendChild(annotationTextDiv)
    this.annotations[aId] = annotation
    this.annotations[aId].descriptionDomElement = annotationTextDiv




  }

  addTooltip(p) {

    const OFFSET_Y = 2

    console.clear()
    const div = document.getElementById('annotation-1')
    //this.labelRenderer.domElement.appendChild(div);
    div.style.pointerEvents = 'auto';

    this.annotation1 = new CSS2DObject(div);
    //this.annotation1.position.copy(p.point)

    this.annotation1.position.set(p.point.x, p.point.y + OFFSET_Y, p.point.z)
    //this.annotation1.position.set(this.mouse.x, this.mouse.y, 0)

    this.isAnnotationHidden = false
    console.log(this.annotation1)


    this.scene.add(this.annotation1)


  }




  lastObjHoverId = -1
  lastMaterial
  lastObjName = ''

  onMouseMove(evt: MouseEvent) {




  }







  onClick(evt: MouseEvent) {

    /////////////////////// CHECK INTERSECTIONS ON MARKERS ///////////////////////
    const rectMarkers = this.renderer.domElement.getBoundingClientRect();
    this.mouse.x = ((evt.clientX - rectMarkers.left) / (rectMarkers.right - rectMarkers.left)) * 2 - 1;
    this.mouse.y = - ((evt.clientY - rectMarkers.top) / (rectMarkers.bottom - rectMarkers.top)) * 2 + 1;
    //PARTE PER ANNOTATIONS
    /*
    const element = document.getElementById('annotation-1');
    const rect = element.getBoundingClientRect();
    if (
      this.mouse.x * 100 >= rect.left &&
      this.mouse.x * 100 <= rect.right &&
      this.mouse.y * 100 >= rect.top &&
      this.mouse.y * 100 <= rect.bottom
    ) {
      console.log('Clicked on the CSS2DObject!');
    }*/
    //PARTE PER ANNOTATIONS
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersectsMarkers = this.raycaster.intersectObjects(this.annotationMarkers)
    console.log(intersectsMarkers)
    this.hideAllAnnotations()
    // if(this.annotation1 != null){
    //   const intersectsAnnotations = this.raycaster.intersectObjects([this.annotation1]);
    //   if ( intersectsAnnotations.length > 0){
    //     console.log("AAAAAAAAAAAAAAA",intersectsAnnotations)
    //   }
    // }

    if (intersectsMarkers.length > 0 && intersectsMarkers[0].object.userData && intersectsMarkers[0].object.userData.id) {

      let i = +intersectsMarkers[0].object.userData.id - 1
      let d = this.damages[i]

      console.clear()
      console.log('Marker found', this.damages, i, d)

      console.log(intersectsMarkers)

      this.showDamage(d)
      //console.log(d, i)
      //this.openDialog(d.intersect, i)
      ///this.showAnnotation(intersects[0].object.userData.id)
      return
    }
    ////////////////////////////////////////////////////////////////////////////////

    if (this.readonly) return
    if (!this.controlsEnabled) return
    this.onTest(evt)
    // this.onDoubleClick(evt) //ADD DAMAGE
  }

  onPointerUp(event) {
    this.pointerDown = false
  }

  showAnnotation(id) {
    if (this.annotations[id].descriptionDomElement)
      (this.annotations[id].descriptionDomElement as HTMLDivElement).style.display = 'block'
  }

  hideAllAnnotations() {
    Object.keys(this.annotations).forEach((annotation) => {
      if (this.annotations[annotation].descriptionDomElement)
        (this.annotations[annotation].descriptionDomElement as HTMLDivElement).style.display = 'none'
    })
  }



  getGroup(id) {

    const object = this.scene.getObjectById(id);

    if (this.rootObjectNames.includes(object.parent.name)) return id

    return this.getGroup(object.parent.id)

  }

  setReflection() {


    let material = new THREE.MeshPhongMaterial({
      color: 0x909090,
      //roughness: 0.1,
      //metalness: 0.9,

      emissive: 0x000000,
      specular: 0x111111,
      shininess: 100,

      reflectivity: 1,
      refractionRatio: 0.98,

      side: THREE.FrontSide,
    })


    this.object.material = material



  }

  showDamageImage: IDamage3d
  showDamage(d: IDamage3d) {

    console.log(d)
    console.log(this.damages)

    this.showDamageImage = d
  }



  undo() {
    this.clearMarkers()
    this.delete(this.damages.length - 1)
  }

  deleteDamage() {

    this.showDamageImage = undefined

    const i = this.damages.findIndex(e => e.intersect[0]?.object?.userData?.id == this.showDamageImage?.intersect[0]?.object?.userData?.id)


    this.delete(i)

  }

  delete(i) {
    if (!confirm('Sei sicuro di voler eliminare il danno?')) return
    this.onDelete.emit(i)

    this.damages.splice(i, 1)

    this.clearMarkers()


  }




  resetObjRotation() {
    this.object.rotateX(-this.currentObjRotation.x)
    this.object.rotateY(-this.currentObjRotation.y)
    this.object.rotateZ(-this.currentObjRotation.z)

    this.currentObjRotation = { x: 0, y: 0, z: 0 }
  }

}



const getDamageTypes = [
  {
    "code": "M",
    "zone": "Esterni",
    "description": "Danneggiato"
  },
  {
    "code": "R",
    "zone": "Esterni",
    "description": "Rotto"
  },
]



export interface IDamage3d {
  x?: number;
  y?: number;

  intersect?: { face: any, point: any }
  //object?: any
  objectName?: string

  severity: string;
  type: string;

  img1?: IFoto;
  img2?: IFoto;
  img_rip?: IFoto;

  color?: string;
  //part?: any;

  cost?: any

  extra?: IExtra[]
}

export interface IExtra {

  descr: string
  img1?: IFoto;
  img2?: IFoto;
  img_rip?: IFoto;

}



const getDefault = (objUrl) => {
  let tmp = DEFAULTS.find(e => e.objUrl == objUrl)
  return tmp ? tmp : DEFAULTS[0]
}




const _1DEG = 0.0174533
const _1RAD = 57.29578
const STEP = 45
const G = 9.81

const DEFAULTS = [
  {
    objUrl: '/assets/models/audi.glb',
    object: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 3,
      positionZ: 0,
    },
    camera: {
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,

      positionX: 0,
      positionY: 10,
      positionZ: 70,

    }
  },

  //_1DEG * -90 + 0,
]
