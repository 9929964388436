<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <div class="card">
          <div class="card-header card-header-info">
            <h4 class="card-title">UXpert</h4>


          </div>
          <div class="card-body">


            <mat-tab-group (selectedTabChange)="onTabChanged($event);">



              <mat-tab label='Elenco definizioni' style="height: 100%;">


                <div *ngIf="!item; else edit">
                  <br>

                  <ag-grid-angular style="width: 100%; height: calc(100vh - 300px);" class="ag-theme-alpine"
                    [rowData]="rowData_defs" *ngIf="rowData_defs" [columnDefs]="columnDefs_defs"
                    [defaultColDef]="defaultColDef_defs" (gridReady)="onGridReady_defs($event)">
                  </ag-grid-angular>


                  <eco-fab-speed-dial style="z-index: 999; position:absolute; left:5px; bottom:5px;"
                    animationMode="fling">

                    <eco-fab-speed-dial-trigger>
                      <button type="button" mat-fab color="primary" (click)="addDef()">
                        <mat-icon>add</mat-icon>
                      </button>
                    </eco-fab-speed-dial-trigger>
                  </eco-fab-speed-dial>

                </div>

                <ng-template #edit>
                  <br>
                  <div class="row">
                    <div class="col-4">
                      <mat-form-field class="full">
                        <mat-label>Tipo</mat-label>
                        <mat-select [(ngModel)]="item.type" [disabled]="item.id != '' ">
                          <mat-option value="doc">DOC</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-4">
                      <mat-form-field class="full">
                        <mat-label>Tipo Veicolo</mat-label>
                        <mat-select [(ngModel)]="item.vehType" [disabled]="item.id != '' ">
                          <mat-option value="auto">AUTO</mat-option>
                          <mat-option value="bike">MOTO</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!--
                    <div class="col-2">
                      <mat-form-field class="full">
                        <mat-label>Versione</mat-label>
                        <input matInput [(ngModel)]="item.version" readonly>
                      </mat-form-field>
                    </div>
-->
                    <div class="col-4">
                      <mat-label>Attuale: </mat-label>
                      <mat-checkbox [(ngModel)]="item.current"></mat-checkbox>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col">

                      <ag-grid-angular style="width: 100%; height: calc(100vh - 300px);" class="ag-theme-alpine"
                        [rowData]="item.questions" *ngIf="item?.questions" [columnDefs]="columnDefs_def"
                        [defaultColDef]="defaultColDef_def" (gridReady)="onGridReady_def($event)">
                      </ag-grid-angular>

                    </div>
                  </div>


                  <div class="row">
                    <div class="col">
                      <div style="float:right">
                        <button type="button" mat-raised-button class="btn btn-success"
                          (click)="saveDef()">Salva</button>

                        <button type="button" mat-raised-button class="btn btn-danger"
                          (click)="cancelDef()">Annulla</button>
                      </div>
                    </div>
                  </div>


                  <eco-fab-speed-dial style="z-index: 999; position:absolute; left:5px; bottom:5px;"
                    animationMode="fling">

                    <eco-fab-speed-dial-trigger>
                      <button type="button" mat-fab color="primary" (click)="addQuestion()">
                        <mat-icon>add</mat-icon>
                      </button>
                    </eco-fab-speed-dial-trigger>
                  </eco-fab-speed-dial>

                </ng-template>


              </mat-tab>

            </mat-tab-group>

            <!--
            <input type="file" #file_visura id="file_visura" style="display: none;" (change)="addAttachment($event, 'visura')"
            accept="application/pdf">
        
          <input type="file" #file_etax id="file_etax" style="display: none;" (change)="addAttachment($event, 'etax')"
            accept="application/pdf">
-->
          </div>
        </div>


      </div>
    </div>
  </div>
</div>