<div id="toolbar" *ngIf="!disabled">
  <!--
  <button mat-raised-button [ngClass]="{'selected': currentSeverity == 'L'}" (click)="setSeverity('L')" style="background-color: green;  color:white">Lieve</button>
  <button mat-raised-button [ngClass]="{'selected': currentSeverity == 'M'}" (click)="setSeverity('M')" style="background-color: orange; color:white">Medio</button>
  <button mat-raised-button [ngClass]="{'selected': currentSeverity == 'G'}" (click)="setSeverity('G')" style="background-color: red;    color:white">Grave</button>
  <button mat-raised-button [ngClass]="{'selected': currentSeverity == 'S'}" (click)="setSeverity('S')" style="background-color: #0066ff;  color:white">Sost.</button>
-->
  <button color="danger" mat-raised-button (click)="undo()" style="margin-left:30px; background-color: gray;    color:white">Undo</button>
</div>

<div>
  <div style="height: 60vh">
    <canvas style="height: 60vh" #canvas (mouseup)="mouseup($event)" (mousedown)="mousedown($event)" (mousemove)="mousemove($event)"
      (mouseleave)="mouseleave($event)"></canvas>
  </div>

  <!--[ngClass]="{'blueprint':true, 
    'suv':value?.vehType=='suv', 
    'truck':value?.vehType=='truck', 
    'bike':value?.vehType=='bike', 
    'berlina':value?.vehType=='berlina',
    'lcv':value?.vehType=='lcv'
  }"-->
</div>

<div class="imgs" *ngIf="selectedDamage">
  <div class="card-header card-header-info">
    <h4 class="card-title">
      Localizzazione danno / dettaglio
      <i style="float:right" color="danger" (click)="selectedDamage=null" class="material-icons">close</i>
    </h4>
  </div>


  <!--
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <img style="width:100%" [src]="selectedDamage.img1.url ? selectedDamage.img1.url : selectedDamage.img1.thumb">
      </div>
      <div class="col-6">
        <img style="width:100%" [src]="selectedDamage.img2.url ? selectedDamage.img2.url : selectedDamage.img2.thumb">
      </div>
    </div>
  </div>
-->
</div>