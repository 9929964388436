<div class="cnt card">

  <table class="light">
    <tr>
      <td colspan=4 style="color:black; text-align: center;">
        <strong>{{title}}</strong>
      </td>
    </tr>
    <tr>
      <td class="input-top">{{tire?.l}}
        <!--
        <ion-select class="input-top" [(ngModel)]="tire?.l" placeholder="Larghezza" (ionChange)="changed($event)">
          <ion-select-option *ngFor="let e of l" [value]="e">{{e}}</ion-select-option>
        </ion-select>-->
      </td>
      <td class="input-top">{{tire?.h}}
        <!--
        <ion-select class="input-top" [(ngModel)]="tire?.h" placeholder="Altezza" (ionChange)="changed($event)">
          <ion-select-option *ngFor="let e of h" [value]="e">{{e}}</ion-select-option>
        </ion-select>-->
      </td>
      <td class="input-top">{{tire?.d}}
        <!--
        <ion-select class="input-top" [(ngModel)]="tire?.d" placeholder="Diametro" (ionChange)="changed($event)">
          <ion-select-option *ngFor="let e of d" [value]="e">{{e}}</ion-select-option>
        </ion-select>-->
      </td>
      <td class="input-top">{{tire?.brand}}
        <!--
        <ion-select class="input-top" [(ngModel)]="tire?.brand" placeholder="Costruttore" (ionChange)="changed($event)">
          <ion-select-option *ngFor="let e of brands" [value]="e">{{e}}</ion-select-option>
        </ion-select>-->
      </td>
    </tr>

    <tr>
      <td colspan=4><img class="tire" src="/assets/img/tire.png"></td>
    </tr>

    <tr>
      <td colspan=2>

        <span *ngIf="tire?.runflat">Runflat/ZP/SSR</span>
        <span *ngIf="!tire?.runflat"><s>Runflat/ZP/SSR</s></span>

        <!--
        <ion-item>
          <ion-label>Runflat/ZP/SSR</ion-label>
          <ion-toggle [(ngModel)]="tire?.runflat" (ionChange)="changed($event)"></ion-toggle>
        </ion-item>
        -->
      </td>

      <td colspan=2>
        {{tire?.type}}
        <!--
        <ion-select [(ngModel)]="tire?.type" placeholder="Tipo" (ionChange)="changed($event)">
          <ion-select-option *ngFor="let e of t" [value]="e">{{e}}</ion-select-option>
        </ion-select>
        -->
      </td>

    </tr>

    <tr>
      <td colspan=4 style="color:black">
        <hr>

        Battistrada: {{tire?.mm}} mm
        <!--
        <ion-item>
          <ion-label> Battistrada: </ion-label>
          <ion-input ionInput type="number" min="0" max="9" step="0.1" [(ngModel)]="tire?.mm"
            (ionBlur)="changed($event)"> mm </ion-input>
        </ion-item>
-->
      </td>
    </tr>

    <tr>
      <td colspan="4">
        <app-gallery *ngIf="tire?.img" [disabled]="true" [source]="filterForGallery(tire.img)" [(source)]="tire.img" (onSelected)=openViewer($event,tire.img) [uploader]="false" [categorized]="false" [showLabel]="false"> 
        </app-gallery>
      </td>
    </tr>

  </table>




</div>