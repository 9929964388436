export const environment = {
    appVersion: require('../../package.json').version,
    production: false,
    envName: 'ie',

    config: {
        apiKey: "AIzaSyDiY9p3L38oHdO94ppeJcCEDIAxuWk5Jw8",
        authDomain: "telexpert-748c9.firebaseapp.com",
        databaseURL: "https://telexpert-748c9.firebaseio.com",
        projectId: "telexpert-748c9",
        storageBucket: "telexpert-748c9.appspot.com",
        messagingSenderId: "1050862928023"
    }


};