import { Component, OnInit, Input, Output , EventEmitter} from '@angular/core';
import { TableCheckboxComponent } from 'app/components/table/table-checkbox/table-checkbox.component';
import { MatDialog } from '@angular/material/dialog';
import { MysqlService } from 'app/services/mysql.service';
import { AuthorizationService } from 'app/services/authorization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';


@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {


  settings = {
actions: {
      position: 'left', width: '100px', columnTitle: '*Azioni*',
      add: false,
      edit: false,
      delete: false,
      download: true,
    },
    

    pager: {perPage:50},

    mode: 'external',


    columns: {


      selected: {
        title: '',
        type: 'custom',
        renderComponent: TableCheckboxComponent,
        filter: false,
        width: '10px'
      },

      id: {
        title: 'ID',
  
      },

      targa: {
        title: 'Targa',
  
      },

      modello: {
        title: 'Modello',
      },
      download: {
        title: 'Download',
        type: 'custom',
        renderComponent: ProgramDownloadComponent,
        filter: false,
        width: '10px'
      },
 

      
    }
  };
  source : LocalDataSource;
  constructor(   public dialog: MatDialog,
    private db: MysqlService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private route: ActivatedRoute,
) { 

      
    }

  ngOnInit(): void {
    this.loadData();
  }
  loadData(){
    this.db.program_getData().then(res => {
      console.log(res)
      this.source = res.list
    })
  

  }
}
/** */
@Component({
  selector: 'app-program-download',
  template: `
               <button (click)="download()" mat-icon-button >
               <i class="material-icons">arrow_downward                       </i>
              </button> 
              
              
  `
})
export class ProgramDownloadComponent implements ViewCell, OnInit {

  @Input() value;
  @Input() rowData: any;
  @Output() refresh = new EventEmitter<any>();

  state: string = '';
  next: string = '';
  buttonEnabled = false;



  constructor( private db: MysqlService) {

  

  }



  ngOnInit() {

  }


  download(dl = false) {
    console.log()
    let url = this.db.program_getUrl(this.rowData.id)

    window.open(url, '_system')

  }




}

