<div *ngIf="!showEscalation">

    <h3 style="text-align: center;">Richiedi un chiarimento</h3><br>

    <div class="row" *ngIf="statoReclamo">


        <div class="col-12 col-sm-6" *ngIf="statoReclamo.reclamo1_dta">

            <div *ngIf="statoReclamo.reclamo1_dta">

                Richiesto da: {{statoReclamo.reclamo1_nome}} {{statoReclamo.reclamo1_cognome}}<br>
                In data: {{statoReclamo.reclamo1_dta|date:'dd/MM/yyyy'}}<br>
                Contatti: {{statoReclamo.reclamo1_email}} - {{statoReclamo.reclamo1_tel}}
                <br>
                {{statoReclamo.reclamo1_richiesta}}
                <hr>

                <span *ngIf="statoReclamo.risposta1_dta">
                    Risposta in data: {{statoReclamo.risposta1_dta|date:'dd/MM/yyyy'}}
                    {{statoReclamo.risposta1_descr}}
                    <hr>
                </span>

                <span *ngIf="role=='' && !statoReclamo.risposta1_dta">
                    In attesa di risposta
                    <hr>
                </span>


                <div *ngIf="(role == 'ADMIN' || role == 'GESTORE') && !statoReclamo.risposta1_dta">
                    <form [formGroup]="form_risposta">
                        <mat-form-field class="full">
                            <mat-label>Risposta</mat-label>
                            <textarea type="text" matInput formControlName="risposta" [(ngModel)]="risposta"></textarea>
                        </mat-form-field>

                        <button class="btn btn-success" type="button" mat-raised-button (click)="rispondiReclamo()"
                            style="float:right; margin-right:15px">Invia</button>

                    </form>
                </div>

            </div>

        </div>

        <div class="col-12 col-sm-6" *ngIf="statoReclamo.reclamo2_dta">



            <div *ngIf="statoReclamo.reclamo2_dta">

                Richiesto da: {{statoReclamo.reclamo2_nome}} {{statoReclamo.reclamo2_cognome}}<br>
                In data: {{statoReclamo.reclamo2_dta|date:'dd/MM/yyyy'}}<br>
                Contatti: {{statoReclamo.reclamo2_email}} - {{statoReclamo.reclamo2_tel}}
                <br>
                {{statoReclamo.reclamo2_richiesta}}
                <hr>

                <span *ngIf="statoReclamo.risposta2_dta">
                    Risposta in data: {{statoReclamo.risposta2_dta|date:'dd/MM/yyyy'}}
                    {{statoReclamo.risposta2_descr}}
                    <hr>
                </span>

                <span *ngIf="role=='' && !statoReclamo.risposta2_dta">In attesa di risposta
                    <hr>
                </span>


                <div *ngIf="(role == 'ADMIN' || role == 'GESTORE') && !statoReclamo.risposta2_dta">
                    <form [formGroup]="form_risposta">
                        <mat-form-field class="full">
                            <mat-label>Risposta</mat-label>
                            <textarea type="text" matInput formControlName="risposta" [(ngModel)]="risposta"></textarea>
                        </mat-form-field>

                        <button class="btn btn-success" type="button" mat-raised-button (click)="rispondiReclamo()"
                            style="float:right; margin-right:15px">Invia</button>

                    </form>
                </div>

            </div>



        </div>

        <div class="col-12 col-sm-6"
            *ngIf="(statoReclamo.stato == '' || statoReclamo.stato == 'RISPOSTA 1') && role=='' ">
            <form [formGroup]="form_reclamo">


                <div class="row">
                    <div class="col">
                        <mat-form-field class="full">
                            <mat-label>Nome</mat-label>
                            <input type="text" matInput placeholder="Nome" formControlName="nome"
                                [(ngModel)]="reclamo.nome">
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field class="full">
                            <mat-label>Cognome</mat-label>
                            <input type="text" matInput placeholder="Cognome" formControlName="cognome"
                                [(ngModel)]="reclamo.cognome">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <mat-form-field class="full">
                            <mat-label>Email</mat-label>
                            <input type="email" matInput placeholder="Email" formControlName="email"
                                [(ngModel)]="reclamo.email">
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field class="full">
                            <mat-label>Telefono</mat-label>
                            <input type="text" matInput placeholder="Telefono" formControlName="tel"
                                [(ngModel)]="reclamo.tel">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <mat-form-field class="full">
                            <mat-label>Richiesta</mat-label>
                            <textarea type="text" matInput placeholder="Richiesta" formControlName="richiesta"
                                [(ngModel)]="reclamo.richiesta"></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <!--
          <button class="btn btn-danger" type="button" mat-raised-button (click)="close()"
            style="float:right">Annulla</button>
        -->
                        <button class="btn btn-success" type="button" mat-raised-button (click)="insertReclamo()"
                            style="float:right; margin-right:15px">Invia</button>
                    </div>
                </div>

            </form>


        </div>



    </div>


    <div *ngIf="statoReclamo?.escalation_chiusura_dta">

        <h3 style="text-align: center;">Conclusione:</h3>

        In data: {{statoReclamo.escalation_chiusura_dta|date:'dd/MM/yyyy'}}<br>
        {{statoReclamo.escalation_chiusura}}
        <hr>

    </div>


    <div class="row">
        <div class="col">
            <button class="btn btn-danger" type="button" mat-raised-button (click)="toggleEscalation()"
                *ngIf="(role == 'ADMIN' || role == 'GESTORE' || role == 'CLIENTE')">ESCALATION</button>
        </div>
    </div>
</div>


<div *ngIf="showEscalation && (role == 'ADMIN' || role == 'GESTORE' || role == 'CLIENTE')">
    <br>

    <h3 style="text-align: center;">Richiesta ESCALATION:</h3>

    <div *ngIf="statoReclamo.stato != 'ESCALATION' && statoReclamo.stato != 'RISPOSTA ESC' && statoReclamo.stato != 'CONCLUSO'  && (role == 'ADMIN' || role == 'GESTORE')">

        <form [formGroup]="form_escalation" >
            <mat-form-field class="full">
                <mat-label>Richiedi ESCALATION</mat-label>
                <textarea type="text" matInput formControlName="escalation_richiesta"
                    [(ngModel)]="escalation_richiesta"></textarea>
            </mat-form-field>

            <button class="btn btn-success" type="button" mat-raised-button (click)="inviaEscalation()"
                style="float:right; margin-right:15px">Invia</button>

        </form>

    </div>


    <div *ngIf="statoReclamo.stato == 'ESCALATION' || statoReclamo.stato == 'RISPOSTA ESC'  || statoReclamo.stato == 'CONCLUSO' ">

        In data: {{statoReclamo.escalation_dta|date:'dd/MM/yyyy'}}<br>
        {{statoReclamo.escalation_richiesta}}
        <hr>


        <form [formGroup]="form_escalation_risp" *ngIf="statoReclamo.stato == 'ESCALATION'  && role == 'CLIENTE' ">
            <!-- SETTARE PERMESSI SOLO CLIENTE -->
            <br>

            <h3 style="text-align: center;">Risposta ESCALATION:</h3>

            <mat-form-field class="full">
                <mat-label>Rispondi all'ESCALATION</mat-label>
                <textarea type="text" matInput formControlName="escalation_risposta"
                    [(ngModel)]="escalation_risposta"></textarea>
            </mat-form-field>

            <button class="btn btn-success" type="button" mat-raised-button (click)="rispondiEscalation()"
                style="float:right; margin-right:15px">Invia</button>

        </form>

        <div *ngIf="statoReclamo.stato != 'ESCALATION'">

            <h3 style="text-align: center;">Risposta ESCALATION:</h3>

            In data: {{statoReclamo.escalation_risp_dta|date:'dd/MM/yyyy'}}<br>
            {{statoReclamo.escalation_risposta}}
            <hr>



            <form [formGroup]="form_escalation_chiusura" *ngIf="statoReclamo.stato == 'RISPOSTA ESC'  && (role == 'ADMIN' || role == 'GESTORE')">
                <!-- SETTARE PERMESSI SOLO GESTORE e ADMIN -->
                <br>

                <h3 style="text-align: center;">Risposta al CLIENTE:</h3>

                <mat-form-field class="full">
                    <mat-label>Rispondi al CLIENTE</mat-label>
                    <textarea type="text" matInput formControlName="escalation_chiusura"
                        [(ngModel)]="escalation_chiusura"></textarea>
                </mat-form-field>

                <button class="btn btn-success" type="button" mat-raised-button (click)="chiudiEscalation()"
                    style="float:right; margin-right:15px">Invia</button>

            </form>


            <div *ngIf="statoReclamo.stato == 'CONCLUSO'">

                <h3 style="text-align: center;">Risposta al CLIENTE:</h3>
    
                In data: {{statoReclamo.escalation_chiusura_dta|date:'dd/MM/yyyy'}}<br>
                {{statoReclamo.escalation_chiusura}}
                <hr>

            </div>

        </div>


    </div>

    <div class="row">
        <div class="col">
            <button class="btn btn-danger" type="button" mat-raised-button (click)="toggleEscalation()"
                *ngIf="(role == 'ADMIN' || role == 'GESTORE' || role =='CLIENTE')">INDIETRO</button>
        </div>
    </div>

</div>