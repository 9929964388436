import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  domain = '';
  constructor() { }

  ngOnInit() {
    this.domain = window.location.href.split('/')[2].split(':')[0];
  }

}
