<div class="main-content" style="height: calc(100vh - 50px); padding-top:40px; margin-bottom:0">


  <form [formGroup]="form" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" style="height: 100%; ">



    <div class="card">

      <div class="card-header card-header-info">
        <h4 class="card-title">

          FIR - ID: {{item.id ? item.id : 'NUOVO'}}
          <div style="float:right" >
            <button style="margin-right: 10px;" mat-raised-button type="submit" class="btn btn-success" *ngIf="isWritable">Salva
              Richiesta</button>
            <a (click)="close()"><i class="material-icons">close</i></a>
          </div>
        </h4>
      </div>

      <div class="card-body" style="width: 100%!important; height: 100%" *ngIf="loading">
        <div class="row">
          <div class="col-sm-2" style="margin:auto">

            <mat-spinner *ngIf="isMSIE()"></mat-spinner>
            <mat-progress-spinner mode="indeterminate" [value]="loading_percentage" *ngIf="!isMSIE()">
            </mat-progress-spinner>

          </div>
        </div>

      </div>

      <div class="card-content" style="height: calc(100vh - 100px); overflow-x:hidden; overflow-y:auto; padding:20px;"
        [hidden]="loading">

        <div class="row">

          <div class="col-12 col-lg-6">

            <mat-form-field>
              <mat-label>Tipo richiesta</mat-label>
              <mat-select placeholder="Tipo richiesta" [(ngModel)]="item.type" formControlName="type" id="type"
                [disabled]="isDisabled()">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let i of types" [value]="i">
                  {{i}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Descrizione evento</mat-label>
              <textarea matInput type="text" placeholder="Descrivi la tua richiesta" [(ngModel)]="item.note"
                formControlName="note" [readonly]="isDisabled()" id="note" matTextareaAutosize matAutosizeMinRows=5
                matAutosizeMaxRows=25></textarea>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Categoria prodotto</mat-label>
              <mat-select placeholder="Categoria prodotto" [(ngModel)]="item.productCategory"
                (ngModelChange)="productCategoryChange($event)" [disabled]="isDisabled()" formControlName="productCategory"
                id="productCategory">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let i of productCategories" [value]="i">
                  {{i}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Stato</mat-label>
              <mat-select placeholder="Stato prodotto" [(ngModel)]="item.productState" formControlName="productState"
                [disabled]="isDisabled()" id="productState">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let i of states" [value]="i">
                  {{i}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Alimentazione</mat-label>
              <mat-select placeholder="Alimentazione" [(ngModel)]="item.powerType" formControlName="powerType"
                [disabled]="isDisabled()" id="powerType">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let i of powerTypes" [value]="i">
                  {{i}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Applicazione</mat-label>
              <mat-select placeholder="Applicazione" [(ngModel)]="item.application" formControlName="application"
                [disabled]="isDisabled()" id="application">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let i of applications" [value]="i">
                  {{i}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Tipo cliente</mat-label>
              <mat-select placeholder="Tipo cliente" [(ngModel)]="item.clientType" formControlName="clientType"
                [disabled]="isDisabled()" id="clientType">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let i of clientTypes" [value]="i">
                  {{i}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Il cliente è estero?</mat-label>
              <mat-select placeholder="Tipo cliente" [(ngModel)]="item.clientExt" formControlName="clientExt"
                [disabled]="isDisabled()" id="clientExt">
                <mat-option value="NO">NO</mat-option>
                <mat-option value="SI">SI</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Persona di riferimento</mat-label>
              <input matInput type="text" placeholder="Persona di riferimento" [(ngModel)]="item.refPerson"
                formControlName="refPerson" [readonly]="isDisabled()" id="refPerson">
            </mat-form-field>

            <mat-form-field>
              <mat-label>Telefono</mat-label>
              <input matInput type="text" placeholder="Telefono" [(ngModel)]="item.refPhone" formControlName="refPhone"
                [readonly]="isDisabled()" id="refPhone">
            </mat-form-field>

          </div>
          <div class="col-12 col-lg-6">

            <app-gallery [categorized]="false" [disabled]="isDisabled()" [(source)]="item.attachments"
              (onDelete)="save(false)" (onSelected)=openViewer($event,item.attachments)></app-gallery>
            <br>
            <div [ngClass]="{'hidden': !item.id || item.id <= 0}">

              <mat-form-field>
                <mat-label>Stato richiesta</mat-label>
                <mat-select placeholder="Stato richiesta" [(ngModel)]="item.status" formControlName="status"
                  [disabled]="isUser() || item.status=='DOCUMENTAZIONE SCARICATA'" id="status">
                  <mat-option value="IN LAVORAZIONE">IN LAVORAZIONE</mat-option>
                  <mat-option value="RICHIESTA INTEGRAZIONE">RICHIESTA INTEGRAZIONE</mat-option>
                  <mat-option value="CONCLUSO">CONCLUSO</mat-option>
                  <mat-option disabled value="DOCUMENTAZIONE SCARICATA">DOCUMENTAZIONE SCARICATA</mat-option>
                  
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Risposta</mat-label>
                <textarea matInput type="text" placeholder="response" [(ngModel)]="item.response"
                  formControlName="response" [readonly]="isUser()" id="response" matTextareaAutosize
                  matAutosizeMinRows=5 matAutosizeMaxRows=25></textarea>
              </mat-form-field>

            </div>

            <div [ngClass]="{'hidden': item.status!='CONCLUSO' &&  item.status!='DOCUMENTAZIONE SCARICATA'}">


                <button (click)="download(true)" expand="full" mat-raised-button type="button" class="btn btn-success" *ngIf="isUser()" [disabled]="item.lead1 < 0 || item.lead2 < 0 || item.lead3 < 0">Scarica Documentazione</button>
                <button (click)="download(false)" expand="full" mat-raised-button type="button" class="btn btn-success" *ngIf="!isUser()" [disabled]="item.lead1 < 0 || item.lead2 < 0 || item.lead3 < 0">Scarica Documentazione</button>
                
                <span *ngIf="item.lead1 < 0 || item.lead2 < 0 || item.lead3 < 0 ">Compila il questionario per poter scaricare la documentazione</span>

                <hr>
 
              <div>
                <mat-label>Ti è stato utile il servizio?</mat-label>
                <div>
                  <app-rating [(value)]="item" [itemId]="'lead1'"
                    [disabled]="item.status != 'CONCLUSO' && isUser() || !isUser() "
                    [text]="['Ho risolto la problematica','Ho parzialmente risolto la problematica','Non Ho risolto la problematica']">
                  </app-rating>
                  <hr>
                </div>
              </div>
              <div>
                <mat-label>Per quanto riguarda i tempi di lavorazione del servizio
                </mat-label>
                <div>
                  <app-rating [(value)]="item" [itemId]="'lead2'"
                    [disabled]="item.status != 'CONCLUSO' && isUser() || !isUser() "
                    [text]="['Ho ricevuto la risposta nei tempi attesi','Ho ricevuto pressioni dal cliente per una risposta','Ho atteso troppo tempo']">
                  </app-rating>
                  <hr>
                </div>
              </div>
              <div>
                <mat-label>Pensi di utilizzare nuovamente il servizio
                </mat-label>
                <div>
                  <app-rating [(value)]="item" [itemId]="'lead3'"
                    [disabled]="item.status != 'CONCLUSO' && isUser() || !isUser()"
                    [text]="['Sicuramente','Forse','Non credo']">
                  </app-rating>
                  <hr>
                </div>
              </div>
            </div>

            

          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<app-doc-viewer [index]="docviewer_i" [(files)]="docviewer_source" *ngIf="docviewer" (closed)="closeDocviewer()"
  [position]="docviewer_position" [filter]="docviewer_filter"></app-doc-viewer>