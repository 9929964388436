import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameToInitial'
})
export class NameToInitialPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    //console.log(value)
    
    if(value=='*') return '* Tutti *'

    let r = '';
    try {

      let x: string[] = value.split(' ')

      for (let i=0; i<x.length; i++){
        r+=x[i].substring(0,1).toUpperCase();
      }

    } catch (error) {

      return value;

    }

    if(r=='') return value

    return r;
  }

}
