<div class="cnt">

    <div [ngClass]="{'logo':true}">
        <a href="./" class="simple-text">
            <div class="logo-img">
                <img [src]="logo" />
            </div>
        </a>
    </div>


    <div [ngClass]="{'sidebar-wrapper':false}">

        <app-navbar [hidden]="!user"></app-navbar><br><br>
        <hr>

        <ul class="nav">

            <mat-accordion>

                <div *ngFor="let c of categories">
                    <mat-expansion-panel *ngIf="countVisibleByCategory(c.text)>0" >
                        
                        <mat-expansion-panel-header [ngClass]="['cl_' + c.color]">
                            <mat-panel-title>
                                <i class="material-icons">{{c.icon}}</i>{{c.text}}
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <li routerLinkActive="active" *ngFor="let menuItem of filterMenuItems()"
                            class="{{menuItem.class}} nav-item">

                            <div *ngIf="menuItem.category == c.text">
                                <a class="nav-link" (click)=open(menuItem)
                                    *ngIf="!menuItem.separator && !menuItem.child && ( !menuItem.permissions || menuItem.permissions.includes(user?.Livello) ) && checkUserPermissionFlag(menuItem)">

                                    <span *ngIf="!menuItem.disabled">
                                        <i class="material-icons">{{menuItem.icon}}</i>
                                        <p>{{menuItem.title}}</p>
                                    </span>

                                    <span *ngIf="menuItem.disabled">
                                        <s style="cursor:not-allowed;">
                                            <i class="material-icons">{{menuItem.icon}}</i>
                                            <p>{{menuItem.title}}</p>
                                        </s>
                                    </span>
                                </a>
                                <!--
                        <a class="nav-link" [routerLink]="[menuItem.path]" *ngIf="!menuItem.separator && menuItem.child" style="margin-left:30px; font-size: 80%">
                            <i class="material-icons">{{menuItem.icon}}</i>
                            <p>{{menuItem.title}}</p>
                        </a>
                        -->
                                <div class="logo" *ngIf="menuItem.separator">

                                </div>

                            </div>

                        </li>


                    </mat-expansion-panel>

                </div>
            </mat-accordion>






        </ul>
    </div>
</div>