<div class="main-content" style="padding-top: 40px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">


        <div class="card">
          <div class="card-header " style="background-color: #ee1d23!important">
            <h4 class="card-title">
              <div style="float:left">
                <img src="/assets/img/fir.png" height=32 style="margin-right:20px">

                <span style="color:white; font-size:80%">Servizio di consulenza per la gestione della garanzia</span>

                <br><span style="vertical-align: bottom; margin:0; padding:0; font-size: 50%; color:white"><i
                    style="color:white">Powered by</i>
                  Independent Expert</span>
              </div>

              <div style="position:absolute; right:10px;  top:50%">
              </div>
            </h4>

            <button mat-raised-button (click)="logout()" style="margin-top:10px; float:right" *ngIf="type=='ext'"><i
                color="primary" class="material-icons">exit_to_app</i></button>

            <button mat-raised-button (click)="export()" style="margin-top:10px; float:right">Esporta XLS</button>
          </div>
          <div class="card-body">

            <mat-tab-group (selectedTabChange)="tabChange($event)">
              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon class="example-tab-icon">list</mat-icon>
                  Elenco
                </ng-template>
                <br>

                <div style="min-width: 1000px; overflow-x: auto">

                  <!-- <ng2-smart-table class="grid" [settings]="settings" [source]="source" (delete)="onDelete($event)"
                    (create)="onCreateDialog()" (edit)="onEditDialog($event)">
                  </ng2-smart-table> -->
                  <ag-grid-angular style="width: 100%; height: calc(100vh - 300px);" class="ag-theme-alpine"
                    [rowData]="rowDataElenco" rowSelection="multiple" [gridOptions]="gridOptions">
                  </ag-grid-angular>
                  <div class="fab-container">
                    <button mat-fab class="fab-toggler mat-primary" color="primary" style="float:right; margin-top:10px"
                      (click)="onCreateDialog()">
                      <i class="material-icons">add</i>
                    </button>
                  </div>
                </div>

              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon class="example-tab-icon">insert_chart</mat-icon>
                  Riepilogo
                </ng-template>
                <br>

                <div class="row" style="padding-left:20px!important;" *ngIf="stats">

                  <div class="col-lg-4 col-md-12 col-sm-12">

                    <div class="row" style="height:100%">

                      <div class="col-8">
                        Utilità servizio
                        <canvas id="chart1">{{ chart1 }}</canvas>

                      </div>

                      <div class="col-8">
                        Tempi di lavorazione
                        <canvas id="chart2">{{ chart2 }}</canvas>

                      </div>

                      <div class="col-8">
                        Riutilizzo servizio
                        <canvas id="chart3">{{ chart3 }}</canvas>

                      </div>

                    </div>
                  </div>

                  <div class="col-lg-8 col-md-12 col-sm-12" *ngIf="stats">

                    <br>

                    <div class="row">

                      <div class="col-sm-12">
                        <app-dashcard [color]="'gray'" [title]="'TAT - Media giorni lavorazione'"
                          [main_value]="+stats?.tat" [icon]="'access_time'">
                        </app-dashcard><br>
                      </div>


                      <div class="col-lg-3 col-md-6 col-sm-12" style="padding:0 50px">
                        <h5
                          style="font-size: 80%; font-weight: bold; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ">
                          IN LAVORAZIONE</h5>
                        <hr>
                        <mwl-gauge [max]="100" [value]="stats.n_lavorazione" class="warning growx" [animated]="true"
                          [animationDuration]="1" [dialStartAngle]="-90" [dialEndAngle]="-90.001"
                          style="margin:auto;  ">
                        </mwl-gauge>
                        <hr>
                      </div>

                      <div class="col-lg-3 col-md-6 col-sm-12" style="padding:0 50px">
                        <h5
                          style="font-size: 80%; font-weight: bold; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ">
                          RICHIESTA INTEGRAZIONE</h5>
                        <hr>
                        <mwl-gauge [max]="100" [value]="stats.n_richiesta" class="warning growx" [animated]="true"
                          [animationDuration]="1" [dialStartAngle]="-90" [dialEndAngle]="-90.001" style="margin:auto; ">
                        </mwl-gauge>
                        <hr>
                      </div>


                      <div class="col-lg-3 col-md-6 col-sm-12" style="padding:0 50px">
                        <h5
                          style="font-size: 80%; font-weight: bold; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ">
                          CONCLUSO</h5>
                        <hr>
                        <mwl-gauge [max]="100" [value]="stats.n_concluso" class="warning growx" [animated]="true"
                          [animationDuration]="1" [dialStartAngle]="-90" [dialEndAngle]="-90.001" style="margin:auto; ">
                        </mwl-gauge>
                        <hr>
                      </div>

                      <div class="col-lg-3 col-md-6 col-sm-12" style="padding:0 50px">
                        <h5
                          style="font-size: 80%; font-weight: bold; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ">
                          DOC. SCARICATA</h5>
                        <hr>
                        <mwl-gauge [max]="100" [value]="stats.n_scaricato" class="warning growx" [animated]="true"
                          [animationDuration]="1" [dialStartAngle]="-90" [dialEndAngle]="-90.001" style="margin:auto; ">
                        </mwl-gauge>
                        <hr>
                      </div>

                    </div>

                  </div>
                </div>


              </mat-tab>



              <mat-tab *ngIf="!isUser()">

                <ng-template mat-tab-label>
                  <mat-icon class="example-tab-icon">map</mat-icon>
                  Mappa
                </ng-template>

                <div id="map" style=" padding:0; margin:0; height:80vh"></div>

              </mat-tab>



              <mat-tab *ngIf="active">
                <ng-template mat-tab-label>
                  <mat-icon class="example-tab-icon">assignment</mat-icon>
                  Certificati
                </ng-template>
                <br>

                <ag-grid-angular style="width: 100%; height: calc(100vh - 600px);" class="ag-theme-alpine"
                  [rowData]="rowData" [rowStyle]="rowStyle" [getRowStyle]="getRowStyle" *ngIf="rowData"
                  [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)"
                  rowSelection="multiple">
                </ag-grid-angular>

                <div class="fab-container">
                  <button mat-fab class="fab-toggler mat-primary" color="primary" style="float:right; margin-top:10px"
                    (click)="onCertCreateDialog()">
                    <i class="material-icons">add</i>
                  </button>
                </div>

              </mat-tab>



              <mat-tab *ngIf="isAdmin()">
                <ng-template mat-tab-label>
                  <mat-icon class="example-tab-icon">assignment</mat-icon>
                  Abilitazioni utenti per certificazioni
                </ng-template>
                <br>
                <div class="row">
                  <div class="col-5">

                    <table width="100%">
                      <tr>
                        <th colspan="2"><b>NON</b> APPARTENENTI AL GRUPPO</th>
                      </tr>
                      <div *ngFor="let i of usersByGroup">
                        <tr *ngIf="!i?.permissions?.fir_active">
                          <td width="100%">{{i.Nome}}</td>
                          <td width="1"><button color="success" mat-raised-button
                              (click)="toggleActive(i)">Attiva</button></td>
                        </tr>
                      </div>
                    </table>

                  </div>
                  <div class="col-5">
                    <table width="100%">
                      <tr>
                        <th colspan="2">APPARTENENTI AL GRUPPO</th>
                      </tr>
                      <div *ngFor="let i of usersByGroup">
                        <tr *ngIf="i?.permissions?.fir_active">
                          <td style="width:100%">{{i.Nome}}
                            <br>
                            <!--
                            <star-rating [starType]="'svg'" [rating]="i.rating" (starClickChange)="onRate($event,i)" ></star-rating>
                          
                           
                            <star-rating [value]="i.rating" checkedcolor="red" uncheckedcolor="black" size="24px"
                              (rate)="onRate($event,i)" totalstars="5" readonly="false"></star-rating>

                            -->
                          </td>
                          <td width="1"><button color="danger" mat-raised-button
                              (click)="toggleActive(i)">Disattiva</button></td>
                        </tr>
                      </div>
                    </table>
                  </div>
                </div>


              </mat-tab>

            </mat-tab-group>


          </div>
        </div>


      </div>
    </div>
  </div>
</div>