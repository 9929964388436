<div class="card">

    <div class="card-header card-header-info">
      <h4 class="card-title">
        Modifica Imponibile
        <i style="float:right" color="danger" (click)="close()" class="material-icons">close</i>
      </h4>
    </div>

    <br>
    <br>

    <form [formGroup]="form">
      <mat-form-field class="full">
        <mat-label>Osservazioni</mat-label>
        <input formControlName="osservazioni" type="text" matInput placeholder="Osservazioni"  [(ngModel)]="data.adzData.osservazioni">
      </mat-form-field>

      <mat-form-field class="full">
        <mat-label>Imponibile</mat-label>
        <input formControlName="imponibile" type="number" matInput placeholder="Nome"  [(ngModel)]="data.adzData.imponibile">
      </mat-form-field>

      <div class="row">
          <div class="col">
              <button class="btn btn-danger" type="button" mat-raised-button (click)="close()" style="float:right">Annulla</button>
              <button class="btn btn-success" type="button" mat-raised-button (click)="ok()" style="float:right; margin-right:15px">Salva</button> 
          </div>
      </div>
    </form>
  </div>