<div class="all">

  <div class="sticky">

    <div class="full primary">
      <div class="cnt ">
        <table width=100%>
          <tr>
            <td class="center"> <img class="logo" [src]="logo"> </td>
            <td class="bottom right big">Report&nbsp;tecnico</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="full white">
      <div class="cnt ">

        <table width=100%>
          <tr>
            <td class="left">TARGA:&nbsp;{{item?.targa}}</td>
            <td class="right">TELAIO:&nbsp;{{item?.telaio}}</td>
          </tr>
        </table>

      </div>
    </div>

    <div class="full black">
      <div class="cnt ">

        <table width=100%>

          <tr>
            <td width="33%" class="left">DATA ISPEZIONE:<br>{{dta|date: 'dd/MM/yyyy'}}</td>
            <td width="33%" class="center">LUOGO ISPEZIONE:<br>{{item?.citta}}</td>

            <td width="33%" class="right">
              <img style="width:64px; margin-right:20px; padding: 0" *ngIf="result?.weather?.weather[0]"
                [src]="'http://openweathermap.org/img/w/'+ result?.weather?.weather[0]?.icon +'.png'">

            </td>
          </tr>
        </table>
      </div>
    </div>

  </div>

  <div class="cnt nopadding toppadding">

    <table>
      <tr class="gray">

        <td class="center" width=50%>
          <img class="rounded50 " [src]="result?.fotoVetrina[7]?.url" width=100%>
        </td>

        <td class="center top">

          <h3>Dettagli Veicolo</h3>

          <table width=100% style="margin:20px" class="data_table">
            <tr>
              <td class="left padding">MARCA:</td>
              <td class="left padding">{{item?.marcaDescr}}</td>
            </tr>
            <tr>
              <td class="left padding">MODELLO:</td>
              <td class="left padding">{{item?.modDescr}}</td>
            </tr>
            <tr>
              <td class="left padding">IMMATR.:</td>
              <td class="left padding">{{item?.annoImmatricolazione}}</td>
            </tr>
            <tr>
              <td class="left padding">VERSIONE:</td>
              <td class="left padding">{{item?.verDescr}}</td>
            </tr>
            <tr>
              <td class="left padding">KM:</td>
              <td class="left padding">{{item?.km}}</td>
            </tr>
            <tr>
              <td class="left padding">N° PERIZIA:</td>
              <td class="left padding">{{item?.nPerizia}}</td>
            </tr>
          </table>

        </td>
      </tr>

      <tr>
        <td colspan=2>
          <h2>Panoramica veicolo</h2>
          <img class="separator center" src="/assets/img/separator.png">


          <div class="row" style="margin:auto;">

            <div class="col-3" *ngFor="let f of result?.fotoVetrina">
              <img class="rounded20" [src]="f.url">
            </div>

          </div>
<!--
          <br>

          <div class="row" style="margin:auto;">

            <div class="col-4">
              <img class="circled" [src]="result?.altriElementi[7].url">
              CRUSCOTTO
            </div>

            <div class="col-4">
              <img class="circled" [src]="result?.altriElementi[8].url">
              INDICATORI
            </div>

            <div class="col-4">
              <img class="circled" [src]="result?.altriElementi[9].url">
              SEDILI
            </div>

          </div>
-->
        </td>
      </tr>


      <tr>
        <td colspan=2>
          <h2>Ruote</h2>
          <img class="separator center" src="/assets/img/separator.png">

          <app-tires class="r80" *ngIf="result" [(value)]="result.tiresData">
          </app-tires>

        </td>
      </tr>



      <tr>
        <td colspan=2>

          <h2>Danni</h2>
          <img class="separator center" src="/assets/img/separator.png">


          <div class="row">

            <div class="col-6" *ngFor="let d of result?.damages">

              <table>
                <tr>
                  <td><img [src]="d.img1.url"></td>
                  <td><img [src]="d.img2.url"></td>
                </tr>
                <tr>
                  <td class="left small" colspan=2>

                    <table class="full">
                      <tr>
                        <td colspan=2>Descrizione danno:</td>
                        <td colspan=2>{{d?.part?.description}}</td>
                      </tr>
                      <tr>
                        <td colspan=2>Tipo:</td>
                        <td colspan=2>{{d?.type?.description}}</td>
                      </tr>
                      <tr>
                        <td>Intensità:</td>
                        <td>{{d?.severity |severity}}</td>
                        <td>Costo:</td>
                        <td>{{d?.cost | number: '.2-2'}} €</td>
                      </tr>
                    </table>



                  </td>
                </tr>

              </table>

            </div>

          </div>

          <div class="row">
            <div class="col-12">

              <br>
              <div class="tot_danni">TOTALE DANNI: {{tot_danni| number: '.2-2'}} €</div>
            </div>
          </div>

        </td>

      </tr>



    </table>

    <br>
    <br>
    <!--
    <img class="footer" src="/assets/img/footer.jpg">
-->

  </div>
</div>