<table style="width:100%">
    <tbody>

        <tr>
            <td style="text-align: center">
                <app-tire (onChange)="changed($event)" [(tire)]="value?.tires[0]" title="Anteriore SX" (onOpenViewer)="openViewer($event)"></app-tire><br>
                <!--<ion-button mat-raised-button (click)="copy()">Copia sulle altre ruote</ion-button>-->
            </td>
            <td rowspan="3">
                <img src="./assets/img/tires-car.png" style="width:90%; display:block; margin:auto;">
            </td>
            <td style="text-align: center">
                <app-tire (onChange)="changed($event)" [(tire)]="value?.tires[1]" title="Anteriore DX" (onOpenViewer)="openViewer($event)"></app-tire><br>
                <!--<ion-button mat-raised-button style="visibility:hidden">Copia sulle altre ruote</ion-button>-->
            </td>
        </tr>
        <tr>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td style="text-align: center">
                <app-tire (onChange)="changed($event)" [(tire)]="value?.tires[2]" title="Posteriore SX" (onOpenViewer)="openViewer($event)"></app-tire>
            </td>
            <td style="text-align: center">
                <app-tire (onChange)="changed($event)" [(tire)]="value?.tires[3]" title="Posteriore DX" (onOpenViewer)="openViewer($event)"></app-tire>
            </td>
        </tr>
        <tr *ngIf="true || spare">
            <td></td>
            <td style="text-align: center">
                <app-tire (onChange)="changed($event)" [hidden]="value?.spareTireNotPreset"  [(tire)]="value?.tires[4]" title="Ruota di scorta" (onOpenViewer)="openViewer($event)">
                </app-tire>
                <!--<ion-checkbox class="input-top" color="primary" [(ngModel)]="value.spareTireNotPreset">Ruota di scorta
                    non presente</ion-checkbox>-->
            </td>
            <td></td>
        </tr>
    </tbody>
</table>