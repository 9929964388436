import { AdminRouteGuard, LoginRouteGuard, LoginFirRouteGuard } from './route-guard';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ReportComponent } from './report/report.component';
import { VideochatExtComponent } from './videochat-ext/videochat-ext.component';
//import { EShopLayoutComponent } from './layouts/eshop-layout/eshop-layout.component';
import { FirComponent } from './menu/schedule/fir/fir.component';
import { FirDialogComponent } from './menu/schedule/fir/dialog/dialog.component';
import { PropostaComponent } from './proposta/proposta.component';
import { ChimesComponent } from './menu/schedule/chimes/chimes.component';
import { ProgramComponent } from './menu/demo/program/program.component';
import { VideochatExtChimeComponent } from './videochat-ext-chime/videochat-ext-chime.component';
import { PublicDetailsComponent } from './public-details/public-details.component';
import { FirCertDialogComponent } from './menu/schedule/fir/dialog_cert/dialog.component';

const routes: Routes = [
  { path: '',
    redirectTo: 'automotive/dashboard',
    pathMatch: 'full' },


  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      }]
  },
/*
  {
    path: '',
    component: EShopLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/eshop-layout/eshop-layout.module').then(m => m.EShopLayoutModule)
      }]
  },

*/

  {
    path: 'ext/fir', component: FirComponent,
    canActivate: [LoginFirRouteGuard]
  },
  {
    path: 'ext/fir/dettaglio/:id', component: FirDialogComponent,
    canActivate: [LoginFirRouteGuard]
  },

  {
    path: 'ext/fir/cert/:id', component: FirCertDialogComponent,
    canActivate: [LoginFirRouteGuard]
  },

  {
    path: 'report/:type/:id',
    component: ReportComponent,
    children: [
      {
        path: 'report/:type/:id',
        loadChildren: () => import('./layouts/flat-layout/flat-layout.module').then(m => m.FlatLayoutModule)
      }]
  },
  {
    path: 'pub/:id/:hash',
    component: PublicDetailsComponent,
    children: [
      {
        path: 'pub/:id/:hash',
        loadChildren: () => import('./layouts/flat-layout/flat-layout.module').then(m => m.FlatLayoutModule)
      }]
  },

  {
    path: 'videochat-ext/:id',
    component: VideochatExtComponent,
    children: [
      {
        path: 'videochat-ext/:id',
        loadChildren: () => import('./layouts/flat-layout/flat-layout.module').then(m => m.FlatLayoutModule)
      }]
  },

  {
    path: 'videochat-ext-chime/:id',
    component: VideochatExtChimeComponent,
    children: [
      {
        path: 'videochat-ext-chime/:id',
        loadChildren: () => import('./layouts/flat-layout/flat-layout.module').then(m => m.FlatLayoutModule)
      }]
  },



  {
    path: 'chime',
    component: ChimesComponent,
    children: [
      {
        path: 'chime',
        loadChildren: () => import('./layouts/flat-layout/flat-layout.module').then(m => m.FlatLayoutModule)
      }]
  },

  {
    path: 'chime/:meetingId',
    component: ChimesComponent,
    children: [
      {
        path: 'chime/:id',
        loadChildren: () => import('./layouts/flat-layout/flat-layout.module').then(m => m.FlatLayoutModule)
      }]
  },
  {
    path: 'chime/:meetingId/:attendeeId',
    component: ChimesComponent,
    children: [
      {
        path: 'chime/:id',
        loadChildren: () => import('./layouts/flat-layout/flat-layout.module').then(m => m.FlatLayoutModule)
      }]
  },




  {
    path: 'proposta/:id/:hash',
    component: PropostaComponent,
    children: [
      {
        path: 'proposta/:id/:hash',
        loadChildren: () => import('./layouts/flat-layout/flat-layout.module').then(m => m.FlatLayoutModule)
      }]
  },

  // { path: 'table-list',     component: TableListComponent },
  // { path: 'typography',     component: TypographyComponent },
  // { path: 'icons',          component: IconsComponent },
  // { path: 'maps',           component: MapsComponent },
  // { path: 'notifications',  component: NotificationsComponent },
  // { path: 'upgrade',        component: UpgradeComponent },
  // { path: '',               redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
