import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Inject } from '@angular/core';
import { QuestionDialogComponent } from './dialog/dialog.component'
import { MysqlService } from 'app/services/mysql.service';
import { UserService } from 'app/services/user.service';
import { asBlob } from 'html-docx-js-typescript'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';

import * as moment from 'moment-timezone';

import { saveAs } from "file-saver"

import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  AudioVideoObserver,
  VideoTile,
  DefaultVideoTile,
  VideoTileState,
  DefaultActiveSpeakerPolicy,
} from 'amazon-chime-sdk-js';
import { copyText } from 'app/shared';
import { Platform } from '@angular/cdk/platform';



@Component({
  selector: 'app-chimes',
  templateUrl: './chimes.component.html',
  styleUrls: ['./chimes.component.scss']
})
export class ChimesComponent implements OnInit, OnDestroy {


  @ViewChild('myEditor') myEditor: any;

  @ViewChild('meetingAudio') public meetingAudio: ElementRef<HTMLAudioElement>;
  @ViewChild('meetingVideo') public meetingVideo: ElementRef<HTMLVideoElement>;
  //@ViewChild('meetingVideo') public meetingVideo: ElementRef<HTMLVideoElement>;

  videoDev: any

  description = '';
  questions
  activeSpeakers = []
  notifications = true
  editor_config: any = {
    removePlugins: ['EasyImage', 'Image', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed', 'toolbar'],
  }

  novideo


  public Editor = ClassicEditor;
  showEditor = false
  dataWrite
  videos = []

  showMeetingVideo = false

  names = []

  meeting
  meetingId
  attendee
  attendeeId

  logger: ConsoleLogger
  deviceController: DefaultDeviceController
  configuration: MeetingSessionConfiguration
  meetingResponse
  attendeeResponse
  meetingSession: DefaultMeetingSession

  isModerator = false

  isRecording = false

  msg = '';

  doc_data = '';

  bw_down = 0;
  bw_up = 0;

  allegati
  reduced = false
  constructor(
    public dialog: MatDialog,
    private db: MysqlService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private platform: Platform
  ) {

    this.audio = new Audio();
    this.audio.src = "../../../../../assets/sounds/ping.mp3";
    this.audio.load();

    console.log('constructor')

    for (let i = 1; i <= 100; i++)
      this.videos.push({ id: i, visible: false, isMuted: true })

    if (this.platform.ANDROID || this.platform.IOS) {
      this.reduced = true
    }

    this.route.params.subscribe(params => {

      console.log(params)

      if (params['meetingId'])
        this.meetingId = params['meetingId'];


      if (params['attendeeId'])
        this.attendeeId = params['attendeeId'];

      setTimeout(() => {
        this.start()
      }, 1000);

    })

  }

  async stopLocalVideo() {
    await this.setVideo(false)


    let v = this.videos.find(e => e.attendeeId == this.getAttendeeId())

    this.unbindVideo(v.tileState.tileId)

    this.meetingSession.audioVideo.stopLocalVideoTile()
    this.novideo = true
    this.meetingSession.audioVideo.start()
    /** 
    console.log('STOP STOP STOP STOP')
    this.meetingSession.audioVideo.stop()

    setTimeout(async () => {

      console.log('START START START START')
      this.initMeeting()
      await this.setVideo(!this.novideo)
      this.meetingSession.audioVideo.start()
      this.setEventListeners()
      console.log('ebnd')
    }, 100);*/
  }

  async startLocalVideo() {
    await this.setVideo(true)
    this.meetingSession.audioVideo.startLocalVideoTile()
    this.novideo = false
    this.meetingSession.audioVideo.start()
  }


  // recorder: any
  stream: MediaStream

  async startCapture() {
    /** 
    console.log('startCapture')
    let captureStream = null;
    let a

    const sUsrAg = navigator.userAgent; // firefox fix
    if (sUsrAg.indexOf('Firefox') > -1) {
      a = await ((document.getElementById('meeting-audio') as any).mozCaptureStream() as MediaStream)
    }
    else {
      a = await ((document.getElementById('meeting-audio') as any).captureStream() as MediaStream)
    }


    let la = await (navigator.mediaDevices as any).getUserMedia({ audio: true })
    //let v = ((document.getElementById('content-share-video') as any).captureStream() as MediaStream)

    let v = await (navigator.mediaDevices as any).getDisplayMedia(
      {
        audio: true, video: true
      }
    )
    //navigator.getDisplayMedia()


    this.stream = this.mixer(a, v, la)


    let options: MediaRecorderOptions = { mimeType: '', bitsPerSecond: 25000 }

    if (MediaRecorder.isTypeSupported) {
      options = { mimeType: "video/webm;codecs=vp9" };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.warn(`${options.mimeType} is not Supported`);
        options = { mimeType: "video/webm;codecs=vp8,opus" };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.warn(`${options.mimeType} is not Supported`);
          options = { mimeType: "video/webm" };
          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.error(`${options.mimeType} is not Supported`);
            options = { mimeType: "" };
          }
        }
      }
    } else {
      options = { mimeType: "" };
    }

    console.log(options)

    this.recorder = new MediaRecorder(this.stream, options);

    const chunks = [];
    this.recorder.ondataavailable = e => chunks.push(e.data);
    this.recorder.onstop = e => {
      const completeBlob = new Blob(chunks, { type: chunks[0].type });
      console.log(completeBlob)

      this.stream.getVideoTracks().forEach(t => t.stop())
      this.isRecording = false

      let file = new File([completeBlob], 'record.webm', { type: "video/webm" });
      saveAs(file)
      //video.src = URL.createObjectURL(completeBlob);
    };

    this.recorder.start();
    this.isRecording = true
*/
  }

  stopCapture() {
    console.log('stopCapture')
    // this.recorder.stop();
  }
  withoutCards = false
  isFullscreen = false
  togglefullscreen(state) {
    console.log('toggleFullscreen')
    if (state) {
      this.withoutCards = true
    } else {
      if (this.withoutCards)
        this.withoutCards = false
      else
        this.isFullscreen = !this.isFullscreen
    }


  }

  async shareContent() {
    console.log('shareContent')
    await this.meetingSession.audioVideo.startContentShareFromScreenCapture();
  }

  copyUrl() {
    console.log('copyUrl')
    let url = window.location.href.split('/').slice(0, 3).join('/') + '/#/chime/' + this.meeting.Meeting.MeetingId
    copyText(url)

    alert("Indirizzo copiato!")
  }

  mixer(stream1, stream2, stream3) {
    console.log('mixer')
    const ctx = new AudioContext();
    const dest = ctx.createMediaStreamDestination();

    if (stream1.getAudioTracks().length > 0)
      ctx.createMediaStreamSource(stream1).connect(dest);

    if (stream2.getAudioTracks().length > 0)
      ctx.createMediaStreamSource(stream2).connect(dest);

    if (stream3.getAudioTracks().length > 0)
      ctx.createMediaStreamSource(stream3).connect(dest);

    let tracks = dest.stream.getTracks();
    tracks = tracks.concat(stream1.getVideoTracks()).concat(stream2.getVideoTracks());

    return new MediaStream(tracks)
  }

  sendUrlByEmail() {
    console.log('sendUrlByEmail')
    let rcpt = prompt('Inserisci email del destinario')

    if (!rcpt) return

    let url = window.location.href.split('/').slice(0, 3).join('/') + '/#/chime/' + this.meeting.Meeting.MeetingId

    let text = `Sei stato invitato ad un videomeeting, clicca il link per inizare\n\n${url}`

    let html = `Sei stato invitato ad un videomeeting, <a href="${url}">clicca qui</a> per inizare`

    let msg = {

      recipientEmail: rcpt,
      senderName: 'Xpert',
      subject: 'Invito videomeeting',
      text: text,
      html: html,
      attachments: [],
      pec: 0,
      bcc: '', cc: ''
    }

    this.db.send_mail(msg).then(res => alert('Messaggio inviato'))
  }

  openOther() {
    console.log('openOther')
    window.open(window.location.href.split('/').slice(0, 3).join('/') + '/#/chime/' + this.meeting.Meeting.MeetingId)
  }

  ngOnInit() {
    console.log('ngOnInit')

    //this.Editor.builtinPlugins.map(plugin => console.log(plugin.pluginName));
  }

  getAllegati() {
    console.log('getAllegati')

    this.db.aws_chime_get_allegati(this.meetingId).then(res => {
      this.allegati = undefined
      setTimeout(() => {
        this.allegati = res.allegati
      }, 10);
    })
  }

  audioInputDevs
  audioOutputDevs
  videoDevs

  async start() {
    console.log('start')

    if (this.meetingId) {

      console.log('get meeting id from param')
      this.meeting = await this.db.aws_chime_get_meeting(this.meetingId)

      if (this.meeting.status == 'ERROR') {
        alert('Meeting chiuso o scaduto')
        window.close()
        return
      }

    } else {

      console.log('get meeting id from api')
      this.meeting = await this.db.aws_chime_create_meeting()
      this.router.navigate(['/chime/' + this.meeting.Meeting.MeetingId])
      this.isModerator = true

      return

    }


    if (this.attendeeId) {

      console.log('get attendee id from param')
      this.attendee = await this.db.aws_chime_get_attendee(this.meetingId, this.attendeeId)

    } else {

      console.log('get meeting id from api')
      this.attendee = await this.db.aws_chime_create_attendee(this.meetingId)
      this.router.navigate(['/chime/' + this.meeting.Meeting.MeetingId + '/' + this.attendee.Attendee.AttendeeId])
      return

    }

    //console.clear()
    console.log('meeting', this.meeting)
    console.log('attendee', this.attendee)

    this.showEditor = true;


    // You need responses from server-side Chime API. See below for details.
    this.meetingResponse = this.meeting;
    this.attendeeResponse = this.attendee;

    await this.initMeeting()



    this.meetingSession.audioVideo.start() /// importantissimo, senza questo non funziona un cazzo




    this.setEventListeners()



    await this.getCreator()

    this.getAllegati()
    this.loadQuestions()
    setTimeout(() => {
      if (!this.isModerator) {

        this.meetingSession.audioVideo.realtimeMuteLocalAudio()
        this.meetingSession.audioVideo.realtimeSetCanUnmuteLocalAudio(false)
        this.sendMessage({ attendeeId: 'chatroom' }, 'isMuted', true)

        //this.meetingSession.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio
      } else {

        this.sendMessage({ attendeeId: 'chatroom' }, 'isMuted', false)

      }
    }, 200);



    setInterval(() => {
      this.sendMessage({ attendeeId: 'chatroom' }, 'isMuted', this.meetingSession.audioVideo.realtimeIsLocalAudioMuted())
    }, 1000)


    if (!this.username) await this.db.get_current_user_profile().then(usr => {
      console.log(usr)
      try {
        this.username = usr[0].Nome + ' ' + usr[0].Cognome
      } catch (error) {

      }
    }).catch(() => { })



    if (!this.username) this.username = prompt('Inserisci il tuo nome')
    if (!this.username) this.username = 'Non definito'

    let email = ''

    try {
      email = this.userService.getEmail()
    } catch (error) {

    }

    let tmp = { meetingId: this.meetingId, attendeeId: this.attendeeId, email: email, name: this.username }
    console.log('tmp', tmp)
    this.db.aws_chime_attendee_name_upsert(tmp)
      .then(res => { console.log(res) })
      .catch(err => console.error(err))


    console.log('__getNameList')
    this.getNameList()
  }

  async initMeeting() {

    this.logger = new ConsoleLogger('MyLogger', LogLevel.ERROR);
    this.deviceController = new DefaultDeviceController(this.logger, { enableWebAudio: true });


    this.configuration = new MeetingSessionConfiguration(this.meetingResponse, this.attendeeResponse);

    // In the usage examples below, you will use this meetingSession object.
    this.meetingSession = new DefaultMeetingSession(
      this.configuration,
      this.logger,
      this.deviceController
    );



    //this.meetingSession.audioVideo.enableWebAudio(true)

    this.getDoc()
    //this.getCreator()


    ///set audio IN


    //this.sendMessage(undefined, 'chat', '/set audio IN')
    let xx = await navigator.mediaDevices.enumerateDevices()
    //this.sendMessage(undefined, 'chat', JSON.stringify(xx))
    xx = await navigator.mediaDevices.enumerateDevices()
    //this.sendMessage(undefined, 'chat', JSON.stringify(xx))

    try {
      const audioInputDevice = await this.meetingSession.audioVideo.listAudioInputDevices()

      this.audioInputDevs = audioInputDevice

      //this.sendMessage(undefined, 'chat', JSON.stringify(audioInputDevice))

      //   alert('audioInputDevice ' + JSON.stringify(audioInputDevice[0].deviceId))





      console.log('audioInputDevice', audioInputDevice)
      if (!audioInputDevice || audioInputDevice.length <= 0 || audioInputDevice[0].deviceId == '') alert('audioInputDevice non disponibile')
      //this.sendMessage(undefined, 'chat', JSON.stringify(audioInputDevice[0] ? audioInputDevice[0] : null))
      let dev
      if (localStorage != undefined)
        dev = localStorage.getItem('awsc_audioIn')
      if (dev) dev = JSON.parse(dev)

      //alert(JSON.stringify(dev ? dev : audioInputDevice[0] ? audioInputDevice[0] : null))
      //let x = await this.meetingSession.audioVideo.chooseAudioInputDevice(audioInputDevice[0].deviceId ? audioInputDevice[0].deviceId : null)
      let x = await this.meetingSession.audioVideo.chooseAudioInputDevice(audioInputDevice[0].deviceId)
      //this.sendMessage(undefined, 'chat', JSON.stringify(x))
      //  alert(x)
      //   .then(res => alert('then ' + JSON.stringify(res)))
      //  .catch(res => alert('catch ' + JSON.stringify(res)))


    } catch (error) {
      alert('set audio IN ' + JSON.stringify(error))
    }


    /// set audio OUT

    try {
      let audioOutputDevice
      await this.meetingSession.audioVideo.listAudioOutputDevices()
        .then(res => audioOutputDevice = res)
        .catch(err => {
          alert(JSON.stringify(err))
          console.error(err)
        })
      console.log('audioOutputDevice', audioOutputDevice)


      this.audioOutputDevs = audioOutputDevice

      // alert('audioOutputDevice ' + JSON.stringify(audioOutputDevice))

      let audioOutDeviceId = audioOutputDevice[0] ? audioOutputDevice[0].deviceId : null

      if (!audioOutDeviceId) {
        let tmp
        await navigator.mediaDevices.getUserMedia({ audio: true }).then(res => tmp = res).catch(err => alert(JSON.stringify(err)))
        audioOutDeviceId = tmp.id
        console.warn('Im getting the output device id as walkaround' + audioOutDeviceId)

        //alert('Im getting the output device id as walkaround' + audioOutDeviceId)

      }
      if (!audioOutDeviceId) alert('audioOutputDevice non disponibile')

      let dev: any
      if (localStorage != undefined)
        dev = localStorage.getItem('awsc_audioOut')
      if (dev) dev = JSON.parse(dev)

      //alert(JSON.stringify(dev ? dev : audioOutDeviceId))

      await this.meetingSession.audioVideo.chooseAudioOutputDevice(dev ? dev.deviceId : audioOutDeviceId).then(res => { }).catch(err => alert('catch ' + JSON.stringify(err)))

      /*
            setInterval(() => {
              let anal: AnalyserNode = this.meetingSession.audioVideo.createAnalyserNodeForAudioInput()
              console.log('maxdb:' + anal.maxDecibels)
            }, 1000)
      */
    } catch (error) {
      alert('set video ' + JSON.stringify(error))
    }




    await this.setVideo(!this.novideo)

    //this.meetingSession.audioVideo.createAnalyserNodeForAudioInput()


    //alert('audio el' + JSON.stringify(this.meetingAudio))


    let b
    this.meetingSession.audioVideo.bindAudioElement(this.meetingAudio.nativeElement).then(res => {
      b = res
    })

    if (this.platform.SAFARI)
      alert("Vidochiamata pronta, premere ok per continuare")
  }


  async setVideo(video: boolean) {
    /// set video
    this.novideo = !video //!confirm("Vuoi condividere il video?")

    //if (!this.novideo)

    if (video)
      try {
        const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices()

        this.videoDevs = videoInputDevices

        console.log('videoInputDevices', videoInputDevices)
        if (!videoInputDevices || videoInputDevices.length <= 0) alert('videoInputDevices non disponibile')
        await this.meetingSession.audioVideo.chooseVideoInputQuality(720, 480, 12, 300);
        if (localStorage != undefined)
          this.videoDev = localStorage.getItem('awsc_videoDev')
        if (this.videoDev) this.videoDev = JSON.parse(this.videoDev)

        if (this.videoDev && this.videoDev.deviceId == 'none') {
          this.videoDev = null
          this.novideo = true
        } else if (!this.videoDev)
          this.videoDev = videoInputDevices[0]

        await this.meetingSession.audioVideo.chooseVideoInputDevice(this.videoDev)
      } catch (error) {
        //alert('set video ' + JSON.stringify(error))
      }
  }

  logVideo() {
    console.log(this.videos)
    console.log(this.names)
  }

  setEventListeners() {

    const observer = {


      videoTileDidUpdate: tileState => {

        //console.log(this.meetingSession.audioVideo.getAllVideoTiles())

        //console.log('videoTileDidUpdate', tileState)

        // Ignore a tile without attendee ID and other attendee's tile.

        if (!tileState.boundAttendeeId) {
          return;
        }


        //console.log('test', tileState)

        let n = this.videos.findIndex(e => e.attendeeId == tileState.boundAttendeeId)
        //console.log('test', n)

        if (n > 16) {

          this.videos[n] = { id: n + 1, visible: false, isMuted: true }

        }



        this.bindVideo(tileState)

        //console.warn(this.videos)

      },
      videoTileWasRemoved: tileId => {
        //console.clear()
        console.log('videoTileWasRemoved')
        console.log('videoTileWasRemoved ', tileId)

        let e = this.videos.find(e => e.tileState != undefined && e.tileState.tileId == tileId)


        console.log('videoTileWasRemoved ', e)
        if (!e || e.attendeeId == this.fixedSpeaker) {
          this.fixedSpeaker = ''
        }


        console.log('videoTileWasRemoved ', this.fixedSpeaker)

        this.unbindVideo(tileId)
      },




      connectionDidBecomePoor: () => console.warn('connectionDidBecomePoor'),
      connectionDidSuggestStopVideo: () => console.warn('connectionDidSuggestStopVideo'),
      // connectionHealthDidChange: (res) => console.warn('connectionHealthDidChange', res),
      estimatedDownlinkBandwidthLessThanRequired: (n1, n2) => {
        console.warn('estimatedDownlinkBandwidthLessThanRequired', n1, n2)
        this.setTriangle(this.getAttendeeId())
      },
      //metricsDidReceive: clientMetricReport => console.warn('clientMetricReport', clientMetricReport),
      videoNotReceivingEnoughData: res => {
        console.warn('videoNotReceivingEnoughData', res)
        this.setTriangle(res[0].attendeeId)
      },
      videoReceiveBandwidthDidChange: (bold, bnew) => { this.bw_down = bnew },
      videoSendBandwidthDidChange: (bold, bnew) => { this.bw_up = bnew },
      //videoSendHealthDidChange: (n1, n2) => console.warn('videoSendHealthDidChange', n1, n2),


      contentShareDidStart: () => {
        console.log('Screen share started');
      },
      contentShareDidStop: () => {
        // Chime SDK allows 2 simultaneous content shares per meeting.
        // This method will be invoked if two attendees are already sharing content
        // when you call startContentShareFromScreenCapture or startContentShare.
        console.log('Screen share stopped');
      }


    };

    this.meetingSession.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(muted => {
      console.log('realtimeSubscribeToMuteAndUnmuteLocalAudio', muted)

      this.sendMessage({ attendeeId: 'chatroom' }, 'isMuted', muted)

    })


    /// chatroom
    this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage('chatroom', dataMessage => {
      this.manageMessages(dataMessage)
    })

    /// privatechat
    this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage(this.getAttendeeId(), dataMessage => {
      this.manageMessages(dataMessage)
    })

    /// chatroom
    this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage('reservations', dataMessage => {
      console.log(dataMessage)

      let b = dataMessage.text() === 'true'

      console.log('reserved', dataMessage)

      try {
        let v = this.videos.find(e => e.attendeeId == dataMessage.senderAttendeeId)
        v.reserved = b
        if (b) v.reservedAt = dataMessage.timestampMs
        else v.reservedAt = undefined


        this.videos.forEach(e => e.reservationNumber = this.getReservationNumber(e))

      } catch (error) { }

      if (b) {
        if (this.notifications)
          this.audio.play()
      }
      dataMessage.senderAttendeeId


    })


    //alert('subscribeToActiveSpeakerDetector')
    this.meetingSession.audioVideo.subscribeToActiveSpeakerDetector(
      new DefaultActiveSpeakerPolicy(),
      (activeSpeakers) => {
        //console.log(activeSpeakers)

        //alert('subscribeToActiveSpeakerDetector -> callback')

        this.activeSpeakers = activeSpeakers


        this.setActiveSpeaker(activeSpeakers)

      }
    );

    this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId, present) => {

      console.log('realtimeSubscribeToAttendeeIdPresence', presentAttendeeId)

      if (present) {

        setTimeout(() => {
          if (this.notifications)
            this.audio.play()
        }, 2000);

        this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, (attendeeId, volume) => {
          try {

            let el = this.videos.find(e => e.attendeeId == attendeeId)

            //console.log('presence el ', el)

            if (!el) {

              let n = 0
              for (let i = 17; i < this.videos.length; i++) {
                if (!this.videos[i].visible) {
                  n = i
                  break
                }
              }


              //console.error('HERE', el, n)

              //let nvideo = this.meetingSession.audioVideo.getAllVideoTiles().findIndex((e: any) => e.tileState.boundAttendeeId == attendeeId)

              //console.log(this.meetingSession.audioVideo.getAllVideoTiles())

              // console.log('presence n ', n)


              this.videos[n] = { id: n + 1, visible: false, isMuted: true }
              this.videos[n].visible = true
              this.videos[n].attendeeId = attendeeId
              this.videos[n].tileState = { tileId: n }
              this.videos[n].volume = volume
            }




          } catch (error) {

            console.error(error)

          }
        })
      } else {

        try {


          console.log('disconnected: ' + presentAttendeeId)
          this.meetingSession.audioVideo.realtimeUnsubscribeFromVolumeIndicator(presentAttendeeId)

          let n = this.videos.findIndex(e => e.attendeeId == presentAttendeeId)

          if (n == -1) {
            console.log(this.videos, n, presentAttendeeId)
            return
          }


          console.log('disconnected: ' + n)

          this.videos[n] = { id: n + 1, visible: false, isMuted: true }



        } catch (error) {

          console.error(error)



        }


      }

      setTimeout(() => {
        console.log('setActiveSpeaker')

        this.setActiveSpeaker()
      }, 100);

    });


    this.meetingSession.audioVideo.addContentShareObserver(observer);
    this.meetingSession.audioVideo.addObserver(observer)
    if (!this.novideo) this.meetingSession.audioVideo.startLocalVideoTile()

  }

  async restart() {

    console.log('STOP STOP STOP STOP')
    this.meetingSession.audioVideo.stop()

    setTimeout(async () => {

      console.log('START START START START')
      this.initMeeting()
      await this.setVideo(!this.novideo)
      this.meetingSession.audioVideo.start()
      this.setEventListeners()

    }, 100);

  }



  setTriangle(attendeeId) {
    console.log(this.videos)
    this.videos.filter(e => e.attendeeId == attendeeId)[0].warning = true
    setTimeout(() => {
      this.videos.filter(e => e.attendeeId == attendeeId)[0].warning = false
    }, 10000);
  }

  getNameList() {

    //console.log('getNameList')

    this.db.aws_chime_attendee_name_list(this.meetingId).then(res => {
      //console.clear()
      //console.log(res)

      if (this.videos.find(e => e.visible && !e.name)) {

        //console.log(res)

        this.names = res

        //console.log('names - ', this.names, this.videos)

        //  console.log(this.videos)

        this.names.forEach(element => {
          console.log(element)


          this.videos.forEach(e => {

            if (e.attendeeId && element.attendeeId && e.attendeeId.replace('#content', '') == element.attendeeId.replace('#content', ''))
              e.name = element.name


          })


        });


      } else {
        //console.log('skip names update ')
      }



      setTimeout(() => {
        //console.clear()
        //  console.log('getNameList')//
        this.getNameList()
      }, 5000);

    }).catch(err => {
      //console.clear()
      //console.error(err)

      setTimeout(() => {

        // console.log('getNameList')
        this.getNameList()
      }, 5000);

    })




  }


  silence(note) {
    this.notifications = note
  }

  editDescription() {
    console.log('editDescription')

    let s = prompt('Inserisci una descrizione', this.description)
    if (s) this.description = s;

    this.db.aws_chime_update_description(this.meetingSession.configuration.meetingId, this.description).then(res => {

      console.log(res)

      this.sendMessage({ attendeeId: 'chatroom' }, 'descrUpdated')
    }).catch(err => console.error(err))

  }

  fixVideo(x = undefined) {
    console.log('fixVideo')

    if (x) {
      this.fixedSpeaker = x.attendeeId
      this.bindVideo(x.tileState, true)
    } else {
      this.fixedSpeaker = undefined
    }


  }
  fixedSpeaker
  setActiveSpeaker(activeSpeakers = this.activeSpeakers) {

    //console.log('setActiveSpeaker')

    if (this.fixedSpeaker) return

    this.videos.forEach(e => { e.isSpeaking = activeSpeakers.filter(x => e.attendeeId == x).length > 0 })

    try {


      let speaker
      try {
        speaker = this.videos.find(e => {
          try {
            return e.tileState.isContent > 0
          } catch (error) {
            return false
          }
        })
      } catch (error) {
        console.error(error)
      }

      if (!speaker) {
        try {
          let id = activeSpeakers[0]
          speaker = this.videos.find(e => e.attendeeId == id)
        } catch (error) {
          return
        }
      }

      /// console.log(speaker)

      //alert(JSON.stringify(speaker))

      if (speaker.tileState.localTile && this.novideo) return // fix per local senza video

      let ts = speaker.tileState

      if (ts) {

        // console.log(ts)
        this.bindVideo(ts, true)
      }

    } catch (error) {

      //alert(JSON.stringify(error))

      //console.error(error)
    }

  }

  loading = false
  attachmentSave(event) {
    console.log('attachmentSave')

    //this.manageStatus(null)

    if (this.loading) return;

    this.allegati[this.allegati.length - 1].tag = this.getNameFromAttendeeId(this.getAttendeeId())

    this.loading = true;

    this.db.aws_chime_update_allegati(this.meetingId, this.allegati).then(res => {

      this.loading = false;

      this.sendMessage({ attendeeId: 'chatroom' }, 'allegatiUpdated')

    })

    //.catch(err => { this.loading = true; console.error(err) })

  }


  decodeResponseVal(val) {
    console.log('decodeResponseVal')
    switch (val) {
      case 'YES': return 'FAVOREVOLE'
      case 'NO': return 'CONTRARIO'
      case 'AST': return 'ASTENUTO'
    }
  }

  skip_editor_change_event = false;
  public onChange_editor({ editor }: ChangeEvent) {
    console.log('onChange_editor')

    if (!this.isModerator || this.skip_editor_change_event) return

    const data = editor.getData();
    this.dataWrite = editor.getData()
    console.log(data)

    this.db.aws_chime_update_doc(this.meetingSession.configuration.meetingId, data).then((res: any) => {

      if (res.status == 'OK') {

        this.sendMessage({ attendeeId: 'chatroom' }, 'docUpdated')

      }

    }).catch(err => console.error(err))
  }


  audio

  username;
  chatroom = []
  async manageMessages(dataMessage) {
    //console.log('manageMessages')

    let msg = JSON.parse(dataMessage.text())
    //console.log(msg)



    //console.log("I'm receiving a message - ", msg)

    switch (msg.type) {

      case 'allegatiUpdated':
        this.getAllegati()
        if (this.notifications)
          this.audio.play()
        break;

      case 'questionUpdated':
        this.loadQuestions()
        if (this.notifications)
          this.audio.play()
        break;

      case 'descrUpdated':
        this.getCreator()
        break;

      case 'docUpdated':
        this.getDoc()
        break;

      case 'answerUpdated':
      case 'quuestionUpdated':
        this.loadQuestions()
        if (this.notifications)
          this.audio.play()
        break;
      /*
            case 'getName':
      
              if (!this.username) await this.db.get_current_user_profile().then(usr => {
                console.log(usr)
                try {
                  this.username = usr[0].Nome + ' ' + usr[0].Cognome
                } catch (error) {
      
                }
              }).catch(() => { })
      
              if (!this.username) this.username = prompt('Inserisci il tuo nome')
              if (!this.username) this.username = 'Non definito'
      
              console.log('setName', this.username)
      
              this.sendMessage({ attendeeId: 'chatroom' }, 'setName', this.username)
      
              break;
      
            case 'setName':
      
              console.log('setName', msg)
              this.setName(dataMessage.senderAttendeeId, msg.msg)
              break;
      */
      case 'chat':
        console.log('adding message to chatroom')
        this.chatroom.push({
          senderAttendeeId: dataMessage.senderAttendeeId,
          msg: msg.msg,
          date: new Date()
        })
        if (this.notifications)
          this.audio.play()
        break;

      case 'setMute':
        let canMute1 = this.meetingSession.audioVideo.realtimeCanUnmuteLocalAudio()
        this.meetingSession.audioVideo.realtimeSetCanUnmuteLocalAudio(true)
        this.meetingSession.audioVideo.realtimeMuteLocalAudio()
        this.meetingSession.audioVideo.realtimeSetCanUnmuteLocalAudio(canMute1)
        break;

      case 'setUnmute':
        let canMute2 = this.meetingSession.audioVideo.realtimeCanUnmuteLocalAudio()
        this.meetingSession.audioVideo.realtimeSetCanUnmuteLocalAudio(true)
        this.meetingSession.audioVideo.realtimeUnmuteLocalAudio()
        this.meetingSession.audioVideo.realtimeSetCanUnmuteLocalAudio(canMute2)
        break;

      case 'isMuted':

        this.setIsMuted(dataMessage, msg)


        break;
    }
  }

  setIsMuted(dataMessage, msg) {
    //console.log('setIsMuted')
    try {
      this.videos.find(e => e.attendeeId == dataMessage.senderAttendeeId).isMuted = msg.msg
    } catch (error) {
      setTimeout(() => {
        this.setIsMuted(dataMessage, msg)
      }, 100);
    }
  }

  getCreator(): Promise<void> {
    console.log('getCreator')

    return new Promise(resolve => {
      this.db.aws_chime_get_creator(this.meetingSession.configuration.meetingId).then(c => {

        try {
          this.isModerator = c.creatoDa == this.userService.getEmail()
        } catch (error) {
          this.isModerator = false
        }

        this.description = c.description
        if (!this.isModerator) this.editor_config.toolbar = []//.push('toolbar')
        resolve()
      })
    })



  }

  checkIfMuted(x) {

    // x && x.tileState && x.tileState.localTile ? this.meetingSession.audioVideo.realtimeIsLocalAudioMuted() : x.isMuted

    //console.log('checkIfMuted')

    let localTile = true
    try {
      localTile = x.tileState.localTile
    } catch (error) {

    }

    if (localTile) return this.meetingSession.audioVideo.realtimeIsLocalAudioMuted()

    if (!localTile) return x.isMuted

    return false
  }

  getDoc() {
    console.log('getDoc')
    this.db.aws_chime_get_doc(this.meetingSession.configuration.meetingId).then((res: any) => {

      console.log(res)
      this.doc_data = res.doc
      this.skip_editor_change_event = true;
      this.myEditor.editorInstance.setData(this.doc_data);
      this.skip_editor_change_event = false;

    }).catch(err => console.error(err))
  }

  toggleMute(b: boolean = undefined) {
    console.log('toggleMute')

    if (b === undefined)
      b = !this.meetingSession.audioVideo.realtimeIsLocalAudioMuted()

    if (b) {
      this.meetingSession.audioVideo.realtimeMuteLocalAudio()
      console.log('mute')
    } else {
      console.log('unmute')
      this.meetingSession.audioVideo.realtimeUnmuteLocalAudio()
    }
  }

  sendMessage(x, type: string, msg = undefined) {
    //console.log('sendMessage')

    if (!this.meetingSession) return

    let useMsg = false;

    if (type == 'chat' && !msg) {
      msg = this.msg
      if (!msg || msg == '') return
      useMsg = true;
    }

    if (type == 'chat') {
      console.log('adding message to chatroom')
      this.chatroom.push({
        senderAttendeeId: this.getAttendeeId(),
        msg: msg,
        date: new Date()
      })
    }

    //console.log("I'm sending a message - ", { type: type, msg: msg })

    this.meetingSession.audioVideo.realtimeSendDataMessage(x ? x.attendeeId : 'chatroom', { type: type, msg: msg })

    if (useMsg) this.msg = ''
  }

  isReserved
  reserve() {
    console.log('reserve')
    this.isReserved = !this.isReserved
    this.meetingSession.audioVideo.realtimeSendDataMessage('reservations', this.isReserved)
  }


  bindVideo(tileState: VideoTileState, mainVideo = false) {
    //console.log('bindVideo')


    let el;
    if (!mainVideo) {

      let nvideo = this.videos.findIndex(e => e && e.tileState && tileState && e.tileState.tileId == tileState.tileId)

      if (nvideo < 0) {
        nvideo = this.videos.findIndex(e => e.visible == false)
      }

      nvideo++

      this.videos[nvideo - 1].visible = true
      this.videos[nvideo - 1].attendeeId = tileState.boundAttendeeId
      this.videos[nvideo - 1].tileState = tileState

      el = document.getElementById('video-' + nvideo)
      if (!el) return
      this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, el as HTMLVideoElement)
    }
    else {

      //alert('binding main video')

      try {

        this.showMeetingVideo = true;
        el = document.getElementById('content-share-video')
        if (!el) return
        this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, el as HTMLVideoElement)

      } catch (error) {
        console.error(error)
        //alert(JSON.stringify(error))

      }


    }

  }

  unbindVideo(tileId) {
    console.log('unbindVideo')
    let nvideo
    try {
      console.log('unbinding video -> tileId:' + tileId)

      nvideo = this.videos.findIndex(e => e.tileState != undefined && e.tileState.tileId == tileId)

      console.log(this.videos, nvideo)

      this.meetingSession.audioVideo.unbindVideoElement(tileId)

      this.videos[nvideo] = { id: nvideo + 1, visible: false, isMuted: true }

    } catch (error) {
      console.error(error, nvideo)
    }

  }

  ngOnDestroy() {
    if (this.meetingSession && this.meetingSession.audioVideo)
      this.meetingSession.audioVideo.stop()
    console.log('destroy')
  }

  exportToWord() {
    console.log('exportToWord')


    asBlob(this.dataWrite).then(data => {
      saveAs(data, 'report.docx') // save as docx file
    })
  }


  getMeetingId() {
    //console.log('getMeetingId')
    try {
      return this.meetingSession.configuration.meetingId
    } catch (error) {

    }
  }

  getAttendeeId() {
    //console.log('getAttendeeId')
    try {
      return this.attendeeResponse.Attendee.AttendeeId
    } catch (error) {

    }
  }


  lastGetName = 0

  getNameFromAttendeeId(id: string) {
    //console.log(this.names)

    try {
      id = id.split('#')[0]
    } catch (error) {
      //console.error(error)
    }

    if (!id || !this.getAttendeeId()) return id

    let x = this.names.find(e => e.attendeeId == id)
    if (x && x.name) return x.name

    //console.log(id, this.getAttendeeId())

    try {
      id = id.split('#')[0]
    } catch (error) {
      //console.error(error)

    }

    //let ts = (new Date).getTime()
    //if (ts - this.lastGetName > 100) {
    //this.sendMessage({ attendeeId: id }, 'getName')
    //this.lastGetName = ts
    //}


    return id
  }


  tsToDate(ts) {
    //console.log('tsToDate')
    return moment(ts).format('HH:mm:ss')
  }

  getReservationNumber(x) {

    //  console.log('getReservationNumber')
    try {

      return this.videos.filter(e => e.reserved).sort((a, b) => a.reservedAt - b.reservedAt).findIndex(e => e.id == x.id) + 1


    } catch (error) {

    }
  }

  loadQuestions() {

    //console.log('loadQuestions')

    this.db.aws_chime_get_data(this.meetingId).then(res => {

      console.log(res)
      this.questions = res.data.domande

    }).catch(err => console.log(err))
  }

  openSettings() {
    console.log('openSettings')

    //console.clear()


    let dialogRef = this.dialog.open(ChimeSettingsDialog, {
      height: '400px',
      width: '600px',
      data: {
        audioInputDevs: this.audioInputDevs,
        audioOutputDevs: this.audioOutputDevs,
        videoDevs: this.videoDevs,
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log(`Dialog result: ${result}`);
      if (result) {

        if (localStorage != undefined)
          var dev1 = localStorage.getItem('awsc_audioIn')
        if (dev1) {
          dev1 = JSON.parse(dev1)
          await this.meetingSession.audioVideo.chooseAudioInputDevice(dev1)
        }
        if (localStorage != undefined)
          var dev2 = localStorage.getItem('awsc_audioOut')
        if (dev2) {
          dev2 = JSON.parse(dev2)
          await this.meetingSession.audioVideo.chooseAudioOutputDevice(dev2)
        }
        if (localStorage != undefined)
          var dev3 = localStorage.getItem('awsc_videoDev')
        if (dev3) {
          dev3 = JSON.parse(dev3)
          await this.meetingSession.audioVideo.chooseVideoInputDevice(dev3)
        }


        /*
                this.meetingSession.audioVideo.stop()
        
                setTimeout(() => {
                  this.meetingSession.audioVideo.start()
                }, 100);
        */
      }
    });
  }

  addQuestion() {
    console.log('addQuestion')

    let dialogRef = this.dialog.open(QuestionDialogComponent, {
      height: '400px',
      width: '600px',
      data: { type: 'question' },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.db.aws_chime_add_question(this.meetingId, result).then(res => {

          console.log(res)

          if (res.status != 'OK') {
            alert(JSON.stringify(res))
            return
          }

          this.sendMessage({ attendeeId: 'chatroom' }, 'quuestionUpdated')

          alert('domanda inserita')
          this.loadQuestions()
        }).catch(err => console.log(err))
      }
    });
  }

  addAnswer(id, question) {
    console.log('addAnswer')

    let dialogRef = this.dialog.open(QuestionDialogComponent, {
      height: '400px',
      width: '600px',
      data: {
        type: 'answer',
        question: question
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.db.aws_chime_add_answer(this.meetingId, id, this.getAttendeeId(), result, this.username).then(res => {

          if (res.status != 'OK') {
            alert(JSON.stringify(res))
            return
          }

          this.sendMessage({ attendeeId: 'chatroom' }, 'answerUpdated')

          alert('Risposta inserita')
          console.log(res)
          this.loadQuestions()
        }).catch(err => console.log(err))
      }
    });
  }

  giaRisposto(id) {
    //console.log('giaRisposto')

    let me = this.getAttendeeId()


    let q = this.questions.find(e => e.id == id)

    if (!q) {
      console.warn('domanda non trovata')
      return false
    }


    let answ = q.risposte.find(e => e.attendeeId == me)

    if (!answ) return false

    switch (answ.val) {
      case 'YES':
        return 'Favorevole'
      case 'NO':
        return 'Contrario'
      case 'AST':
        return 'Astenuto'
      default:
        return 'Non precisato'
    }

  }


  displayRisposte(type, risposte: IRisposta[]) {
    //console.log('displayRisposte')

    switch (type) {
      case 'YES':
        let yes = risposte.filter(e => e.val == 'YES').length
        return yes
      case 'NO':
        let no = risposte.filter(e => e.val == 'NO').length
        return no
      case 'AST':
        let ast = risposte.filter(e => e.val == 'AST').length
        return ast
      case 'TOT':
        let tot = risposte.length
        return tot
    }
  }


  docviewer = false;
  docviewer_i = 0;
  docviewer_source = [];
  docviewer_position = 'center';
  docviewer_filter = '';
  docviewer_double = false
  docviewer_descr


  closeDocviewer() {
    this.docviewer = false;
  }

  openViewer(e, source) {
    //console.log(e)
    this.docviewer_i = 0;
    this.docviewer_source = source

    this.docviewer_double = e.double
    this.docviewer_descr = e.descr

    this.docviewer_i = e.i;
    this.docviewer_filter = e.filter;
    this.docviewer = true;

  }

  showDetailData = {}
  toggleDetail(q) {
    //   console.log('toggleDetail')
    console.log(q)
    this.showDetailData[q.id] = !this.showDetailData[q.id]
  }

  showDetail(q) {
    // console.log('showDetail')
    return this.showDetailData[q.id]
  }


}




export interface IDomande {
  id: number,
  dta: Date,
  domanda: string,
  risposte: IRisposta[],
}
export interface IRisposta {
  attendeeId: string,
  dta: Date,
  val: string,
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}




@Component({
  selector: 'app-chime-settings-dialog',
  template: `

    <div  style="width:50vw; margin:auto;">
    
      <div class="card">
        <div class="card-header  card-header-info">
          <h4 class="card-title" style="float:left">
            Impostazioni
          </h4>

          <button mat-raised-button style="float:right; padding: 0 10px!important; margin-left:10px"
          (click)="save()" >Ok</button>
          <button mat-raised-button style="float:right; padding: 0 10px!important; margin-left:10px"
          (click)="cancel()" >Annulla</button>
        </div>

        <div style="padding:20px">

          <mat-form-field class="full">
            <mat-label>Dispositivo video</mat-label>

            <mat-select [(ngModel)]="videoDev" [compareWith]="compareObjects">  
              <mat-option *ngFor="let i of videoDevs" [value]="i">
                {{i.label}}
              </mat-option>
            </mat-select>

          </mat-form-field>

          <mat-form-field class="full">
            <mat-label>Dispositivo audio IN</mat-label>

            <mat-select [(ngModel)]="audioIn" [compareWith]="compareObjects">
              <mat-option *ngFor="let i of audioIns" [value]="i">
                {{i.label}}
              </mat-option>
            </mat-select>

          </mat-form-field>

          <mat-form-field class="full">
            <mat-label>Dispositivo audio OUT</mat-label>

            <mat-select [(ngModel)]="audioOut" [compareWith]="compareObjects">
              <mat-option *ngFor="let i of audioOuts" [value]="i">
                {{i.label}}
              </mat-option>
            </mat-select>

          </mat-form-field>

        </div>

        </div>
    </div>
  `
})
export class ChimeSettingsDialog implements OnInit {

  videoDev
  videoDevs = []

  audioIn
  audioIns = []

  audioOut
  audioOuts = []

  constructor(
    public dialogRef: MatDialogRef<ChimeSettingsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    try {


      //console.clear()
      this.videoDevs = this.videoDevs.concat([{ deviceId: 'none', label: ' --- NO VIDEO ---' }], data.videoDevs)


      this.audioIns = data.audioInputDevs
      let distinct1 = []
      for (var i = 0; i < this.audioIns.length; i++)
        try {
          if (!distinct1.find(e => e.groupId == this.audioIns[i].groupId))
            distinct1.push(this.audioIns[i])
        } catch (error) {

        }

      //this.audioIns = distinct1

      this.audioOuts = data.audioOutputDevs
      let distinct2 = []
      for (var i = 0; i < this.audioOuts.length; i++)
        try {
          if (!distinct2.find(e => e.groupId == this.audioOuts[i].groupId))
            distinct2.push(this.audioOuts[i])
        } catch (error) {

        }
      //this.audioOuts = distinct2


      let tmp = JSON.parse(localStorage.getItem('awsc_audioIn'))
      if (tmp && tmp != 'undefined')
        this.audioIn = tmp
      if (!this.audioIn) this.audioIn = this.audioIns[0]

      tmp = JSON.parse(localStorage.getItem('awsc_audioOut'))
      if (tmp && tmp != 'undefined')
        this.audioOut = tmp
      if (!this.audioOut) this.audioOut = this.audioOuts[0]

      tmp = JSON.parse(localStorage.getItem('awsc_videoDev'))
      if (tmp && tmp != 'undefined')
        this.videoDev = tmp
      try {
        if (!this.videoDev) this.videoDev = this.videoDevs[0]
      } catch (error) {
        alert(JSON.stringify(error))
      }

    } catch (error) {

    }

    console.log(this.audioIn, this.audioOut, this.videoDev)

  }

  save(): void {
    if (this.audioIn)
      localStorage.setItem('awsc_audioIn', JSON.stringify(this.audioIn))
    if (this.audioOut)
      localStorage.setItem('awsc_audioOut', JSON.stringify(this.audioOut))
    if (this.videoDev)
      localStorage.setItem('awsc_videoDev', JSON.stringify(this.videoDev))



    this.dialogRef.close(true);
  }
  cancel(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {


  }

  compareObjects(a, b) {
    return a.deviceId == b.deviceId
  }

}

