import { Component, OnInit, Input,EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashcard',
  templateUrl: './dashcard.component.html',
  styleUrls: ['./dashcard.component.scss']
})
export class DashcardComponent implements OnInit {

  //[color]="'blue'" [title]="'title'" [main_value]="'12345'" [category]="'category'" [category_value]="435" [icon]

  _main_value_mask = '.0-0'
  @Input() set main_value_mask(main_value_mask) {
    this._main_value_mask = main_value_mask;
  }

  _color
  @Input() set color(color) {
    this._color = color
  }

  _icon
  @Input() set icon(icon) {
    this._icon = icon
  }

  _main_value_curr = 0;
  _main_value
  @Input() set main_value(main_value) {
    this._main_value_curr = 0;
    this._main_value = main_value;
    this.startIncreasingMain();
  }

  @Input() badge: number = 0;
 
  
  @Output() onBadgeClick: EventEmitter<void> = new EventEmitter();
  @Output() onMainClick: EventEmitter<void> = new EventEmitter();
  @Output() onCategoryClick: EventEmitter<void> = new EventEmitter();
  
  
  startIncreasingMain() {
    if (!this._main_value == undefined) return
    if (this._main_value_curr >= this._main_value) { this._main_value_curr = this._main_value; return; };

    if (this._main_value - this._main_value_curr > 10000)
      this._main_value_curr += Math.round((this._main_value - this._main_value_curr) / 3);
    else if (this._main_value - this._main_value_curr > 1000)
      this._main_value_curr += 1000 / 2
    else if (this._main_value - this._main_value_curr > 100)
      this._main_value_curr += 100 / 2
    else if (this._main_value - this._main_value_curr > 10)
      this._main_value_curr += 10 / 2
    else
      this._main_value_curr++

    setTimeout(() => {
      this.startIncreasingMain();
    }, 50);
  }


  _category_value_curr = 0;
  _category_value
  @Input() set category_value(category_value) {
    this._category_value_curr = 0;
    this._category_value = category_value;
    this.startIncreasingCategory();
  }

  openBadge(){
    console.log('onBadgeClick')
    this.onBadgeClick.emit()
  }

  
  openMain(){
    console.log('onMainClick')
    this.onMainClick.emit()
  }

  openCategory(){
    console.log('onCategoryClick')
    this.onCategoryClick.emit()
  }

  startIncreasingCategory() {

    if (!this._category_value == undefined) return
    if (this._category_value_curr >= this._category_value) { this._category_value_curr = this._category_value; return; };

    if (this._category_value - this._category_value_curr > 10000)
      this._category_value_curr += Math.round((this._category_value - this._category_value_curr) / 3);
    else if (this._category_value - this._category_value_curr > 1000)
      this._category_value_curr += 1000 / 2
    else if (this._category_value - this._category_value_curr > 100)
      this._category_value_curr += 100 / 2
    else if (this._category_value - this._category_value_curr > 10)
      this._category_value_curr += 10 / 2
    else
      this._category_value_curr++

    setTimeout(() => {
      this.startIncreasingCategory();
    }, 50);
  }


  _title
  @Input() set title(title) {
    this._title = title
  }

  _category
  @Input() set category(category) {
    this._category = category
  }




  constructor(private router:Router) { }

  ngOnInit() {
  }

}
