import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'severity'
})
export class ServerityPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    switch((value as string).toLowerCase()){
      case 'l': return 'Lieve';
      case 'm': return 'Medio';
      case 'g': return 'Grave';
      case 's': return 'Sost.';

      default: return value;
    }

  }

}
