import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../../services/authorization.service';
import { IPerizia } from '../../../models/perizia.interface';
import { MatDialog } from '@angular/material/dialog';
import { LocalDataSource } from 'ng2-smart-table';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FirDialogComponent } from './dialog/dialog.component';
import { IFir } from 'app/models/fir.interface';
import { MysqlService } from 'app/services/mysql.service';
import { UserService } from 'app/services/user.service';
import { OsmService } from 'app/services/osm.service';
import { GridActiveComponent, GridDeleteComponent, GridEditComponent } from 'app/components/grid-edit/grid-edit.component';
import { dateComparator, dateFormatter } from 'app/shared';
declare var Chart: any;
declare let L;
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-fir',
  templateUrl: './fir.component.html',
  styleUrls: ['./fir.component.scss']
})
export class FirComponent implements OnInit, OnDestroy {

  item: IPerizia;
  stats;
  usersByGroup
  labels = ["Download Sales", "In-Store Sales", "Mail-Order Sales"];
  data = [300, 500, 100];

  gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    },
    pagination: true,
    paginationPageSize: 10,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    columnDefs: [
      {
        headerName: '', cellRenderer: GridEditComponent,width:'100px', cellStyle: { 'display': 'flex', 'align-items': 'center' },
        cellRendererParams: {
          clicked: (field: any) => {
            this.onEditDialog({ data: field })
          }
        },
      },
      {
        headerName: '', cellRenderer: GridDeleteComponent,width:'100px', cellStyle: { 'display': 'flex', 'align-items': 'center' },
        cellRendererParams: {
          clicked: (field: any) => {
            this.onDelete({ data: field })
          }
        },
      },
      { field: 'id', headerName: 'ID',width:'100px', },
      {
        field: 'dta', headerName: 'Data richiesta', cellRenderer: (params) => {
          try {
            return moment(params.data.dta).tz('Europe/London').format('DD/MM/YYYY HH:mm:ss');
          } catch (error) {
            return params.data.dta;
          }
        }
      },
      { field: 'user', headerName: 'Utente' },
      { field: 'type', headerName: 'Tipo richiesta' },
      { field: 'productCategory', headerName: 'Cat. prodotto' },
      { field: 'powerType', headerName: 'Alimentazione' },
      { field: 'application', headerName: 'Applicazione' },
      { field: 'refPerson', headerName: 'Riferimento' },
      { field: 'refPhone', headerName: 'Telefono' },
      { field: 'status', headerName: 'Stato',width:'300px', },
    ],
    onGridReady(params) {
      params.columnApi.autoSizeAllColumns();
    }
  };
  settings = {

    actions: {
      position: 'left', width: '100px', columnTitle: '*Azioni*',
      add: this.authorizationService.isAuthorized('insurance', 'new').permitted,
      edit: this.authorizationService.isAuthorized('insurance', 'view').permitted,
      delete: this.authorizationService.isAuthorized('insurance', 'delete').permitted,
    },

    mode: 'external',

    add: {
      addButtonContent: '<i class="material-icons" style="display:inline">add</i>',
      createButtonContent: '<i class="checkmark" style="display:inline"></i>',
      cancelButtonContent: '<i class="close" style="display:inline"></i>',
    },

    edit: {
      editButtonContent: '<i class="material-icons" style="display:inline">edit</i>',
      saveButtonContent: '<i class="checkmark style="display:inline""></i>',
      cancelButtonContent: '<i class="close" style="display:inline"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="material-icons" style="display:inline">delete</i>',
      confirmDelete: true,
    },

    columns: {


      id: {
        title: 'ID',
      },

      dta: {
        title: 'Data richiesta',
      },

      user: {
        title: 'Utente',
      },

      type: {
        title: 'Tipo richiesta',
      },
      productCategory: {
        title: 'Cat. prodotto',
      },
      powerType: {
        title: 'Alimentazione',
      },
      application: {
        title: 'Applicazione',
      },
      refPerson: {
        title: 'Riferimento',
      },
      refPhone: {
        title: 'Telefono',
      },
      status: {
        title: 'Stato',
      },


    }
  };


  source: LocalDataSource; // add a property to the component

  type: string;
  rowData
  rowDataElenco
  getRowStyle

  columnDefs: any[] = [

    {
      field: 'id', headerName: '',
      sortable: false,
      width: '40px', resizable: false,
      cellRenderer: GridEditComponent,
      cellRendererParams: {
        clicked: (field: any) => {
          //console.clear()
          this.onCertEditDialog({ data: { id: field } })
        }
      },
    },


    //  { field: 'ok', cellRenderer: GridActiveComponent, width: '40px', resizable: false, filter: false },
    { field: 'user', headerName: 'Utente' },
    { field: 'nrCommessaInt', headerName: 'Nr. Commessa' },
    {
      field: 'dataCommessa', headerName: 'Data Commessa.',
      valueFormatter: e => dateFormatter(e.value), filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => dateComparator
      }
    },
    { field: 'cliente', headerName: 'Cliente' },
    { field: 'telaioDiProvenienza', headerName: 'Telaio Prov.' },
    { field: 'respInt', headerName: 'Resp. Int.' },
    { field: 'anomalieIN', headerName: 'Anomalie IN.' },
    { field: 'tipoMotore', headerName: 'Tipo Motore' },

  ];

  defaultColDef = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    suppressToolPanel: true,

    cellStyle: { padding: '0', 'text-align': 'center' }

  };
  gridApi;
  gridColumnApi;
  rowClass = 'row-color'

  chart1;

  constructor(
    public dialog: MatDialog,
    private db: MysqlService,
    private authorizationService: AuthorizationService,
    private router: Router,
    private route: ActivatedRoute,
    private osm: OsmService,
    private userService: UserService,
  ) {
    this.db.list_utenti_by_clienteAssociato(11).then(res => {
      console.log(res)
      this.usersByGroup = res
    })



    let x = window.location.href.split('/');
    this.type = x.pop();
    this.type = x.pop();
    this.type = this.type.split('?')[0];

    console.log('t: ' + this.type)

    //this.types = db.getTypes();

    this.route.queryParams.subscribe(params => {

      let id: number = +params['id'];

      console.log('goto ID:' + id)
      /*
            if (id > 0) {
              router.navigate(['/automotive/' + this.type + '/dettaglio/' + id]);
              return;
            }
      */

    });

    this.loadData();
    this.checkActive()
  }

  isUser(): boolean {
    return this.userService.getCurrentUser().role == 'CLIENTE';
  }
  isAdmin(): boolean {
    return (this.userService.getCurrentUser().role == 'ADMIN' || this.userService.getCurrentUser().role == 'GESTORE');
  }
  active = false
  checkActive() {
    this.db.utenti_get_attivo(this.userService.getEmail()).then(res => {
      console.log(res)
      if (res)
        this.active = true
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event)
    this.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sizeColumnsToFit();
  }
  sizeColumnsToFit() {
    this.gridApi.sizeColumnsToFit()
  }


  autoSizeAll(skipHeader = false) {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().filter(e => e.colDef.resizable && e.colDef.field != 'note').forEach((column) => {
      allColumnIds.push(column.colId);
      //    console.log(column)
    });


    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  toggleActive(i) {

    console.log(i)
    if (i.permissions) {
      if (i.permissions.fir_active) i.permissions.fir_active = false;
      else i.permissions.fir_active = true;
    } else {
      i.permissions = { fir_active: true }
    }
    console.log(i)


    this.db.upsert_utente(i).then(res => {
      console.log(res);

    }).catch(err => {
      console.error('error in upsert_utenti', err)
    })

  }


  tabChange(e) {


    setTimeout(() => {
      this.loadData()
      this.loadMap();
    }, 500);
  }



  logout() {
    this.userService.logout();
    this.router.navigate([''])
  }

  loadData() {

    this.db.fir_list().then(res => {
      console.log(res);
      this.rowDataElenco = res
      this.source = new LocalDataSource(res); // create the source
    })

    this.db.fir_stats().then(res => {
      console.log(res);

      res.avg_l1 = +res.avg_l1;
      res.avg_l2 = +res.avg_l2;
      res.avg_l3 = +res.avg_l3;

      this.stats = res; // create the source


      this.loadCharts();

      this.db.fir_cert_list().then(res => {
        console.log(res)
        this.rowData = res
        this.getRowStyle = params => {
          if (params.node.rowIndex % 2 === 0) {
            return { background: '#F0F0FF' };
          }
        };
      })
    })

  }


  loadCharts() {
    this.loadChart('chart1', ['Ho risolto la problematica', 'Ho parzialmente risolto la problematica', 'Non Ho risolto la problematica',], [this.stats.l1_2, this.stats.l1_1, this.stats.l1_0,])
    this.loadChart('chart2', ['Ho ricevuto la risposta nei tempi attesi', 'Ho ricevuto pressioni dal cliente per una risposta', 'Ho atteso troppo tempo',], [this.stats.l2_2, this.stats.l2_1, this.stats.l2_0,])
    this.loadChart('chart3', ['Sicuramente', 'Forse', 'Non credo',], [this.stats.l3_2, this.stats.l3_1, this.stats.l3_0,])
  }




  openDialog(data: IFir = null): void {

    const dialogRef = this.dialog.open(FirDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {

      this.loadData();

      console.log(result);
    });

  }



  ngOnInit() {



  }

  loadChart(id: string, labels: string[], values: number[]) {
    this[id] = new Chart(id, {
      type: 'pie',

      data: {
        datasets: [{
          data: values,
          backgroundColor: ['#66ff66', '#ffff66', '#ff6666']
        }],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: labels
      },

      options: {
        legend: {
          display: false,
          position: 'right',
          fullWidth: false
        },

      }
    });
  }



  map: any
  layers = L.layerGroup();
  loadMap() {
    /*
        L.NumberedDivIcon = L.Icon.extend({
          options: {
            // EDIT THIS TO POINT TO THE FILE AT http://www.charliecroom.com/marker_hole.png (or your own marker)
            iconUrl: '/assets/img/marker.png',
            number: '',
            shadowUrl: null,
            iconSize: new L.Point(64, 64),
            iconAnchor: new L.Point(32, 64),
            popupAnchor: new L.Point(0, -33),
    
            className: 'leaflet-div-icon'
          },
    
          createIcon: function () {
            var div = document.createElement('div');
            var img = this._createImg(this.options['iconUrl']);
            var numdiv = document.createElement('div');
            numdiv.setAttribute("class", "number");
            numdiv.innerHTML = this.options['number'] || '';
            div.appendChild(img);
            div.appendChild(numdiv);
            this._setIconStyles(div, 'icon');
            return div;
          },
    
          //you could change this to add a shadow like in the normal marker if you really wanted
          createShadow: function () {
            return null;
          }
        });*/

    this.map = null;

    if (!this.map) {
      this.map = L.map('map');
      this.map.addLayer(this.layers);
    };

    this.layers.clearLayers();

    var bounds = new L.LatLngBounds();

    let geo = {
      latitude: 9, longitude: 45
    }


    this.map.setView([geo.latitude, geo.longitude], 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);


    this.stats.perUser.forEach(async e => {

      console.log(geo)


      if (!e.geo) {

        await this.osm.nominatim('IT', e.Prov, e.CAP, e.Citta, e.Indirizzo).then(res => {

          if (!res || !res[0]) {
            geo = undefined;
          } else {
            geo = {
              latitude: res[0].lat,
              longitude: res[0].lon
            }

            this.db.upsert_utente_geo(e.Email, geo).then(res => { console.log(res) }).catch(err => { console.error(err) })


            let nome = `${e.Nome.toUpperCase()}`;
            let marker = L.marker(new L.LatLng(geo.latitude, geo.longitude),
              //{        icon: new L.NumberedDivIcon({ number: '1' })      }
            ).addTo(this.map)
              .bindPopup(`${nome}`)
              .openPopup();

            bounds.extend(marker.getLatLng());
            this.map.fitBounds(bounds);

          }

        })

      } else {

        let nome = `${e.Nome.toUpperCase()}`;
        let marker = L.marker(new L.LatLng(e.geo.latitude, e.geo.longitude),
          //{        icon: new L.NumberedDivIcon({ number: '1' })      }
        ).addTo(this.map)
          .bindPopup(`${nome}`)
          .openPopup();

        bounds.extend(marker.getLatLng());
        this.map.fitBounds(bounds);

      }




    });





  }


  ngOnDestroy() {
    console.log('destroy')

    if (!this.source) return;

  }

  onCreateDialog() {
    this.router.navigate(['/' + this.type + '/fir/dettaglio/0'])
    //this.openDialog();
  }

  onEditDialog(event) {
    this.router.navigate(['/' + this.type + '/fir/dettaglio/' + event.data.id])
    //this.openDialog(event.data);
  }

  onCertCreateDialog() {
    let url = '/' + this.type + '/fir/cert/0'
    console.log(url)
    this.router.navigate([url])
    //this.openDialog();
  }

  onCertEditDialog(event) {
    this.router.navigate(['/' + this.type + '/fir/cert/' + event.data.id])
    //this.openDialog(event.data);
  }

  onDelete(event): void {

    if (!confirm("Cancellare il record selezionato?")) return;

    this.db.fir_delete(event.data.id).then(res => {
      this.loadData();
    })

  }



  export() {
    window.open(this.db.fir_get_export_url(), '_blank')
  }



}





