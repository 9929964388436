<div class="wrapper">

    <!--
    <div class="topmenu">
        <mat-menu #appMenu="matMenu" yPosition="above">
            <button mat-menu-item>Settings</button>
            <button mat-menu-item>Help</button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu">
            File
        </button>
    </div>
    -->
    
    <div class="sidebar" *ngIf="user" data-color="danger">
        <app-sidebar-tree></app-sidebar-tree>



        <p style="text-align: center; border-top:1px solid gray; border-bottom:1px solid gray; margin:30px; padding:5px">Versione:<br>{{version}}</p>
            
    </div>

    <div class="cnt"
        [ngClass]="{'main-panel': user!=undefined && user!={}, 'main-panel-nologin': user==undefined || user=={} }">

        <router-outlet></router-outlet>

        <!--        
        <div *ngIf="isMaps('maps')">
            <app-footer></app-footer>
        </div>

        -->

    </div>
</div>