import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MysqlService } from 'app/services/mysql.service';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-videochat-ext',
  templateUrl: './videochat-ext.component.html',
  styleUrls: ['./videochat-ext.component.scss'],
})


export class VideochatExtComponent implements OnInit, OnDestroy {

  sessionId: string;
  token: string;
  cmd: string;

  privacy: string = 'Modello Privacy in caricamento ...'


  @HostListener('window:unload', ['$event'])
  async handleClose($event) {
    await this.setOnlineStatus(false).then(res => console.log(res)).catch(err => console.log(err));
    //$event.returnValue = true;

    return true;
  }

  id: string;
  item;
  agent
  hideThumb: boolean;

  constructor(private route: ActivatedRoute,
    private afFirestore: AngularFirestore,
    private db: MysqlService,
  ) {
    console.log('constructor')


    this.agent = window.navigator.userAgent

    //this.hideThumb = db.getIcon() == 'gg'

  }

  ngOnInit() {



    this.db.impostazioni_get('direct_privacy').then(res => {
      this.privacy = res[0].valore
      console.log('direct_privacy', this.privacy)

      window.document.getElementById('privacy').innerHTML = this.privacy

    })

    //alert('init')

    this.route.params.subscribe(res => {
      console.log('id:' + res.id)
      this.id = res.id;

      //alert('id: ' + this.id)

      this.loadData()
    })




  }

  loadData() {

    console.log('loaddata')

    this.afFirestore.collection('requests').doc(this.id).valueChanges().subscribe(res => {
      this.item = res;

      //alert('record found')
      console.log('record found', res)

      if (this.item.closed && this.item.okPrivacy) {
        alert('Teleperizia conclusa, la ringraziamo per la collaborazione!')
        return;
      }
      if (this.item.closed && this.item.okPrivacy === false) {
        alert('Teleperizia non possibile!')
        return;
      }

      //alert('not closed')
      console.log('not closed')

      if (this.item.cmd && this.item.cmd != '') {
        //alert('cmd ' + this.item.cmd)
        console.log('cmd ' + this.item.cmd)
        console.log('cmd received - ext: ', this.item.cmd);
        this.cmd = this.item.cmd;
        setTimeout(() => { this.cmd = ''; this.afFirestore.doc('requests/' + this.id).set({ cmd: '' }, { merge: true }) }, 100)
      }


      //alert('sessionId ' + this.item.sessionId)

      if (!this.item.sessionId) {

        this.db.opentok_create_session().then(res => {
          console.log(res)
          this.item.sessionId = res;
          this.sessionId = this.item.sessionId




          // alert('created sessionId ' + this.item.sessionId)

          this.setOnlineStatus(true, res);

          this.generateToken()

        })
      } else {
        this.sessionId = this.item.sessionId
        this.generateToken()
        this.setOnlineStatus(true, this.sessionId);
      }

      // set agent //
      if (!this.item.agent) this.afFirestore.doc('requests/' + this.id).set({ agent: this.agent }, { merge: true })

      if (this.item.okPrivacy) this.getGeo()

    })

  }

  generateToken() {
    if (!this.token) {
      this.db.opentok_generate_token(this.sessionId).then(res => {
        console.log(res)
        this.token = res;
      })
    }
  }

  async setOnlineStatus(online: boolean, sessionId = null) {
    console.log('setting online:' + online)

    if (sessionId == null)
      await this.afFirestore.doc('requests/' + this.id).set({ online: online, }, { merge: true })
    else
      await this.afFirestore.doc('requests/' + this.id).set({ online: online, sessionId: sessionId }, { merge: true })
    console.log('online:' + online)
  }

  ngOnDestroy() {
    console.log('ngOnDestroy')
    alert('ngOnDestroy')

    this.setOnlineStatus(false);
    alert('destroy')
    console.log('destroy')
  }


  async setOkPrivacy(b) {

    await this.afFirestore.doc('requests/' + this.id).set({ okPrivacy: b, okPrivacyDate: new Date(), closed: !b }, { merge: true })

    if (b) this.getGeo()

  }

  getGeo() {
    if (!this.item.geo && this.item.okPrivacyGeo == undefined) {

      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(res => {




          //  alert('geo ok ')

          console.log(res.coords)

          let geo = {
            accuracy: res.coords.accuracy,
            latitude: res.coords.latitude,
            longitude: res.coords.longitude,
          }

          this.afFirestore.doc('requests/' + this.id).set({ geo: geo, okPrivacyGeo: true, okPrivacyGeoDate: new Date() }, { merge: true })


        },
          err => {
            console.error(err)
            this.afFirestore.doc('requests/' + this.id).set({ geoErr: err.message, okPrivacyGeo: false, okPrivacyGeoDate: new Date() }, { merge: true })
          }
        );
      };


    }

  }

  okPrivacyCam(b) {

    console.log('okPrivacyCam ' + b)
    this.afFirestore.doc('requests/' + this.id).set({ okPrivacyCam: b, okPrivacyCamDate: new Date(), closed: !b }, { merge: true })

  }

  setStream(e) {

    console.log('setStream ', e)
    this.afFirestore.doc('requests/' + this.id).set({ stream: e }, { merge: true })

  }
}
