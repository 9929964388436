import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPerizia } from 'app/models/perizia.interface';
import { MysqlService } from 'app/services/mysql.service';
import { LocalDataSource } from 'ng2-smart-table';
import * as ts from "typescript";

export interface IPCheck {
  description: string
  fn: (item: IPerizia) => Promise<boolean>
  condition: (item: IPerizia) => boolean
  passed?: boolean
}

@Component({
  selector: 'app-dialog-check',
  templateUrl: './dialog-check.component.html',
  styleUrls: ['./dialog-check.component.scss']
})
export class DialogCheckComponent implements OnInit {


  ///////////////////////////////////

  source: LocalDataSource;
  settings = {

    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    filter: false,

    pager: { perPage: 650000 },

    columns: {

      description: {
        title: 'Descrizione',
        filter: false
      },

      passed: {
        title: 'Passed',
        filter: false,
        type: 'html',
        valuePrepareFunction: (value: boolean) => {

          if (value === true)
            return '<i class="material-icons" color="success" style="color:green!important">check_circle</i>'

          else if (value === false) return '<i class="material-icons" color = "danger" style = "color:red!important" > warning </i>'

          else return 'Loading...'

        }
      },

    }
  };

  checks_all: IPCheck[] = [
    /* 
    {
      description: "Almeno un'immagine",

      fn: async (item: IPerizia) => {

        try {
          let n = 0

          n += item.allegati.length

          if (item.result?.attachments)
            n += item.result.attachments.length

          if (item.result?.fotoVetrina)
            n += item.result.fotoVetrina.length

          if (item.result?.altriElementi)
            n += item.result.altriElementi.length

          if (item.result?.damages)
            n += item.result.damages.length

          if (item.result?.documenti)
            n += item.result.documenti.length

          return n > 0
        } catch (error) {
          console.error(error)
          return false
        }



      },

      condition: (item: IPerizia) => {
        return true
      }

    },
    
       {
         description: "Consistenza immagini",
   
         fn: async (item: IPerizia) => {
   
           try {
   
   
             for (const e of item.allegati) {
               let b = true
               await this.http.head(e.url).toPromise().catch(err => {
                 console.error(e, err)
                 b = false
               })
               if (!b) return false
             }
   
             if (item?.result?.fotoVetrina?.length > 0)
               for (const e of item.result.fotoVetrina) {
                 let b = true
                 await this.http.head(e.url).toPromise().catch(err => {
                   console.error(e, err)
                   b = false
                 })
                 if (!b) return false
               }
   
             if (item?.result?.altriElementi?.length > 0)
               for (const e of item.result.altriElementi) {
                 let b = true
                 await this.http.head(e.url).toPromise().catch(err => {
                   console.error(e, err)
                   b = false
                 })
                 if (!b) return false
               }
   
             if (item?.result?.attachments?.length > 0)
               for (const e of item.result.attachments) {
   
                 console.log(e)
   
                 let b = true
                 await this.http.head(e.url).toPromise().catch(err => {
                   console.error(e, err)
                   b = false
                 })
                 if (!b) return false
               }
   
             if (item?.result?.documenti?.length > 0)
               for (const e of item.result.documenti) {
                 let b = true
                 await this.http.head(e.url).toPromise().catch(err => {
                   console.error(e, err)
                   b = false
                 })
                 if (!b) return false
               }
   
   
   
             if (item?.result?.damages?.length > 0)
               for (const e of item.result.damages) {
                 let b = true
                 await this.http.head(e.img1.url).toPromise().catch(err => {
                   console.error(e, err)
                   b = false
                 })
                 await this.http.head(e.img1.url).toPromise().catch(err => {
                   console.error(e, err)
                   b = false
                 })
                 if (!b) return false
               }
   
           } catch (error) {
             console.error(error)
             return false
           }
   
   
           return true
   
         },
   
         condition: (item: IPerizia) => {
           return true
         }
   
       },
   
   
       {
         description: "ADZ Presente",
   
         fn: async (item: IPerizia) => {
   
           try {
             return item.adz_data?.adz != undefined
   
           } catch (error) {
             //console.error(error)
             return false
           }
   
         },
   
         condition: (item: IPerizia) => {
           return true
         }
   
       },
   
   
   
       {
         description: "Valore commerciale > 0",
   
         fn: async (item: IPerizia) => {
   
           try {
             return +item.adz_data.adz.valore_commerciale.val > 0
   
           } catch (error) {
             //console.error(error)
             return false
           }
   
         },
   
         condition: (item: IPerizia) => {
           return true
         }
   
       },
   
       {
         description: "Controllo impossibile",
   
         fn: async (item: IPerizia) => {
   
           return false
         },
   
         condition: (item: IPerizia) => {
           return true
         }
   
       }
   */
  ]
  checks = []

  checks_filter() {

    console.log(this.checks_all)

    for (const e of this.checks_all) {

      //console.log('fn', JSONfn.stringify(e))

      if (e.condition(this.data.item)) this.checks.push(e)
    }
    console.log(this.checks)
    this.source = new LocalDataSource(this.checks); // create the source
  }

  async checks_run() {
    for (const e of this.checks) {

      e.fn(this.data.item, { commessa: this.data.commessa, http: this.http }).then(passed => {
        console.log('checks_run single', e, passed)
        e.passed = passed
        this.source = new LocalDataSource(this.checks); // create the source
      })

    }
    console.log(this.checks)



  }


  ////////////////////////////////////


  form: UntypedFormGroup;
  valore_commerciale: number
  valore_relitto: number
  valore_per_differenza: number
  valore_a_nuovo: number
  osservazioni: string

  constructor(
    public dialogRef: MatDialogRef<DialogCheckComponent>,
    private formBuilder: UntypedFormBuilder,
    private db: MysqlService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA,) public data,
  ) {

    console.log(data)
    if (this.data.item.adz_data) {
      this.valore_commerciale = this.data.item.adz_data.adz.valore_commerciale.val
      this.valore_relitto = this.data.item.adz_data.adz.valore_relitto.val
      this.valore_per_differenza = this.data.item.adz_data.adz.valore_per_differenza.val
      this.valore_a_nuovo = this.data.item.adz_data.adz.valore_a_nuovo.val
      this.osservazioni = this.data.item.adz_data.adz.osservazioni.val
    }

    this.db.perizie_checks_list().then(async res => {
      for (const e of res) {


        let fn_result = ts.transpile(e.fn);

        let fn: any = eval(fn_result);

        let cnd_result = ts.transpile(e.condition);
        let cnd: any = eval(cnd_result);

        let x: IPCheck = {
          description: e.description,
          fn: fn,
          condition: cnd,
        }
        this.checks_all.push(x)
        console.log(x)


      }


      this.checks_filter()
      this.checks_run()



    })

    //console.log('fn', JSONfn.stringify(this.checks_all[0].fn))
    //console.log('condition', JSONfn.stringify(this.checks_all[0].condition))

    this.form = this.formBuilder.group({
      valore_commerciale: ['',],
      valore_relitto: ['',],
      valore_per_differenza: ['',],
      valore_a_nuovo: ['',],
      osservazioni: ['',],

    })


  }



  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close(this.changed);
  }

  changed = false
  save(field: string, value: any) {
    console.log(field, value)
    this.db.perzie_adz_update_field(this.data.item.id, field, value).then((res: any) => {
      console.log(res)

      if (res.status == 'OK') {
        this.changed = true
        alert('Campo aggironato')
      } else {
        alert(JSON.stringify(res))
      }

    })
  }
}
