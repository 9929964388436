import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { MysqlService } from 'app/services/mysql.service';
import { Router } from '@angular/router';

declare const $: any;
declare interface RouteInfo {
    category: string;
    path: string;
    title: string;
    icon: string;
    class: string;
    separator: boolean;
    child?: boolean;
    disabled?: boolean;
    adminOnly?: boolean;
    permissions?: string[];
    userPermissionFlag?: string;
    newPage?: boolean
}


export const CATEGORIES = [
    { text: 'AUTOMOTIVE', icon: 'commute', color: 'red' },
    { text: 'INDUSTRIAL', icon: 'apartment', color: 'red' },
    { text: 'GESTIONE', icon: 'airplay', color: 'gray' },
    { text: 'EXTRA', icon: 'comment', color: 'gray' },
    { text: 'OPZIONI', icon: 'settings', color: 'white' }
]

export const ADMIN_CATEGORIES = [

    // { text: 'CLAIMS', icon: 'compare-arrows', color: 'blue' },
    // { text: 'INDUSTRIAL', icon: 'comment', color: 'gray' },
    // { text: 'EXTRA', icon: 'compare-arrows', color: 'blue' },

]

export const ROUTES: RouteInfo[] = [

    { category: 'AUTOMOTIVE', path: '/automotive/dashboard', title: 'Dashboard', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'automotive_dashboard' },
    { category: 'AUTOMOTIVE', path: '/automotive/stats', title: 'Statistiche', icon: 'insert_chart', class: '', separator: false, userPermissionFlag: 'automotive_stats' },
    { category: 'AUTOMOTIVE', path: '/automotive/perizie', title: "Insurance", icon: 'security', class: '', separator: false, userPermissionFlag: 'automotive_insurance', },
    { category: 'AUTOMOTIVE', path: '/automotive/ssu', title: "SSU", icon: 'opacity', class: '', separator: false, userPermissionFlag: 'automotive_ssu' },
    { category: 'AUTOMOTIVE', path: '/automotive/psv', title: "PSV", icon: 'opacity', class: '', separator: false, userPermissionFlag: 'automotive_psv' },
    { category: 'AUTOMOTIVE', path: '/automotive/all', title: "SSU", icon: 'opacity', class: '', separator: false, userPermissionFlag: 'automotive_all' },

    { category: 'AUTOMOTIVE', path: '/automotive/jobs', title: "Buy-back (BO)", icon: 'compare_arrows', class: '', separator: false, userPermissionFlag: 'automotive_buyback_backoffice' },
    { category: 'AUTOMOTIVE', path: '/automotive/buyback', title: "Buy-Back", icon: 'compare_arrows', class: '', separator: false, userPermissionFlag: 'automotive_buyback_internal' },
    { category: 'AUTOMOTIVE', path: '/automotive/buyback_fleet', title: "Buy-Back (Fleet)", icon: 'opacity', class: '', separator: false, userPermissionFlag: 'automotive_buyback_fleet' },


    { category: 'AUTOMOTIVE', path: '/automotive/remarketing', title: "Remarketing", icon: 'local_atm', class: '', separator: false, userPermissionFlag: 'automotive_remarketing' },
    { category: 'AUTOMOTIVE', path: '/ecommerce', title: "E-commerce", icon: 'shopping_cart', class: '', separator: false, userPermissionFlag: 'automotive_ecommerce', newPage: true },

    { category: 'AUTOMOTIVE', path: '/automotive/livexpert', title: 'LIVE', icon: 'videocam', class: '', separator: false, userPermissionFlag: 'automotive_telexpert' },
    { category: 'AUTOMOTIVE', path: '/automotive/livexpert-chime', title: 'LIVE-CHIME', icon: 'videocam', class: '', separator: false, userPermissionFlag: 'automotive_telexpert' },

    //{ category: 'AUTOMOTIVE', path: '/automotive/verycar', title: "VeryCar", icon: 'perm_device_information', class: '', separator: false, userPermissionFlag: 'automotive_verycar' },

    { category: 'AUTOMOTIVE', path: '/automotive/irepair', title: "iRepair", icon: 'build', class: '', separator: false, userPermissionFlag: 'automotive_irepair' },

    { category: 'CLAIMS', path: '/claims/dashboard', title: 'Dashboard', icon: 'dashboard', class: '', separator: false, disabled: true },
    { category: 'CLAIMS', path: '/claims/x/', title: "Incarichi", icon: 'assignment', class: '', separator: false, disabled: true },
/*
    { category: 'INDUSTRIAL', path: '/industrial/dashboard', title: 'Dashboard', icon: 'dashboard', class: '', separator: false,userPermissionFlag: 'industrial_dashboard' },
    { category: 'INDUSTRIAL', path: '/industrial/ispezioni', title: "Ispezioni", icon: 'assignment', class: '', separator: false,userPermissionFlag: 'industrial_ispezioni'  },
*/

    
    { category: 'EXTRA', path: '/extra/fir', title: "FIR", icon: 'settings_ethernet', class: '', separator: false, userPermissionFlag: 'extra_fir' },

    { category: 'EXTRA', path: '/extra/apeb', title: "UnipolService", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_apeb' },
    { category: 'EXTRA', path: '/extra/reclami-apeb', title: "Reclami", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_apeb' },
    { category: 'EXTRA', path: '/extra/mdg', title: "MdG - Allianz", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_mdg' },
    { category: 'EXTRA', path: '/extra/mdg-grandine', title: "MdG - Grandine", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_mdg' },
    { category: 'EXTRA', path: '/extra/archive', title: "Archivio", icon: 'file_copy', class: '', separator: false, userPermissionFlag: 'extra_archive' },

    { category: 'GESTIONE', path: '/extra/valutazioni', title: "Valutazioni", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_valutazioni' },
    { category: 'GESTIONE', path: '/extra/valutazioni-ritiri', title: "Ritiri", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_valutazioni' },
 
    { category: 'OPZIONI', path: '/options/users', title: "Utenti", icon: 'account_box', class: '', separator: false, userPermissionFlag: 'options_users' },
    { category: 'OPZIONI', path: '/options/ecommerce-users', title: "Utenti Eshop", icon: 'account_box', class: '', separator: false, userPermissionFlag: 'options_ecommerce_users' },
    { category: 'OPZIONI', path: '/options/registry/', title: "Anagrafiche", icon: 'content_paste', class: '', separator: false, userPermissionFlag: 'options_registry' },
    { category: 'OPZIONI', path: '/options/time-matrix/', title: "Attività", icon: 'content_paste', class: '', separator: false, userPermissionFlag: 'options_time_matrix' },
    { category: 'OPZIONI', path: '/options/tempario/', title: "Tempario", icon: 'access_time', class: '', separator: false, userPermissionFlag: 'options_tempario' },
    { category: 'OPZIONI', path: '/options/valutazioni-cespiti', title: "Cespiti", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'extra_valutazioni' },

    { category: 'OPZIONI', path: '/options/inspector-groups', title: "Gruppi - Ispettori", icon: 'group_work', class: '', separator: false, userPermissionFlag: 'options_inspectorgroups' },

    { category: 'OPZIONI', path: '/options/form-composer', title: "Form composer", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_formcomposer' },
//    { category: 'OPZIONI', path: '/options/document-composer', title: "Document composer", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_documentcomposer' },
    { category: 'OPZIONI', path: '/options/commesse', title: "Commesse", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_commesse' },
    { category: 'OPZIONI', path: '/options/coverage', title: "Copertura ispettori", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_coverage' },
    { category: 'OPZIONI', path: '/options/report', title: "Report", icon: 'report', class: '', separator: false, userPermissionFlag: 'options_rpeort' },
    { category: 'OPZIONI', path: '/options/verycar_leeds', title: "Verycar Sondaggi", icon: 'report', class: '', separator: false, userPermissionFlag: 'options_verycar_leeds' },




    { category: 'AUTOMOTIVE', path: '/automotive/planner', title: "Pianificatore", icon: 'calendar_today', class: '', separator: false, userPermissionFlag: 'automotive_calendar' },
    { category: 'INDUSTRIAL', path: '/industrial/planner', title: "Pianificatore", icon: 'calendar_today', class: '', separator: false, userPermissionFlag: 'industrial_calendar' },
    

];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    categories: any[];


    user;

    logo;


    constructor(private userService: UserService, public db: MysqlService, private router: Router) {

        this.logo = db.getLogo();

    }

    ngOnInit() {

        console.log(this.userService.isAdmin())
        

        this.userService.onUserState()
            .subscribe((user: any) => {

                let r = [];

                this.menuItems = ROUTES.filter(menuItem => menuItem)
                
                this.categories = r.concat(
                    CATEGORIES.filter(categories => categories),
                    this.userService.isAdmin() ? ADMIN_CATEGORIES.filter(categories => categories) : []
                );

                this.db.get_current_user_profile().then(res => {

                    this.user = res[0]

                    console.log('auth obs')
                    
                });

            });



    }

    open(menuItem) {

        if (!menuItem.newPage)
            this.router.navigate([menuItem.path])
        else {

            let url = this.router.createUrlTree([menuItem.path], {})
            window.open(url.toString().replace(menuItem.path, '#' + menuItem.path), '_blank')
        }

    }

    countVisibleByCategory(category: string): number {

        let n = 0;
        this.menuItems.filter(e => e.category == category).forEach(menuItem => {
            //console.log(menuItem)
            if (!menuItem.separator && !menuItem.child && (!menuItem.permissions || menuItem.permissions.includes(this.user.Livello)) && this.checkUserPermissionFlag(menuItem))
                n++;

        })
        return n;

    }


    checkUserPermissionFlag(menuItem: RouteInfo): boolean {

        if (!menuItem.userPermissionFlag) return true;

        if (!this.user) return false;


        let x;
        try {
            x = this.user.permissions[menuItem.userPermissionFlag];
        } catch (error) {
            return false;
        }


        //console.log(this.user,''+menuItem.userPermissionFlag + ' ' + this.user[menuItem.userPermissionFlag] )

        return +x > 0

    }

    filterMenuItems() {

        let mi = this.menuItems;

        return mi
    }

}
