<div class="row">
  <div class="col-12">
    <div #canvasCnt style="height: 100%;">
      <canvas #canvas id="canvas" (click)="onClick($event)" (mousemove)="onMouseMove($event)"></canvas>
    </div>
    <!--
        (pointerdown)="onPointerDown($event)"
        (pointerup)="onPointerUp($event)"
      -->



    <!--
        <h3>{{lastObjName}}&nbsp;</h3>
      -->

    <div class="loading" *ngIf="loading<=100 && !rendered && showLoading">
      <h3 *ngIf="loading<100">Loading: {{loading|number:'.0-0'}}</h3>
      <h3 *ngIf="loading==100 && !rendered">Rendering...</h3>
      <!--<ion-progress-bar type="indeterminate"></ion-progress-bar>-->
    </div>
    <!--
        <button style="position:absolute; left:0; top:0;" (click)="test()">
          XXX
        </button>
      -->

  </div>

  <div class="col-12" *ngIf="!hideList">
    <div #coldanni>
      <div class="row">
        <div class="col-2" *ngFor="let e of damages" (click)="this.showDamage(e)"
          style="text-align:center; border: 1px solid gray; padding:5px; margin-right:2px; border-radius: 5px;">

          <img *ngIf="e.img1" [src]="e.img1?.thumb ? e.img1?.thumb : '/assets/imgs/camera.png'" slot="end" style="width:100%">
          <br *ngIf="e.img1"><br >
          <p>{{e.type == 'NODAMAGE' ? 'Nessun danno rilevato' : e.objectName}}</p>
        </div>
      </div>

    </div>
  </div>
</div>


<div id="annotation-1" class="annotation" [ngClass]="{'hidden': isAnnotationHidden}">
  <div class="bubble-content">
    <b style="color: red">{{groupName}}</b>
    <div class="icon-content"><img (click)="onAnnotationClick()" [src]="'/assets/imgs/camera.png'" slot="end"
        style="height:40px;margin: 0 auto;"></div>
  </div>
</div>

<div class="card imgs" *ngIf="showDamageImage">

  <div class="card-header card-header-info">
    <h4 class="card-title">
      <span>{{showDamageImage.objectName}}
      </span>

      <button mat-icon-button style="position: absolute; right:15px; top:5px" slot="end" color="danger"
        (click)="showDamageImage=null"><i class="material-icons" style="color:red">close</i></button>

    </h4>
  </div>

  <img [src]="showDamageImage.img1.url ? showDamageImage.img1.url : showDamageImage.img1.thumb">

</div>