
import { Component, OnInit } from '@angular/core';
import { MysqlService } from '../services/mysql.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { MD5 } from 'app/shared';
import * as moment from 'moment';

@Component({
  selector: 'app-proposta',
  templateUrl: './proposta.component.html',
  styleUrls: ['./proposta.component.scss']
})
export class PropostaComponent implements OnInit {

  id; hash; item;

  logo

  completed

  iban_proposta=''

  constructor(
    private db: MysqlService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {

    this.logo = this.db.getLogo()

    route.params.subscribe(val => {

      console.log(val)

      this.id = +val.id
      this.hash = val.hash

     this.loadData();

    });

  }

  loadData(){
    this.db.get_perizia(this.id, '', this.hash).then(res => {

      console.log(res)

      if (MD5(res.item.targa) == this.hash) {

        this.item = res.item

        this.completed = this.item.proposte[this.item.proposte.length-1].proposta_accettata

      }
    })
  }

  async ngOnInit() {


  }

  ifnull(s) {
    if (!s || s == '') return '---'
    return s
  }

  accept_reject(b) {

    if (confirm(`Vuole ${b ? 'accettare' : 'rifiutare'} la proposta in oggetto?`)) {

      let proposte = this.item.proposte

      proposte[proposte.length-1].proposta_accettata = b
      proposte[proposte.length-1].dataRisposta = new Date()
      this.db.perizia_upsert_proposte(this.id, proposte, this.iban_proposta).then(res => { 
        if (res.status == "OK") {
          this.loadData()
          alert('Operazione completata')
          return
        }

        alert(JSON.stringify(res))
      }).catch(res => alert(JSON.stringify(res)))
    }
  }

}
