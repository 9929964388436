<router-outlet></router-outlet>

<div class="version" *ngIf="olderVersion">
    <div class="card">

        <h2>Attenzione</h2>
        <h3>E' disponibile un aggiornamento!</h3>

        <button class="btn btn-success" type="button" mat-raised-button (click)="refresh()">Aggiorna</button> <br>
        <button *ngIf="!isPosponed" mat-flat-button type="button" (click)="pospone()">Posponi (5min)</button>
        <br *ngIf="!isPosponed">


        <p>Se il problema persiste premere CTRL + F5 per forzare l'aggiornamento</p>
    </div>
</div>