import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  styleUrls: ['./password-strength.component.scss'],
  templateUrl: './password-strength.component.html',
})
export class PasswordStrengthComponent implements OnChanges {
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  bar4: string;

  @Input() public passwordToCheck: string;

  @Output() passwordStrength = new EventEmitter<boolean>();

  private colors = ['darkred', 'orangered', 'orange', 'orange', 'yellowgreen'];
  private messages = ['Molto debole', 'Debole', 'Media', 'Buona', 'Sicura'];

  message: string;
  messageColor: string;

  flags: {
    lowerLetters: boolean,
    upperLetters: boolean,
    numbers: boolean,
    symbols: boolean,
    length: boolean
  }



  strengthValue
  checkStrength(password: string) {

    const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(password);
    const upperLetters = /[A-Z]+/.test(password);
    const numbers = /[0-9]+/.test(password);
    const symbols = regex.test(password);

    const length = password.length >= 10

    this.flags = {
      lowerLetters: lowerLetters,
      upperLetters: upperLetters,
      numbers: numbers,
      symbols: symbols,
      length: length
    }

    // 3
    const flags = [length, lowerLetters, upperLetters, numbers, symbols];

    let force = 0
    for (const flag of flags) {
      force += flag ? 1 : 0
    }

    return force;
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const password = changes.passwordToCheck.currentValue;

    this.setBarColors(5, '#DDD');

    if (password) {
      const pwdStrength = this.checkStrength(password);

      this.passwordStrength.emit(pwdStrength >= 5)

      const color = this.getColor(pwdStrength);
      console.log(color)
      this.setBarColors(color.index, color.color);

      this.message = this.messages[pwdStrength-1]

    } else {
      this.message = '';
    }
  }

  private getColor(strength: number) {

    this.messageColor = this.colors[strength - 1];

    return {
      index: strength - 1,
      color: this.colors[strength - 1],
    };
  }

  private setBarColors(count: number, color: string) {
    for (let n = 0; n <= count; n++) {
      (this as any)['bar' + n] = color;
    }
  }
}
