<!-- LATO USER -->
<div id="videos" class="card" *ngIf="session && inverted">
    <app-publisher id="subscriber" #publisher class="thumb" [session]="session" [(receiveCommand)]="cmd" (onOkPrivacy)="okPrivacy($event)"  (onSetStream)="setStream($event)"></app-publisher>
    <app-subscriber [captureEnabled]="this.captureEnabled" id="publisher" #subscriber class="full" (onToggleRecord)="toggleRecord($event)" [isRecording]="isRecording"
      [ngClass]="{'hidden':hideThumb}" *ngFor="let stream of streams" [stream]="stream" [session]="session"
      (onCapture)="capture($event)" (onSendCommand)="sendCommand($event)" [recordable]="recordable"></app-subscriber>
  
  </div>

<!-- LATO OPERATORE-->
<div id="videos" class="card" *ngIf="session && !inverted"> 
    <app-publisher id="publisher" #publisher class="thumb" [session]="session" [ngClass]="{'hidden':hideThumb}" (onOkPrivacy)="okPrivacy($event)">
    </app-publisher>
    <app-subscriber [captureEnabled]="this.captureEnabled" id="subscriber" #subscriber class="full" (onToggleRecord)="toggleRecord($event)" [isRecording]="isRecording"
      *ngFor="let stream of streams" [stream]="stream" [session]="session" (onCapture)="capture($event)"
      (onSendCommand)="sendCommand($event)" [recordable]="recordable"></app-subscriber>
  </div>