import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';

@Injectable()
export class UserService {

  basename = 'authority'

  constructor() {


  }

  user = {}
  userState = []

  protected userState$ = new BehaviorSubject(this.userState[0]);

  setCurrentUser(user: any) {

    console.log('setCurrentUser', user)

    this.user = user;
    localStorage.setItem(this.basename, JSON.stringify(user));
  }

  getCurrentUser(): any {
    this.user = JSON.parse(localStorage.getItem(this.basename));
    return this.user;
  }

  isAdmin(): boolean {
    let user = this.getCurrentUser();
    if (!user) return false;
    return user.role == "ADMIN";
  }

  getRole(): string {
    let user = this.getCurrentUser();
    if (!user) return '';
    return user.role
  }

  isBackoffice(): boolean {
    let user = this.getCurrentUser();
    if (!user) return false;
    return user.role == "GESTORE";
  }

  login(user) {
    this.setCurrentUser(user);
    this.setUserState(user);
  }

  logout() {
    this.user = {};
    localStorage.setItem(this.basename, JSON.stringify({}));

    localStorage.removeItem('dash-automotive-commesse-ssu')
    localStorage.removeItem('dash-automotive-commesse-insurance')
    localStorage.removeItem('dash-automotive-commesse-buyback')


    this.setUserState(this.user);

    sessionStorage.clear();
  }

  setUserState(user: any): any {
    this.userState$.next(user);
  }

  getUserStates(): Observable<any[]> {
    return of(this.userState);
  }

  onUserState(): Observable<any> {
    return this.userState$.asObservable();
  }

  isLogged() {
    let user = this.getCurrentUser();
    return user && user.token && user.token != '';
  }

  getToken(): string {
    return this.getCurrentUser().token;
  }

  getEmail(): string {
    return this.getCurrentUser().email;
  }


}
