<nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute " [hidden]="!user">
    <div class="container-fluid">


        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">

            <ul class="navbar-nav">

                <!-- USER -->
                <li class="nav-item dropdown">
                    <a class="nav-link" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">person</i> {{user?.Nome}} {{user?.Cognome}}
                        <p>
                            <span class="d-lg-none d-md-block">Account</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" (click)="changePwd()">Cambia password</a>
                        <a class="dropdown-item" (click)="logout()">Log-out</a>

                    </div>
                </li>

                <!-- NOTIFIES -->
                <li class="nav-item dropdown">
                    <a class="nav-link" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                        (click)="openUrl('/extra/messages')" aria-expanded="false">
                        <i class="material-icons">notifications</i>
                        <span class="notification" *ngIf="notifies>0">{{notifies}}</span>
                        <p>
                            <span class="d-lg-none d-md-block">Some Actions</span>
                        </p>
                    </a>
                    <!--
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink"
                        style="width:220px!important;">
                        <div *ngFor="let i of notifiesMenu">
                            <div class="dropdown-item" (click)="openUrl(i.link)" *ngIf="i.link"
                                style=" white-space: normal!important; padding:0; margin:5px;">
                                <br *ngIf="i.msg">
                                <div style="width:100%!important;">
                                    {{i.title}}
                                    <br *ngIf="i.msg">
                                    <div
                                        style="margin:5px 3px 3px 5px; width:100%!important; border: 1px solid #cccccccc; border-radius:15px; padding:3px;">{{i.msg}}
                                    </div>
                                </div>
                            </div>
                            <span class="dropdown-item" *ngIf="!i.link">{{i.title}}</span>
                            <div></div>
                        </div>

                    </div>
-->
                </li>
            </ul>
        </div>
    </div>
</nav>