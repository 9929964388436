<form [formGroup]="form"  (keydown.enter)="$event.preventDefault()" style="height: 100%; ">
  <div class="row">
    <div class="col">
      <mat-form-field style="padding-left: 3%;padding-right: 3%;">
        <mat-label>Data</mat-label>
        <input matInput type="date" [(ngModel)]="_d" (change)="change()" formControlName="d">
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field style="padding-left: 3%;padding-right: 3%;">
        <mat-label>Ora</mat-label>
        <input matInput type="time" [(ngModel)]="_t" (change)="change()" formControlName="t">
      </mat-form-field>
    </div>
  </div>
</form>