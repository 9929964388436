<div class="row cnt">

  <div class="col-4">
    <div class="item green" (click)="setValue(2)" [ngClass]="{'item-selected':  getSelectedValue()==2 }">
      <div class="text" [ngClass]="{'selected': getSelectedValue()==2 }"> {{text[2]}}</div>
    </div>
  </div>

  <div class="col-4">
    <div class="item yellow" (click)="setValue(1)" [ngClass]="{'item-selected':  getSelectedValue()==1 }">
      <div class="text" [ngClass]="{'selected':  getSelectedValue()==1 }">{{text[1]}}</div>
    </div>
  </div>

  <div class="col-4">
    <div class="item red" (click)="setValue(0)" [ngClass]="{'item-selected':  getSelectedValue()==0 }">
      <div class="text" [ngClass]="{'selected':  getSelectedValue()==0 }">{{text[0]}}</div>
    </div>
  </div>



</div>