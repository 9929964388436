import { Component, OnInit, Input, Output, EventEmitter, /*Output, EventEmitter*/ } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { AuthorizationService } from '../../../services/authorization.service';

@Component({
  selector: 'app-table-checkbox',
  templateUrl: './table-checkbox.component.html',
  styleUrls: ['./table-checkbox.component.scss']
})
export class TableCheckboxComponent implements ViewCell, OnInit {

  @Input() value;
  @Input() rowData: any;

  userCompetence: boolean;

  @Output() onCheckboxChange: EventEmitter<any>

  constructor(private authorizationService: AuthorizationService) { }

  ngOnInit() {
    this.userCompetence = this.authorizationService.isUserCompetence(this.rowData.stato);
  }

  change(e) {
    this.value = e.checked
    this.rowData.selected = e.checked
    console.log(this.rowData)
    //this.onChange.emit();
  }

}


@Component({
  selector: 'app-table-checkbox',
  templateUrl: './table-checkbox.component.html',
  styleUrls: ['./table-checkbox.component.scss']
})
export class TableCheckboxFleetComponent implements ViewCell, OnInit {

  @Input() value;
  @Input() rowData: any;

  userCompetence: boolean;

  //@Output() onCheckboxChange: EventEmitter<void>

  constructor(private authorizationService: AuthorizationService) { }

  ngOnInit() {
    this.userCompetence = this.rowData.idPerizia == 0;
  }

  change(e) {
    this.value = e.checked
    this.rowData.selected = e.checked
    console.log(this.rowData)
    //this.onChange.emit();
  }

}



@Component({
  selector: 'app-table-checkbox',
  templateUrl: './table-checkbox.component.html',
  styleUrls: ['./table-checkbox.component.scss']
})
export class TableRemarketingCheckboxComponent implements ViewCell, OnInit {

  @Input() value;
  @Input() rowData: any;
  @Output() onCheckboxChange: EventEmitter<any> = new EventEmitter()
  userCompetence: boolean;

  constructor() {


  }

  ngOnInit() {
    this.userCompetence = !this.rowData.statoVendita;
  }

  change(e) {
    this.value = e.checked
    this.rowData.selected = e.checked
    console.log(this.rowData)
    this.onCheckboxChange.emit(this.rowData);
  }

}