import { Router, ActivatedRoute } from '@angular/router';
import { IPerizia } from '../../../models/perizia.interface';
import { MatDialog } from '@angular/material/dialog';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MysqlService } from 'app/services/mysql.service';
import { TableCheckboxComponent } from 'app/components/table/table-checkbox/table-checkbox.component';
import { UserService } from 'app/services/user.service';
import * as moment from 'moment-timezone';
import { GridDeleteComponent, GridDuplicateComponent, GridEditComponent, GridIconComponent, GridUpDownComponent } from 'app/components/grid-edit/grid-edit.component';
import { VerycarQuestionEditDialogComponent } from './question-edit-dialog/question-edit-dialog.component';
import { MD5 } from 'app/shared';
@Component({
  selector: 'app-verycar-defs',
  templateUrl: './verycar-defs.component.html',
  styleUrls: ['./verycar-defs.component.scss']
})
export class VerycarDefsComponent implements OnInit, OnDestroy {


  item

  gridApi_defs;
  gridColumnApi_defs;
  defaultColDef_defs = {
    resizable: true,
    sortable: true,
    filter: true,
    suppressMovable: true,
    suppressToolPanel: true,
  };
  columnDefs_defs: any[] = [

    {
      field: 'id', headerName: '',
      sortable: false,
      width: '60px', resizable: false,
      cellRenderer: GridEditComponent,
      cellRendererParams: {
        clicked: (field: any) => {

          let tmp = this.rowData_defs.find(e => e.id == field)
          tmp.questions = tmp.questions.sort((a, b) => a.Order - b.Order)

          for (let i = 0; i < tmp.questions.length; i++) {
            tmp.questions[i].Order = i + 1
          }

          this.item = tmp

        }
      },
    },



    {
      field: 'id', headerName: '',
      sortable: false,
      width: '60px', resizable: false,
      cellRenderer: GridDuplicateComponent,
      cellRendererParams: {
        clicked: (field: any) => {

          let tmp = JSON.parse(JSON.stringify(this.rowData_defs.find(e => e.id == field)))

          tmp.id = ''
          tmp.ver = 0

          this.item = tmp

        }
      },
    },

    {
      field: 'id', headerName: '',
      sortable: false,
      width: '40px', resizable: false,
      cellRenderer: GridDeleteComponent,
      cellRendererParams: {
        clicked: (id: any) => {

          console.log(this.rowData_defs)

          let def = this.rowData_defs.find(e => e.id == id)

          console.log(def)

          if (def.current) {
            alert('Impossibile eliminare una definizione impostata come attuale')
            return
          }

          if (!confirm("Cancellare la definizione?")) return

          this.db.verycar_defs_delete(id).then(res => {
            this.loadDefs()
          })


        }
      },
    },



    { field: 'id', headerName: 'ID', },

    //{ field: 'completed', headerName: '', width: '50px', resizable: false },

    { field: 'type', headerName: 'Tipo', },
    { field: 'vehType', headerName: 'Tipo Veicolo', },
    { field: 'version', headerName: 'Ver.', },

    { field: 'n', headerName: 'N° Domande' },

    { field: 'current', headerName: 'Attuale', },


  ];
  rowData_defs: any[]





  gridApi_def;
  gridColumnApi_def;
  defaultColDef_def = {
    resizable: true,
    sortable: false,
    filter: true,
    editable: false,
    suppressMovable: true,
    suppressToolPanel: true,
  };


  columnDefs_def: any[] = [

    {
      field: 'questionId', headerName: '',
      sortable: false,
      width: '40px', resizable: false,
      cellRenderer: GridEditComponent,
      cellRendererParams: {
        clicked: (field: any) => {

          let i = this.item.questions.findIndex(e => e.questionId == field)

          const dialogRef = this.dialog.open(VerycarQuestionEditDialogComponent, {
            data: { q: this.item.questions[i], questions: this.item.questions }
          });

          dialogRef.afterClosed().subscribe(result => {

            console.log(result)

            if (!result) return

            this.item.questions[i] = result

            let tmp = JSON.parse(JSON.stringify(this.item.questions))
            this.item.questions = []
            this.item.questions = tmp
          });


        }
      },
    },


    {
      field: 'questionId', headerName: '',
      sortable: false,
      width: '60px', resizable: false,
      cellRenderer: GridDuplicateComponent,
      cellRendererParams: {
        clicked: (field: any) => {
          let q = this.item.questions.find(e => e.questionId == field)
          this.cloneQuestion(q)


        }
      },
    },



    {
      field: 'questionId', headerName: '',
      sortable: false,
      width: '40px', resizable: false,
      cellRenderer: GridDeleteComponent,
      cellRendererParams: {
        clicked: (field: any) => {

          if (!confirm("Cancellare la domanda?")) return

          let i = this.item.questions.findIndex(e => e.questionId == field)

          this.item.questions.splice(i, 1)


          let tmp = JSON.parse(JSON.stringify(this.item.questions))
          this.item.questions = []
          this.item.questions = tmp

        }
      },
    },


    {
      field: 'Order', headerName: '',
      sortable: false,
      width: '90px', resizable: false,
      cellRenderer: GridUpDownComponent,
      cellRendererParams: {
        clicked: (data: any) => {

          try {

            console.log(data)

            let tmp = JSON.parse(JSON.stringify(this.item.questions))

            let ia = tmp.findIndex(e => e.Order == data.order)
            let ib = tmp.findIndex(e => e.Order == (data.direction == 'up' ? data.order - 1 : data.order + 1))

            let itmp = tmp[ia].Order
            tmp[ia].Order = tmp[ib].Order
            tmp[ib].Order = itmp

            tmp = tmp.sort((a, b) => a.Order - b.Order)

            this.item.questions = []
            this.item.questions = tmp

          } catch (error) {

          }
        }
      },
    },

    //{ field: 'Order', headerName: 'order', },
    //{ field: 'completed', headerName: '', width: '50px', resizable: false },

    { field: 'sectionCode', headerName: 'sectionCode', },
    { field: 'sectionDescription', headerName: 'sectionDescription', },


    { field: 'questionId', headerName: 'questionId', },
    { field: 'questionName', headerName: 'questionName', },
    { field: 'questionDescr', headerName: 'questionDescr', },

    { field: 'val1', headerName: 'val1', },
    { field: 'val2', headerName: 'val2', },
    { field: 'val3', headerName: 'val3', },
    { field: 'val4', headerName: 'val4', },
    { field: 'val5', headerName: 'val5', },


    { field: 'text1', headerName: 'text1', },
    { field: 'text2', headerName: 'text2', },
    { field: 'text3', headerName: 'text3', },
    { field: 'text4', headerName: 'text4', },
    { field: 'text5', headerName: 'text5', },

    { field: 'nonApplicabile', headerName: 'nonApplicabile', },
    { field: 'photoRequired', headerName: 'photoRequired', },

    { field: 'blueprint', headerName: 'blueprint', },
    { field: 'tires', headerName: 'tires', },


    /*
  
      note_inspect
      hint
      Cristalli
      Kasko
      Visiva
      Guasti12mesi
      Guasti24mesi
  
    */


  ];

  rowData_def: any[]


  users: any[];

  type: string;

  constructor(

    public dialog: MatDialog,

    private router: Router,
    private route: ActivatedRoute,
    private db: MysqlService,
  ) {


  }


  tab = 0
  onTabChanged(event) {
    console.clear()
    console.log(event)
    this.tab = event.index

    switch (this.tab) {
      case 1:
        break;

      default:
        break;
    }
  }

  addDef() {
    this.item = {
      id: '',
      questions: [],
      ver: 0,
    } as any
  }

  loadDefs() {
    this.db.verycar_defs_list().then((res: any) => {
      console.log(res)

      res.list.forEach(e => {
        e.n = e.questions.length
      });
      this.rowData_defs = res.list


    }).catch(err => {
      console.error(err)
    })
  }

  saveDef() {
    let rowData = []
    this.gridApi_def.forEachNode(node => rowData.push(node.data));

    this.item.questions = rowData

    if (this.item.id == '') this.item.id = MD5(`${new Date()}_${this.item.type}_${this.item.vehType}_${this.item.ver}`)

    this.db.verycar_defs_upsert(this.item)
      .then((res: any) => {
        if (res.status != 'OK') {
          alert(JSON.stringify(res))
          return
        }

        this.cancelDef()
        this.loadDefs()

      })
      .catch(err => alert(JSON.stringify(err)))

  }

  cancelDef() {
    this.item = undefined
  }

  getUserNameByEmail(email) {
    let r;
    try {
      r = this.users.find(v => { return v.email == email });
      return r.firstname + ' ' + r.lastname;
    } catch (error) {

      return email;

    }

  }


  openDialog(data: IPerizia = null): void {
    /*
        const dialogRef = this.dialog.open(PerizieDialogComponent, {
          data: data
        });
    
        dialogRef.afterClosed().subscribe(result => {
    
          this.loadData();
    
          console.log(result);
        });
    */
  }



  addQuestion() {


    let qid = Math.max(...this.item.questions.map(e => e.questionId)) + 1

    console.log(this.item.questions.map(e => e.questionId), qid)

    let max = 1
    try {
      max = Math.max(...this.item.questions.map(e => e.Order)) + 1
    } catch (error) { }


    qid = qid == -Infinity ? 1 : qid
    max = max == -Infinity ? 1 : max

    let q = {
      sectionCode: '',
      questionId: qid,
      questionName: '' + qid,

      Order: max,

    }

    console.clear()
    console.log(q)
    this.item.questions.push(q)



    let i = this.item.questions.findIndex(e => e.questionId == qid)

    const dialogRef = this.dialog.open(VerycarQuestionEditDialogComponent, {
      data: { q: this.item.questions[i], questions: this.item.questions }
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(result)

      if (!result) return

      this.item.questions[i] = result

      let tmp = JSON.parse(JSON.stringify(this.item.questions))
      this.item.questions = []
      this.item.questions = tmp
    });



  }



  cloneQuestion(q1) {


    let qid = Math.max(...this.item.questions.map(e => e.questionId)) + 1

    console.log(this.item.questions.map(e => e.questionId), qid)

    let max = 1
    try {
      max = Math.max(...this.item.questions.map(e => e.Order)) + 1
    } catch (error) { }


    qid = qid == -Infinity ? 1 : qid
    max = max == -Infinity ? 1 : max

    console.log(q1)

    let q = JSON.parse(JSON.stringify(q1))

    q.questionId = qid
    q.questionId = '' + qid
    q.Order = max


    console.clear()
    console.log(q)
    this.item.questions.push(q)



    let i = this.item.questions.findIndex(e => e.questionId == qid)

    const dialogRef = this.dialog.open(VerycarQuestionEditDialogComponent, {
      data: { q: this.item.questions[i], questions: this.item.questions }
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(result)

      if (!result) return

      this.item.questions[i] = result

      let tmp = JSON.parse(JSON.stringify(this.item.questions))
      this.item.questions = []
      this.item.questions = tmp
    });



  }

  ngOnInit() {

    this.loadDefs()

  }


  ngOnDestroy() {
    console.log('destroy')



  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log(event)
    this.autoSizeAll_defs();
    this.autoSizeAll_def();
  }

  onGridReady_defs(params) {
    this.gridApi_defs = params.api;
    this.gridColumnApi_defs = params.columnApi;
    this.autoSizeAll_defs();
  }

  sizeColumnsToFit_defs(gridApi) {
    this.gridApi_defs.sizeColumnsToFit()
  }


  autoSizeAll_defs(skipHeader = false) {
    try {
      var allColumnIds = [];
      this.gridColumnApi_defs.getAllColumns().filter(e => e.colDef.resizable).forEach((column) => {
        allColumnIds.push(column.colId);
        console.log(column)
      });

      this.gridColumnApi_defs.autoSizeColumns(allColumnIds, skipHeader);
    } catch (error) {

    }
  }



  onGridReady_def(params) {
    this.gridApi_def = params.api;
    this.gridColumnApi_def = params.columnApi;
    this.autoSizeAll_defs();
  }

  sizeColumnsToFit_def(gridApi) {
    this.gridApi_def.sizeColumnsToFit()
  }


  autoSizeAll_def(skipHeader = false) {

    try {
      var allColumnIds = [];
      this.gridColumnApi_def.getAllColumns().filter(e => e.colDef.resizable).forEach((column) => {
        allColumnIds.push(column.colId);
        console.log(column)
      });

      this.gridColumnApi_def.autoSizeColumns(allColumnIds, skipHeader);

    } catch (error) {

    }
  }


}











@Component({
  selector: 'app-docstatus-checkbox',
  template: `
               <button (click)="download()" mat-icon-button >
               <i class="material-icons">arrow_downward                       </i>
              </button> 
              
              
  `
})
export class VerycarDownloadComponent implements ViewCell, OnInit {

  @Input() value;
  @Input() rowData: any;
  @Output() refresh = new EventEmitter<any>();

  state: string = '';
  next: string = '';
  buttonEnabled = false;

  role: string;

  callback;

  dtaField: string = ''

  //@Output() onCheckboxChange: EventEmitter<void>

  constructor(private user: UserService, private db: MysqlService) {

    this.role = this.user.getCurrentUser().role

  }



  ngOnInit() {

  }


  download(dl = false) {
    console.log(this.rowData)
    let url = this.db.verycar_getUrl(this.rowData.id, dl)

    window.open(url, '_system')

  }






}


@Component({
  selector: 'app-docstatus-checkbox',
  template: `
              <button (click)="add('visura',rowData.id)" mat-icon-button >
               <i class="material-icons">note_add</i>
              </button><i *ngIf="fileex" class="material-icons">done                       </i>

              <input type="file" #file id="file" style="display: none;" (change)="addAttachment($event)"
              accept="application/pdf"> 
              
  `
})
export class VerycarVisuraComponent implements ViewCell, OnInit {

  @Input() value;
  @Input() rowData: any;
  @Output() refresh = new EventEmitter<any>();


  next: string = '';
  buttonEnabled = false;

  role: string;

  recordId = "";
  recordType = "";
  callback;
  fileex = false

  dtaField: string = ''

  //@Output() onCheckboxChange: EventEmitter<void>

  constructor(private user: UserService, private db: MysqlService, private firestore: AngularFirestore,) {
    this.role = this.user.getCurrentUser().role
  }

  ngOnInit() {
    if (this.rowData.file_visura != undefined)
      this.fileex = true
  }

  add(type, id) {
    this.recordId = id;
    this.recordType = type;
    this.file.nativeElement.click();
  }
  @ViewChild('file') file: ElementRef;
  addAttachment(event) {
    const file: File = event.target.files[0];
    this.getBase64(file);
  }



  getBase64(file: File) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = res => {

      let r = '' + reader.result
      this.file.nativeElement.value = "";

      if (r.length > 1048480) {
        alert("Il file supera la dimensione massima");
        return;
      }

      this.db.verycar_upload_file(this.recordId, 'visura', r).then(res => {
        console.log('verycar_upload_file', res)
      }).catch(err => console.log(err))

    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

  }

}






@Component({
  selector: 'app-docstatus-checkbox',
  template: `
              <button (click)="add('visura',rowData.id)" mat-icon-button >
               <i class="material-icons">note_add</i>
              </button><i *ngIf="fileex" class="material-icons">done                       </i>

              <input type="file" #file id="file" style="display: none;" (change)="addAttachment($event)"
              accept="application/pdf"> 
              
  `
})
export class VerycarEtaxComponent implements ViewCell, OnInit {

  @Input() value;
  @Input() rowData: any;
  @Output() refresh = new EventEmitter<any>();


  next: string = '';
  buttonEnabled = false;

  role: string;

  recordId = "";
  recordType = "";
  callback;
  fileex = false
  dtaField: string = ''

  //@Output() onCheckboxChange: EventEmitter<void>

  constructor(private user: UserService, private db: MysqlService, private firestore: AngularFirestore,) {
    this.role = this.user.getCurrentUser().role
  }

  ngOnInit() {
    if (this.rowData.file_etax != undefined) {
      this.fileex = true
    }
    console.log(this.fileex)
  }

  add(type, id) {
    this.recordId = id;
    this.recordType = type;
    this.file.nativeElement.click();
  }
  @ViewChild('file') file: ElementRef;
  addAttachment(event) {
    const file: File = event.target.files[0];
    this.getBase64(file);
  }



  getBase64(file: File) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = res => {

      let r = '' + reader.result
      this.file.nativeElement.value = "";

      if (r.length > 1048480) {
        alert("Il file supera la dimensione massima");
        return;
      }

      this.db.verycar_upload_file(this.recordId, 'etax', r).then(res => {
        console.log('verycar_upload_file', res)
      }).catch(err => console.log(err))

    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

  }

}












