<div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
  
          <div class="card">
            <div class="card-header card-header-info">
              <h4 class="card-title"> Program
  
              </h4>
              <p class="card-category">
              </p>
            </div>
            <div class="card-body">
  
              <ng2-smart-table class="grid" [settings]="settings" [source]="source" 
                >
              </ng2-smart-table>
  
            </div>
          </div>
  
  
        </div>
      </div>
    </div>
  </div>