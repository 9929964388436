<div class="main-content" style="height: calc(100vh - 50px); padding-top:0; margin-bottom:0">
  <div class="row" style="height: calc(100vh - 50px); margin-bottom:0">

    <div class="col-md-2" style="height: calc(100vh - 50px);">
      <div class="row" *ngIf="loading">
        <div class="col-12">
          <mat-spinner style="display: block; margin:auto;"></mat-spinner>
        </div>
      </div>
      <div class="card" *ngIf="!loading && !locked">

        <div class="card-header card-header-info">
          <h4 class="card-title" style="color:white">
            <p style="color:white; font-size: 80%">TARGA: {{item?.plate}}</p>
            <p style="color:white; font-size: 75%">N° SINISTRO:<br>{{item?.claimNr}}</p>
            <p style="color:white; font-size: 75%">Durata: {{ ts * 1000 | date:'H:mm:ss':'UTC'}}</p>
            <p style="color:white; font-size: 75%" *ngIf="item?.geo?.latitude"> <a
                [href]="'https://www.google.it/maps/@'+item?.geo?.latitude+','+item?.geo?.longitude+',15z'"
                target="_blank"> Geo: {{ item?.geo?.latitude|number:'.3-3'}} -
                {{ item?.geo?.longitude|number:'.3-3'}}</a></p>

            <p *ngIf="item?.okPrivacy===true" style="color:white; font-size: 80%">Consenso privacy&nbsp;
              <span class="material-icons" color="green" style="float:right; color:green"> check_circle </span>
            </p>
            <p *ngIf="item?.okPrivacy===false" style="color:white; font-size: 80%">Consenso privacy&nbsp;
              <span class="material-icons" color="red" style="float:right; color:red"> error </span>
            </p>

            <p *ngIf="item?.okPrivacyGeo===true" style="color:white; font-size: 80%">Consenso geo&nbsp;
              <span class="material-icons" color="green" style="float:right; color:green"> check_circle </span>
            </p>
            <p *ngIf="item?.okPrivacyGeo===false" style="color:white; font-size: 80%">Consenso geo&nbsp;
              <span class="material-icons" color="red" style="float:right; color:red"> error </span>
            </p>

            <p *ngIf="item?.okPrivacyCam===true" style="color:white; font-size: 80%">Consenso camera&nbsp;
              <span class="material-icons" color="green" style="float:right; color:green"> check_circle </span>
            </p>
            <p *ngIf="item?.okPrivacyCam===false" style="color:white; font-size: 80%">Consenso camera&nbsp;
              <span class="material-icons" color="red" style="float:right; color:red"> error </span>
            </p>

            <!-- <p style="color:white; font-size: 75%">Agent: {{item?.agent}}</p> -->
          </h4>




          <button *ngIf="false" matTooltipPosition="right" matTooltip="Invia notifica push"
            style="float:right; color:white" class="btn btn-info" mat-button (click)="sendPushNotify()"><i
              class="material-icons">send</i></button>

          <button matTooltipPosition="right" matTooltip="Copia link" style="float:right; color:white"
            class="btn btn-info" mat-button (click)="copyUrl()"><i class="material-icons">file_copy</i></button>

          <button matTooltipPosition="right" matTooltip="Invia SMS" style="float:right; color:white"
            class="btn btn-info" mat-button (click)="sendSms()"><i class="material-icons">sms</i></button>


          <button matTooltipPosition="right" matTooltip="Impostazioni A/V" style="float:right; color:white"
            class="btn btn-info" mat-button (click)="openSettings()"><span class="material-icons">
              settings
            </span></button>

          <button *ngIf="!item?.idPerizia && item?.closed" matTooltipPosition="right" matTooltip="Genera perizia"
            style="float:right; color:white" class="btn btn-info" mat-button (click)="sendToPerizia()"><i
              class="material-icons">note_add</i></button>

          <button *ngIf="item?.idPerizia && item?.closed" matTooltipPosition="right" matTooltip="Salva e chiudi"
            style="float:right; color:white" class="btn btn-info" mat-button (click)="save()"><i
              class="material-icons">save</i></button>

          <button *ngIf="item?.idPerizia && item?.closed && item?.attachments" matTooltipPosition="right"
            matTooltip="Scarica Immagini" style="float:right; color:white" class="btn btn-info" mat-button
            (click)="downloadAll()"><i class="material-icons">get_app</i></button>

          <button *ngIf="!item?.closed" matTooltipPosition="right" matTooltip="Chiudi chiamata"
            style="float:right; color:red" class="btn btn-info" mat-button (click)="endCall()"><i
              class="material-icons">call_end</i></button>




        </div>

        <mat-form-field class="full" style="padding-top: 5px;">
          <mat-label>Tipo utente</mat-label>
          <mat-select [(ngModel)]="usertType">
            <mat-option value="danneggiato">Danneggiato</mat-option>
            <mat-option value="riparatore">Riparatore</mat-option>
            <mat-option value="patrocinatore">Patrocinatore</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="card-content" style="height: calc(100vh - 200px); overflow-x:hidden; overflow-y:auto;">

          <div class="row" *ngIf="item?.archiveList && item?.archiveList.length >0" style="padding:15px">
            <div class="col-6" *ngFor="let v of item?.archiveList; index as i">
              <button style="float:left" (click)="openVideo(v)">
                <span class="material-icons">
                  videocam
                </span>
                &nbsp;&nbsp;&nbsp;
                Video {{i+1}}
              </button>
            </div>
          </div>

          <div *ngFor="let c of item?.attachments">
            <img style="margin:5px; max-height:200px; object-fit:contain;" [src]="c.url" width="100%"
              (click)="openImg(c.url)">
          </div>
        </div>

      </div>
      <div class="card" *ngIf="locked">
        <div class="card-content" style="height: calc(100vh - 200px); overflow-x:hidden; overflow-y:auto;">
          <h2>La videoperizia è attualmente bloccata. Contattare un amministratore di sistema per aumentare i minuti a
            vostra disposizione questo mese.</h2>
        </div>

      </div>
    </div>
   
    <div class="col-md-10" style="height: calc(100vh - 50px);" *ngIf="!locked">


      <audio id="meeting-audio" #meetingAudio style="border:1px solid black"></audio>


      <video [attr.id]="'video-1'" style="position:absolute; top:0; left:0; width:20%; z-index:999"></video>
      <span style="position:absolute; top:0; left:0; width:20%; z-index:999">VOLUME:{{videos[0].volume}} </span>


      <video [attr.id]="'video-2'" style="position:absolute; top:0; left:0; width:100% " #video1></video>
      <span style="position:absolute; top:0; left:0; width:100% ">{{videos[1].volume}} </span>


      <div style="position:absolute; bottom:10px; right:10px;  ">
        <button class="btn btn-info" mat-button (click)="capture()">CATTURA</button>
        <button class="btn btn-info" mat-button (click)="switchCamera()">SWITCH CAMERA</button>
      </div>




      <h3 *ngIf="item?.closed">Chiamata chiusa {{msg}}</h3>
    </div>

  </div>
</div>