import { Injectable } from '@angular/core';

import * as OT from '@opentok/client';

@Injectable()
export class OpentokService {

  session: OT.Session;
  token: string;
  URL_BASE: string;

  constructor() {

    this.URL_BASE = window.location.href.split('/').slice(0, 3).join('/');
    let host = window.location.href.split('/')[2].split(':')[0];

    if (host == 'localhost') {
      this.URL_BASE = 'https://xpert.ggroup.cloud/';
      this.URL_BASE += 'api/api.php?cmd=';
    } else {
      this.URL_BASE = window.location.href.split('/').slice(0, 3).join('/');
      this.URL_BASE += '/api/api.php?cmd=';
    }

  }

  getOT() {
    return OT
  }


  isTest() {
    return this.URL_BASE.includes('-test');
  }

  config_ggIcon() {

    if (this.isTest()) return 'test';

    else if (this.URL_BASE.includes('csi.')) return 'csi'
    else if (this.URL_BASE.includes('ggroup')) return 'gg';
    else if (this.URL_BASE.includes('tradeone')) return 'tradeone';
    else if (this.URL_BASE.includes('cta-iannone')) return 'cta-iannone';
    else if (this.URL_BASE.includes('expert')) return 'ie';
    else if (this.URL_BASE.includes('e-consulting')) return 'te';
    else if (this.URL_BASE.includes('demo')) return 'demo';
    else if (this.URL_BASE.includes('nationalclaims')) return 'nc';

    return 'test';

  }

  initSession(SESSION_ID: string, TOKEN: string) {

    let config = config_gg;
    /*
        if (this.config_ggIcon() == 'ie') config = config_ie;
        else if (this.config_ggIcon() == 'gg') config = config_gg;
        else if (this.config_ggIcon() == 'nc') config = config_nc;
        else if (this.config_ggIcon() == 'csi') config = config_csi;
        else if (this.config_ggIcon() == 'test') config = config_test;
    */
    //console.clear()
    console.log('API_KEY: ' + config.API_KEY)
    console.log('TOKEN:   ' + TOKEN)
    console.log('SESSION_ID: ' + SESSION_ID)

    if (config.API_KEY && TOKEN && SESSION_ID) {
      console.log('init')
      this.session = this.getOT().initSession(config.API_KEY, SESSION_ID);
      this.token = TOKEN;
      return Promise.resolve(this.session);
    } else {

      return fetch(config.SAMPLE_SERVER_BASE_URL + '/session')
        .then((data) => data.json())
        .then((json) => {
          this.session = this.getOT().initSession(json.apiKey, json.sessionId);
          this.token = json.token;
          return this.session;
        });
    }
  }

  connect() {
    return new Promise((resolve, reject) => {
      this.session.connect(this.token, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(this.session);
        }
      });
    });
  }


  close() {
    this.session.disconnect()
  }
}




export const config_gg = {
  SAMPLE_SERVER_BASE_URL: 'https://telexpert.herokuapp.com',

  API_KEY: '46328692',
}

export const config_ie = config_gg
export const config_test = config_gg
export const config_nc = config_gg
export const config_csi = config_gg
