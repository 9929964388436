<div class="main-content">



    <div class="container-fluid" style="padding:10%">

        <div class="row">
            <div class="col-12 col-lg-6" style="margin:auto;margin-bottom:5%">
                <div style="float:left">
                    <img [src]="logo" style="height:64px" />
                </div>
                <div style="float:right">
                    <h3>Proposta</h3>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-6" style="margin:auto; text-align: justify;" *ngIf="item">

                <p>Gentile {{ ifnull(item?.danneggiato_nome) }} {{ ifnull(item?.danneggiato_cognome) }}, in riferimento
                    al sinistro {{ ifnull(item?.sinistro_numero) }}
                    avenuto in data {{ item?.sinistro_data }}
                    che ha coinvolto il veicolo {{ ifnull(item?.marcaDescr) }} {{ ifnull(item?.modelloDescr) }} targato
                    {{ item?.targa }} di
                    sua proprietà, comunichiamo di aver ricevuto
                    documentazione fotografica sulla base della quale è stato stimato il danno al veicolo assicurato in
                    euro
                    {{ item?.importo_proposta |number:'.2-2'}}.</p>

                <p>Qualora concordasse con la quantificazione di cui sopra, la preghiamo di comunicarci il codice IBAN
                    associato ad un conto corrente intestato al
                    proprietario del veicolo. In mancanza di tale indicazione l'eventuale pagamento potrà avvenire
                    tramite
                    assegno bancario, inviato via posta all'indirizzo
                    di residenza del proprietario del veicolo.</p>


                <mat-form-field class="full">
                    <mat-label>IBAN</mat-label>
                    <input type="text" matInput placeholder="IT60X0542811101000000123456" [disabled]="completed != undefined"
                        [(ngModel)]="iban_proposta">
                </mat-form-field>



            </div>


        </div>

        <div class="row" *ngIf="item && completed == undefined">
            <div class="col-6">
                <button style="width:100%" mat-raised-button type="button" class="btn btn-success"
                    (click)="accept_reject(true)">ACCETTA</button>
            </div>

            <div class="col-6">
                <button style="width:100%" mat-raised-button type="button" class="btn btn-danger"
                    (click)="accept_reject(false)">RIFIUTA</button>
            </div> 
        </div>

        <div class="row" *ngIf="item && completed != undefined">
            <div class="col-12">
                La richiesta è stata {{completed ? 'ACCETTATA' : 'RIFIUTATA'}}
            </div>
        </div>

    </div>

</div>