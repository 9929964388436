import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'presentImage'
})
export class PresentImagePipe implements PipeTransform {

  transform(value: any[], args?: any): any {

    let valuex = value.filter(x => {

      try {
        return !x.url?.includes('null')
      } catch (error) {
        return false
      }

    })

    return valuex;
  }

}
