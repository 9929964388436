import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class OpenWeatherService {

  constructor(private http: HttpClient) {
  }

  getWeather(loc: string, country = 'it'): Promise<any> {
    let url = `https://community-open-weather-map.p.rapidapi.com/weather?units=metric&mode=json&lang=it&q=${loc}%2C${country}`
    return this.http.get(url, { headers: { 'X-RapidAPI-Key': KEY } }).toPromise()
  }

  getForecast(loc: string, country = 'it'): Promise<any> {
    let url = `https://community-open-weather-map.p.rapidapi.com/forecast?units=metric&mode=json&lang=it&q=${loc}%2C${country}`
    return this.http.get(url, { headers: { 'X-RapidAPI-Key': KEY } }).toPromise()
  }

  searchForecastByDate(forecast: any, d: Date, timeLimit = null) {

    if (!timeLimit) timeLimit = 60 * 60 * 12; //HH 

    let ts = d.getTime() / 1000;

    let list: any[] = forecast.list;

    list.forEach(e => { e.delta = Math.abs(e.dt - ts) })

    list = list.filter(e => e.delta <= timeLimit).sort((a, b) => {
      return +a.delta - +b.delta
    })


    let r = list;

    try {

      try {
        r[0].rain = r[0].rain['3h'] / 3
      } catch (error) { }

      try {
        r[0].snow = r[0].snow['3h'] / 3
      } catch (error) { }

      return r[0];
    } catch (error) {
      return undefined
    }

  }


  showAlert(forecast: any):boolean {

    if (!forecast) return;

    switch (forecast.weather[0].main.toLowerCase()) {

      case 'shower rain':
      case 'rain':
      case 'thunderstorm':
      case 'snow':

        return true;

      default:

        return false;
    }

  }

}


const KEY = 'd8ac19555dmsh28d4329e775238ap1dea62jsn4f59a1dca825';