import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MysqlService } from 'app/services/mysql.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';

@Component({
  selector: 'app-verycar-payment',
  templateUrl: './verycar-payment.component.html',
  styleUrls: ['./verycar-payment.component.scss']
})
export class VerycarPaymentComponent implements OnInit {
  
    @ViewChild(StripeCardComponent) card: StripeCardComponent;
   
    cardOptions: StripeCardElementOptions = {
      style: {
        base: {
          iconColor: '#666EE8',
          color: '#31325F',
          fontWeight: '300',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSize: '18px',
          '::placeholder': {
            color: '#CFD7E0'
          }
        }
      }
    };
   
    elementsOptions: StripeElementsOptions = {
      locale: 'it'
    };
   
    stripeTest: UntypedFormGroup;
   
    constructor(private fb: UntypedFormBuilder, private stripeService: StripeService, private db : MysqlService, private route : ActivatedRoute) {}
   id
    ngOnInit(): void {

      this.id = this.route.snapshot.paramMap.get('id');
      this.loadData()
      this.stripeTest = this.fb.group({
        name: ['', [Validators.required]]
      });
    }
   result
   wait = false
    item;
    user;
    ammo = 0


   loadData(){
     this.wait= true
     console.log('load')
    this.db.verycar_get(this.id).then(res=>{
      console.log(res)
      this.item = res[0]

       this.wait = false

      if(this.item.type=='full') this.ammo = 150
      else this.ammo = 85
      
 

    }).catch(err=>console.log(err ))

   }


    createToken(): void {
     


      const name = this.stripeTest.get('name').value;
      this.stripeService
        .createToken(this.card.element, { name })
        .subscribe((result) => {
          if (result.token) {
            this.wait=true
          
            this.db.callPayment('300', result.token.id, this.item.id).then(res => {
              console.log(res)
              this.result = {
                status: "ok",
                response: {
                  state: "approved",
                  description: "STRIPE"
                }
              }
              this.wait = false
              this.item.payment = result
              this.loadData()
             
             
            }).catch(err => {
              console.log(err)
              this.wait = false
              this.result = {
                status: "no"
              }
             
            })
            console.log(result.token.id);
          } else if (result.error) {
            // Error creating the token
            console.log(result.error.message);
          }
        });
    }
  }