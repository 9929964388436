<div class="main-content">


    <div class="card" style="padding:1em">


        <eco-fab-speed-dial style="z-index: 999; position:absolute; right:15px; bottom:15px;" animationMode="fling">
            <eco-fab-speed-dial-trigger>
                <button type="button" mat-fab class="btn btn-success" (click)="save()" [disabled]="isDisabled()">
                    <mat-icon>save</mat-icon>
                </button>
            </eco-fab-speed-dial-trigger>
        </eco-fab-speed-dial>


        <div class="card-header card-header-info">
            <h4 class="card-title">
                <span>Ordina le fotografie</span>
                <div style="float:right"><a (click)="alertClose()"><i class="material-icons">close</i></a>
                </div>
            </h4>
        </div><br>

        <div class="card">

            <div class="row">

                <div class="col-3" style="height: calc(100vh - 170px); overflow-y: auto;">
                    <h3>Elenco immagini</h3>

                    <div *ngFor="let a of allegati">



                        <div style="width:100%; margin-bottom:6px; cursor: move;" draggable="true"
                            *ngIf="!a.placeholder && isImage(a)" (dragstart)="drag($event,a,true)" (dragend)="dragEnd($event)">
                            <img style="width:90%; margin:auto; display:block; border-radius: 15px;" [src]="a.url">

                            <i class="material-icons" (click)="zoom(a)" style="float:right">zoom_in</i>
                            
                        </div>

                    </div>

                </div>
                <div class="col" style="height: calc(100vh - 170px); overflow-y: auto;">
                    <h3>Immagini necessarie</h3>

                    <h4 class="warn" *ngIf="missingChiavi && !adzVoceChiavi" style="color:red">
                        Attenzione, immagine chiavi o relativa voce nel file ADZ mancanti
                    </h4>

                    <h4 class="warn" *ngIf="!missingChiavi && adzVoceChiavi" style="color:red">
                        Attenzione, Sono presenti sia l'immagine delle chiavi che la relativa voce nel file ADZ
                    </h4>

                    <h4 class="warn" *ngIf="missingCdc && !adzVoceCdc" style="color:red">
                        Attenzione, immagine CdC o relativa voce nel file ADZ mancanti
                    </h4>

                    <h4 class="warn" *ngIf="!missingCdc && adzVoceCdc" style="color:red">
                        Attenzione, Sono presenti sia l'immagine delle CdC che la relativa voce nel file ADZ
                    </h4>

                    <div class="row" style="width:100%; padding: 10px;">
                        <div style="overflow:hidden; padding:2px;; border-radius: 15px;" class="col-3"
                            *ngFor="let p of placeholders" (drop)="drop($event,p)" (dragover)="allowDrop($event)">

                            <div class="card" style="margin:2px">

                                <p class="footer">
                                    {{p.label}}
                                </p>

                                <button type="button" color="warn" mat-icon-button (click)="resetPlaceholder(p)"
                                    style="position:absolute; top:-6px; right:0px;"><i
                                        class="material-icons">delete_forever</i></button>

                                <img class="img-placeholder" *ngIf="getImage(p.id); else elsePlaceholder"
                                    [src]="getImage(p.id).url">

                                <ng-template #elsePlaceholder>
                                    <img class="img-placeholder" [src]="p.placeholder"
                                        [ngStyle]="{'opacity': p.missing ? 0.3 : 1 }">
                                </ng-template>


                                <p style="padding:10px">Non rilevabile
                                    <mat-slide-toggle [(ngModel)]="p.missing" style="float:right"
                                        (change)="resetPlaceholder(p)">
                                    </mat-slide-toggle>
                                </p>

                                <div *ngIf="p.descr" style="text-align: center; padding:5px;">{{p.descr}}</div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>


    <div class="dialog-large animated heartBeat" *ngIf="showZoom"
        style="position:absolute; left:5%; right:5%; top:5%;">

        <a style="float:right" (click)="zoom()"><i class="material-icons">close</i></a>

        <img [src]="zoomData?.url" style="width:100%">

    </div>