<br>
<div class="row">
  <div class="col">
    <div style="text-align: center; font-weight: bold;">Validazione operatore</div>
    <button type="button" (click)="confirm(1)" mat-flat-button *ngIf="!validazione1_data && role == 'ADMIN' && ( role == 'OPERATORE' || role == 'ADMIN' ) ">VALIDA</button>
    <div class="row">
      <div class="col-5">
        <img style="display:block; margin:auto; height:33%; object-fit: contain;" *ngIf="validazione1_data"
          src='/assets/img/validato.png'>
      </div>
      <div class="col">
        {{getUserDescr(validazione1_utente)}}<br>{{validazione1_data|date:'dd/MM/yyyy'}}
      </div>
    </div>
  </div>

  <div class="col">
    <div style="text-align: center; font-weight: bold;">Validazione supervisore</div>
    <button type="button" (click)="confirm(2)" mat-flat-button *ngIf="!validazione2_data  && ( role == 'GESTORE' || role == 'ADMIN' ) ">VALIDA</button>
    <div class="row">
      <div class="col-5">
        <img style="display:block; margin:auto; height:33%; object-fit: contain;" *ngIf="validazione2_data"
          src='/assets/img/validato.png'>
      </div>
      <div class="col">
        {{getUserDescr(validazione2_utente)}}<br>{{validazione2_data|date:'dd/MM/yyyy'}}
      </div>
    </div>
  </div>

</div>